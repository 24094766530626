import React, { FunctionComponent, ReactElement, useContext, useEffect, useRef, useState } from 'react';

import styles from "./clubs.module.scss";
import { Approve, Delete, Down, EnquiryItem, SortASC, Up } from '../../../components/icons/icons';
import Button from '../../../components/Button/Button';
import Textbox from '../../../components/Textbox/Textbox';
import ListItem from '../../../components/ListItem/ListItem';
import List from '../../../components/List/List';
import ToggleButton from '../../../components/ToggleButton/ToggleButton';
import { ClubData, listClubs } from '../../../restAPI/clubGate';
import { PopupsContext } from '../../../handlers/PopupsContext';


interface ClubsProperties {
    onClose?: () => void;
}

const Clubs: FunctionComponent<ClubsProperties> = (props) => {

    const { showInfoPopup, showAlertPopup, showBusy, hideBusy } = useContext(PopupsContext);
    const [clubsList, setClubsList] = useState<ClubData[]>([]);
    const [showDetails, setShowDetails] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            showBusy('busy', '');
            const clubs = await listClubs();
            if (clubs) {
                const list = await JSON.parse(clubs).clubsList;
                if (list)
                    setClubsList(list.sort((oneClub:ClubData, theOtherClub:ClubData) => oneClub.rate! < theOtherClub.rate! ? 1 : -1));
            }
            hideBusy(); 
        })()
    }, [])


    return (
        <div className={styles.container}>
            <div className={styles.separator}></div>
            {/* <div className={styles.userTopControls}>
                <div className={styles.searchBox}>
                    <Textbox borderColor='#CA7C4E'
                        fontFamily='interRegular'
                        fontSize='14px'
                        placeHolder='Search...'
                        width='80%' height='34px' />
                        <div className={styles.splitter}></div>
                    <Button color='#D9B59D'
                        borderColor='#CA7C4E'
                        border='1px solid #CA7C4E'
                        borderRadius='3px'
                        width='34px' height='34px' image={require('../../../assets/icons/search.png')} imageHeight='24px' imageWidth='24px' />
                </div>
                <SortASC width='32px' />
            </div> */}
            <div className={styles.separatorHalf}></div>
            <div className={styles.contentItems}>
                <List>
                    {
                        clubsList.map((item, index) => {
                            return (
                                <ListItem index={index} key={index}
                                    onClick={() => { }}
                                    data={JSON.stringify({
                                        title: item.name,
                                        details: 'loyalty club'
                                    })}>
                                    <div className={styles.listExpandControl} >
                                        <div>{item.rate}</div>
                                    </div>
                                </ListItem>
                            );
                        })
                    }
                </List>
            </div>

        </div>
    );
}

export default Clubs;
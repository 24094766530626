import React, { FunctionComponent, ReactElement, useContext, useEffect, useRef, useState } from 'react';

import styles from "./appProfile.module.scss";
import { Calendar } from '../../../components/icons/icons';
import Button from '../../../components/Button/Button';
import Textbox from '../../../components/Textbox/Textbox';
import FormItem from '../../../components/FormItem/FormItem';
import Dropdown from '../../../components/DropDown/Dropdown';
import Modal from '../../../components/Modal/Modal';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { PopupsContext } from '../../../handlers/PopupsContext';
import { GlobalContext } from '../../../handlers/GlobalContext';
import { checkDuplicates, getUser, saveUser, UserData } from '../../../restAPI/userGate';
import { listClubs } from '../../../restAPI/clubGate';


interface AppProfileProperties {
    onClose?: () => void;
}

const AppProfile: FunctionComponent<AppProfileProperties> = (props) => {

    const genderList = [
        {'label': 'man', 'value': 'man'},
        {'label': 'woman', 'value': 'woman'},
        {'label': 'not set', 'value': 'not set'}
    ];

    const { showModal, showInfoPopup, showBusy, hideBusy } = useContext(PopupsContext);

    const regexMail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    const regexCell = /^(\+|00)[1-9][0-9]{7,32}$/;
    const [showDetails, setShowDetails] = useState<boolean>(false);
    
    const{user} = useContext(GlobalContext);
    const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
    const [isValidMail, setIsValidMail] = useState<boolean>(true);
    const [isValidPhone, setIsValidPhone] = useState<boolean>(true);
    const [existingEmail, setExistingEmail] = useState<boolean>(false);
    const [existingCell, setExistingCell] = useState<boolean>(false);
    const [passwordRetype, setPasswordRetype] = useState<string>();
    const [currentUser, setCurrentUser] = useState<UserData>();
    const [club, setClub] = useState<string>('');
    

    useEffect(() => {
        (async () => {
            showBusy('busy', '');
            if(user!){
                const result = await getUser(user.pkUser);
                if(result!){
                    console.log(result);
                    const userData = JSON.parse(result);
                    const birthday = new Date(userData.birthday).toLocaleDateString('en-UK', {
                        year: 'numeric',  // Full year (e.g., 2024)
                        month: '2-digit',    // Full month name (e.g., September)
                        day: '2-digit'    // Day of the month (e.g., 13)
                    })
                    setCurrentUser({...userData, birthday});
                    const clubs = await listClubs();
                    if(clubs){
                        console.log(clubs);
                        const list = await JSON.parse(clubs).clubsList;
                        if(list)
                            setClub( list.filter((element:any) => element.pkClub === userData.fkClub )[0].name );
                    }
                }
            }
            hideBusy();
        })()
    }, [user])

    useEffect(() => {
    }, [currentUser])

    return (
        <div className={styles.container}>
            {showDatePicker &&
                <Modal>
                    <div className={styles.timePicker}>
                        <div>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateCalendar onChange={(value) => {
                                    // console.log(birthday);
                                    // setBirthday(value.$y + '/' + value.$M + '/' + value.$D);
                                    setCurrentUser({ ...currentUser, birthday: value.$y + '/' + value.$M + '/' + value.$D });
                                }}></DateCalendar>
                            </LocalizationProvider>
                        </div>
                        <div className={styles.mixedDateControls}>
                            <div className={styles.splitter}></div>
                            <Button width='130px' height='42px' text='cancel' borderColor='#ADB8BA' textColor='#EDEAE3' fontFamily='interBold' fontSize='18px' onClick={() => setShowDatePicker(false)} />
                            <div className={styles.splitter}></div>
                            <Button width='130px' height='42px' text='set' borderColor='#ADB8BA' textColor='#EDEAE3' fontFamily='interBold' fontSize='18px' onClick={() => setShowDatePicker(false)} />
                            <div className={styles.splitter}></div>
                        </div>
                    </div>
                </Modal>
            }
            <div className={styles.userPageTitle}>
                Profile
            </div>
            <div className={styles.separator}></div>
            
            <div className={styles.contentItems}>
                {/* <div className={styles.userPhoto}>
                </div> */}
                <div className={styles.separator}></div>
                <div className={styles.separator}></div>
                <div className={styles.userClub}>
                    <div className={styles.crown}>Crown Gold Gallery</div>
                    <div className={styles.memberClub}>
                        <div className={styles.club}>{club}</div>
                        <div className={styles.member}>member</div>
                    </div>
                    <div className={styles.loyalty}>Loyalty Club</div>
                </div>
                <div className={styles.separator}></div>
                <div className={styles.separator}></div>
                <FormItem title={'name'} titleColor='#253D5B' titleFontFamily='interRegular' titleFontSize='10px' errorMessage={''} width='90%'>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Textbox width={'70%'} height={'34px'} placeHolder={'name'} fontFamily='interRegular' fontSize='14px'
                            text={currentUser?.name}
                            onChange={(value) => {
                                setCurrentUser({ ...currentUser, name: value });
                            }} />
                    </div>
                </FormItem>
                <div className={styles.separator}></div>
                <FormItem title={'surname'} titleColor='#253D5B' titleFontFamily='interRegular' titleFontSize='10px' errorMessage={''} width='90%'>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Textbox width={'80%'} height={'34px'} placeHolder={'surname'} fontFamily='interRegular' fontSize='14px' 
                        text={currentUser?.surname}
                        onChange={(value) => {
                            setCurrentUser({ ...currentUser, surname: value });
                        }} />
                    </div>
                </FormItem>
                <div className={styles.separator}></div>
                <FormItem title={'birthday'} titleColor='#253D5B' titleFontFamily='interRegular' titleFontSize='10px' errorMessage={''} width='90%'>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Textbox width={'50%'} height={'34px'} placeHolder={'name'} fontFamily='interRegular' fontSize='14px' 
                            text={currentUser?.birthday}
                            onClick={(value) => { 
                                setShowDatePicker(true);
                            }} 
                            icon={<Calendar width='32px' />} />
                    </div>
                </FormItem>
                <div className={styles.separator}></div>
                {/* <FormItem title={'gender'} titleColor='#253D5B' titleFontFamily='interRegular' titleFontSize='10px' errorMessage={''} width='90%'>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Dropdown width={'60%'} height={'34px'} placeHolder={'gender'} fontFamily='interRegular' fontSize='14px' onChange={(value) => { 'setEmail(value)' }}
                            items={genderList}
                         />
                    </div>
                </FormItem> 
                <div className={styles.separator}></div>
                */}
                <FormItem title={'email'} errorMessage={!isValidMail ? 'invalid email address' : ''} titleColor='#253D5B' titleFontFamily='interRegular' titleFontSize='10px' width='90%'>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Textbox width={'90%'} height={'34px'} placeHolder={'me@mail.ext'} fontFamily='interRegular' fontSize='14px'
                            validationIcon={existingEmail?? undefined}
                            disabled={currentUser?.pkUser !== undefined}
                            text={currentUser?.email!}
                        onChange={async (value) => {
                            setCurrentUser({ ...currentUser, email: value });
                            if (value !== '') {
                                const emailExists = (await JSON.parse(await checkDuplicates(value, 'EMAIL', currentUser?.pkUser)).result);
                                
                                if (emailExists) {
                                    setExistingEmail(true);
                                }
                                else {
                                    setExistingEmail(false);
                                    if (regexMail.test(value)) {
                                        setIsValidMail(true);
                                    }
                                    else
                                        setIsValidMail(false);
                                }
                            }
                        }}
                         />
                    </div>
                </FormItem>
                
                <div className={styles.separatorDouble}></div>
                <FormItem title={'password'} titleColor='#253D5B' titleFontFamily='interRegular' titleFontSize='10px' 
                    errorMessage={currentUser?.password! !== passwordRetype ? 'password & retype do not match' : ''} 
                    width='90%'>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Textbox width={'75%'} height={'34px'} placeHolder={'password'} fontFamily='interRegular' fontSize='14px' type='password'
                        text={currentUser?.password!}
                        onChange={(value) => {
                            setCurrentUser({ ...currentUser, password: value });
                        }} />
                    </div>
                </FormItem>
                <div style={{ display: 'flex', flexDirection: 'row', width: '90%', color:'gray', justifyContent: 'space-between', alignItems: 'center', fontFamily:'interRegular', fontSize:'10px'  }}>
                        if you want to change your password? enter & retype your new password! if not leave them blank!
                    </div>
                <div className={styles.separatorHalf}></div>
                <FormItem title={'password retype'} titleColor='#253D5B' titleFontFamily='interRegular' titleFontSize='10px' errorMessage={''} width='90%'>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Textbox width={'75%'} height={'34px'} placeHolder={'password retype'} fontFamily='interRegular' fontSize='14px' type='password'
                        text={passwordRetype}
                        onChange={(value) => {
                            setPasswordRetype(value);
                        }} />
                    </div>
                </FormItem>

                <div className={styles.separator}></div>
                <div className={styles.separator}></div>
                <FormItem title={'phone'} titleColor='#253D5B' titleFontFamily='interRegular' titleFontSize='10px' errorMessage={!isValidPhone ? 'invalid phone number' : ''} width='90%'>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Textbox width={'70%'} height={'34px'} placeHolder={'phone'} fontFamily='interRegular' fontSize='14px'
                        text={currentUser?.phone}
                        onChange={async (value) => {
                            setCurrentUser({ ...currentUser, phone: value });
                                    if (value !== '') {
                                        const cellExists = (await JSON.parse(await checkDuplicates(value, 'CELL', currentUser?.pkUser)).result);
                                        
                                        if (cellExists) {
                                            setExistingCell(true);
                                        }
                                        else {
                                            setExistingCell(false);
                                            if (regexCell.test(value)) {
                                                setIsValidPhone(true);
                                            }
                                            else
                                                setIsValidPhone(false);
                                        }
                                    }
                            }} />
                    </div>
                </FormItem>
                <div className={styles.separator}></div>
                <FormItem title={'postal code'} errorMessage={''} width={'100%'}>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Textbox width={'220px'} height={'32px'} placeHolder={'postal code'}
                            text={currentUser?.postalCode!}
                            onChange={(value) => {
                                setCurrentUser({ ...currentUser, postalCode: value });
                            }} />
                    </div>
                </FormItem>
                <div className={styles.separator}></div>
                <FormItem title={'address'} titleColor='#253D5B' titleFontFamily='interRegular' titleFontSize='10px' errorMessage={''} width='90%'>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Textbox type='multiline' width={'100%'} height={'75px'} placeHolder={'address'} fontFamily='interRegular' fontSize='14px'
                        text={currentUser?.address}
                        onChange={(value) => {
                            setCurrentUser({ ...currentUser, address: value });
                        }} />
                    </div>
                </FormItem>
                <div className={styles.separator}></div>
                <div className={styles.separatorDouble}></div>                
                <div className={styles.button}>
                    <Button text='save'
                            textColor='#EDEAE3' fontFamily='interLight' fontSize='16px'
                            border='1px solid #ADB8BA' borderRadius='0px' color='#153147'
                            width='128px' height='38px'
                        onClick={async () => {
                            //if(currentUser!.password! !== passwordRetype){
                                
                            //}
                            //showModal('', 'Your request sent successfully!\nCrown Gold Gallery experts, will contact\nyou soon, about the Item!', null, '', () => {}, () => {} )
                            if (isValidMail && !existingEmail) {
                                showBusy('busy', '');
                                const updatedUser = (await saveUser(currentUser!)).updatedUser;
                                console.log(updatedUser);
                                //const updatedUser = (await JSON.parse(result)); 
                                hideBusy();
                                if(updatedUser.pkUser === currentUser?.pkUser){
                                    showModal('updated', 'your profile updated successfully!', null, '', () => { document.location = '/'; }, () => { document.location = '/'; });
                                }
                                else if(!updatedUser.pkUser){
                                    showModal('update failure', updatedUser.error, null, '', () => {}, () => {});    
                                }
                            }
                        }} />
                </div>
                <div className={styles.separator}></div>
                <div className={styles.separatorDouble}></div>
                <div className={styles.separator}></div>
            </div>

        </div>
    );
}

export default AppProfile;
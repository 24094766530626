import React, { FunctionComponent, ReactElement, useContext, useEffect, useRef, useState } from 'react';

import styles from "./appItems.module.scss";
import { SortASC, SortDESC } from '../../../components/icons/icons';
import AppItemDetails from '../appItemDetails/appItemDetails';
import List from '../../../components/List/List';
import ListItem from '../../../components/ListItem/ListItem';
import { useLocation } from 'react-router-dom';
import { GlobalContext } from '../../../handlers/GlobalContext';
import { PopupsContext } from '../../../handlers/PopupsContext';
import { listItems } from '../../../restAPI/itemGate';
import { Settings } from '../../../helpers/Settings';
import AppItem from '../../../components/inappComponents/AppItem/appItem';
import ListItemEmpty from '../../../components/ListItemEmpty/ListItemEmpty';

interface AppItemsProperties {
    itemsGallery?: string;
    onClose?: () => void;
}

const AppItems: FunctionComponent<AppItemsProperties> = (props) => {

    const { user, displayingCategory } = useContext(GlobalContext);
    const { showBusy, hideBusy } = useContext(PopupsContext);
    const documentLocation = useLocation();
    const [itemsList, setItemsList] = useState<any[]>([]);
    const [selectedItem, setSelectedItem] = useState<string | undefined>(undefined);
    const [searchString, setSearchString] = useState<string>('*');

    const [showDetails, setShowDetails] = useState<boolean>(false);
    const [showSortOptions, setShowSortOptions] = useState<boolean>(false);
    const [isAscending, setIsAscending] = useState<boolean>(false);
    const [sortField, setSortField] = useState<string>();

    // window.addEventListener('popstate', (e) => {            
    //     e.preventDefault();
    //     console.log('what happened!', showDetails);
    //     if(showDetails)
    //         setShowDetails(false);
    //     else
    //         document.location = '/galleries';
    // });

    useEffect(() => {

        (() => {
            if (documentLocation.pathname.split('/')[2]!)
                setSearchString(documentLocation.pathname.split('/')[2]);
            loadList();
        })()
    }, [])

    useEffect(() => {
        (() => {
            loadList();
        })()
    }, [searchString, sortField, isAscending])

    const loadList = async () => {
        console.log(displayingCategory);
        showBusy('busy', '');
        setItemsList([]);
        let result;
        if (user!)
            result = searchString !== null ? await listItems(user, searchString, displayingCategory, isAscending, sortField) : await listItems(user, '*', displayingCategory, false, sortField);
        else
            result = searchString !== null ? await listItems(undefined, searchString, displayingCategory, isAscending, sortField) : await listItems(undefined, '*', displayingCategory, false, sortField);

        if (result !== undefined) {
            setItemsList(await JSON.parse(result).itemsList);
        }
        hideBusy();
    }

    return (
        <div className={styles.container}
            onClick={() => {
                setShowSortOptions(false);
            }}>
            {showDetails &&
                <AppItemDetails itemId={selectedItem!} onClose={() => { setShowDetails(false) }} />
            }

            <div className={styles.separator}></div>
            <div className={styles.userTopControls}>
                <div className={styles.userPageTitle} style={{flexDirection: 'column', alignItems: 'flex-start'}}>
                    <div className={styles.backToGalleries} style={{textDecoration: 'underline'}}
                        onClick={() => { document.location = '/galleries'; }}>
                            Galleries
                    </div>
                    <div className={styles.subPage}>
                        ↪ {props.itemsGallery!}
                    </div>
                </div>
                <div className={styles.sortButton} onClick={(e) => {
                    e.stopPropagation();
                    setShowSortOptions(!showSortOptions);
                }}>
                    {isAscending ? <SortASC width='32px' /> : <SortDESC width='32px' />}
                    {/* <SortASC /> */}
                    {showSortOptions &&
                        <div className={styles.sortMenu}>

                            <ListItem width={250} margin={'0px'} imageSource={require('../../../assets/icons/sortMenuASC.svg').default} index={0} 
                                data={JSON.stringify({ 'title': 'Title (a-z)' })}
                                onClick={() => {
                                    setIsAscending(true);
                                    setSortField('title');
                                }} />
                            <ListItem width={250} imageSource={require('../../../assets/icons/sortMenuDESC.svg').default} index={0} 
                                data={JSON.stringify({ 'title': 'Title (z-a)' })} 
                                onClick={() => {
                                    setIsAscending(false);
                                    setSortField('title');
                                }}/>
                            <ListItem width={250} imageSource={require('../../../assets/icons/sortMenuASC.svg').default} index={0} 
                                data={JSON.stringify({ 'title': 'Date (newer first)' })} 
                                onClick={() => {
                                    setIsAscending(false);
                                    setSortField(undefined);
                                }}/>
                            <ListItem width={250} imageSource={require('../../../assets/icons/sortMenuDESC.svg').default} index={0} 
                                data={JSON.stringify({ 'title': 'Date (older first)' })} 
                                onClick={() => {
                                    setIsAscending(true);
                                    setSortField(undefined);
                                }}/>
                            {/* <ListItem imageSource={require('../../../assets/icons/sortMenuASC.svg').default} index={0} data={JSON.stringify({ 'title': 'Tag (a-z)' })} />
                                <ListItem imageSource={require('../../../assets/icons/sortMenuDESC.svg').default} index={0} data={JSON.stringify({ 'title': 'Tag (z-a)' })} /> */}
                        </div>
                    }
                </div>
            </div>

            <div className={styles.separatorHalf}></div>
            <div className={styles.contentItems}>
                {(itemsList.length === 0) && 
                    <div style={{ display:'flex', flexDirection: 'row', width: '80%', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                        <ListItemEmpty />
                    </div>
                }
                {
                    itemsList.map((item, index) => {
                        return (
                            <div key={index}>
                                <div className={styles.item}>
                                    <AppItem key={index}
                                        index={index}
                                        data={JSON.stringify({
                                            title: item.title,
                                            description: item.description,
                                            price: item.price
                                        })}
                                        onClick={() => {
                                            setSelectedItem(item.pkItem);
                                            setShowDetails(true);
                                        }}
                                        image={`${Settings.api.path}/photos/items/${item.fkOwner}/${item.pkItem}_0.jpg`}
                                        imageSize={164}
                                    //imageFrame='CIRCLE'
                                    />
                                </div>
                                <div className={styles.separator}></div>
                            </div>
                            // <div className={styles.item}
                            //     onClick={() => {
                            //         setShowDetails(true);
                            //     }}>
                            //     <div className={styles.data}>
                            //         <div className={styles.title}>
                            //             {item.title}
                            //         </div>
                            //         <div className={styles.description}>
                            //             {item.description}
                            //         </div>
                            //         <div className={styles.price}>
                            //             <span className={styles.currency}>£&nbsp;</span>{item.price}
                            //         </div>
                            //     </div>
                            //     <div className={styles.photo}>
                            //         <img src={require(`${Settings.api.path}/photos/items/${item.fkOwner}/${item.pkItem}_0.jpg`)} width='164px' height='164px' />
                            //     </div>
                            // </div>
                        );
                    })
                }

            </div>

        </div>
    );
}

export default AppItems;
import React, { FunctionComponent, ReactElement, useContext, useEffect, useRef, useState } from 'react';

import styles from "./noticeInfo.module.scss";
import Modal from '../../../../components/Modal/Modal';
import { CloseModal, Auction, News, Delete } from '../../../../components/icons/icons';
import FormItem from '../../../../components/FormItem/FormItem';
import Textbox from '../../../../components/Textbox/Textbox';
import Button from '../../../../components/Button/Button';
import { PopupsContext } from '../../../../handlers/PopupsContext';
import Dropdown from '../../../../components/DropDown/Dropdown';

import { listClubs } from '../../../../restAPI/clubGate';
import { approveNotice, createNotice, deleteNotice, getNotice, NoticeData, updateNotice } from '../../../../restAPI/noticeGate';
import ItemSelector from '../../items/itemSelector/itemSelector';
import AppItem from '../../../../components/inappComponents/AppItem/appItem';
import { Settings } from '../../../../helpers/Settings';
import { GlobalContext } from '../../../../handlers/GlobalContext';
import ItemInfo from '../../items/itemInfo/itemInfo';
import AppItemDetails from '../../../customer/appItemDetails/appItemDetails';

interface NoticeInfoProperties {
    noticeId?: string | undefined;
    noticeType: 'NEWSLETTER' | 'AUCTION' | 'ALL';
    onClose?: () => void;
}

const NoticeInfo: FunctionComponent<NoticeInfoProperties> = (props) => {

    const { showModal, showInfoPopup, showBusy, hideBusy } = useContext(PopupsContext);
    const { user } = useContext(GlobalContext);
    const [currentNotice, setCurrentNotice] = useState<NoticeData>({ type: props.noticeType, fkOwner: user?.pkUser! });

    const [showInfo, setShowInfo] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<string>('');
    const [clubsList, setClubsList] = useState<[]>([]);
    const [linkingItems, setLinkingItems] = useState<any[]>([]);
    const [showSelector, setShowSelector] = useState<boolean>(false);

    useEffect(() => {

        (async () => {
            showBusy('busy', '');
            const clubs = await listClubs();
            if (clubs)
                setClubsList(JSON.parse(JSON.stringify(JSON.parse(clubs).clubsList.sort((a: any, b: any) => { return (a.rate > b.rate ? 1 : -1) })).replaceAll('pkClub', 'value').replaceAll('name', 'label')));
            if (props.noticeId!) {
                const result = await getNotice(props.noticeId);
                if (result!) {
                    const noticeData = await JSON.parse(result);
                    if (noticeData!) {
                        console.log(noticeData!);
                        if (noticeData.noticeDetails!)
                            setCurrentNotice(noticeData.noticeDetails);
                        if (noticeData.noticeItems!) {
                            console.log(noticeData.noticeItems);
                            setLinkingItems(noticeData.noticeItems);
                        }
                    }
                }
            }
            hideBusy();
        })()
    }, [])

    useEffect(() => {
        (async () => {
            if (currentNotice! && clubsList) {
                setCurrentNotice({ ...currentNotice, items: linkingItems });
            }
        })()
    }, [linkingItems])

    const removeItem = (index: number) => {
        const temp = [...linkingItems];
        temp.splice(index, 1);
        setLinkingItems([...temp]);
    }

    return (
        <Modal zIndex='1000' glassEffect='CLEARGLASS'>
            {showSelector &&
                <ItemSelector onClose={(value) => {
                    setShowSelector(false);
                    if (value!) {
                        const temp = [...linkingItems];
                        temp.push(value);
                        setLinkingItems(temp);
                    }
                }} />
            }
            {showInfo &&
                <AppItemDetails itemId={selectedItem!}
                    onClose={() => {
                        setShowInfo(false)
                    }} />
            }
            <div className={styles.modalPage}>
                <div className={styles.headerContainer}>
                    <div className={styles.close} onClick={() => { props.onClose!() }}>
                        <CloseModal width={'32px'} />
                        <div className={styles.splitter}></div>
                        <div className={styles.splitter}></div>
                    </div>
                    <div className={props.noticeId! ? styles.headerEx : styles.header}>
                        <div className={styles.header}>
                            <div className={styles.splitter}></div>
                            <div className={styles.splitter}></div>
                            {props.noticeType === 'NEWSLETTER' ? <News width={'64px'} fill={'#2C3D5B'} /> : <Auction width={'64px'} fill={'#2C3D5B'} />}
                            <div className={styles.splitter}></div>
                            <div className={styles.text}>
                                <div className={styles.title}>
                                    {
                                        props.noticeId! ? (currentNotice!.type  === 'NEWSLETTER' ? 'news' : 'auction') :  (props.noticeType === 'AUCTION' ? 'new auction' : 'new news')
                                    }
                                </div>
                                <div className={styles.data}>
                                    {
                                        currentNotice.createdAt ? ((new Date(currentNotice.createdAt)).toLocaleDateString('en-US', {
                                            weekday: 'short',  // Full day name (e.g., Monday)
                                            year: 'numeric',  // Full year (e.g., 2024)
                                            month: 'short',    // Full month name (e.g., September)
                                            day: 'numeric'    // Day of the month (e.g., 13)
                                        }) + ' ' + currentNotice.createdAt.split('T')[1].substring(0, 5)) : ''
                                    }</div>
                            </div>
                        </div>
                        <div className={styles.expansion}>
                            <div className={styles.splitter}></div>
                            <div className={styles.splitter}></div>
                        </div>
                    </div>
                </div>
                <div className={styles.bodyLeft}>
                    <div className={styles.separatorHalf}></div>
                    {(user?.type !== 'STAFF' && user?.type !== 'SUPERVISOR') &&
                        currentNotice!.title
                    }
                    {(user?.type === 'STAFF' || user?.type === 'SUPERVISOR') &&
                        <FormItem title={'title'} errorMessage={''} width='100%'>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Textbox width={'85%'} height={'32px'} placeHolder={'name'}
                                    text={currentNotice!.title}
                                    onChange={(value) => {
                                        setCurrentNotice({ ...currentNotice, title: value });
                                    }} />
                            </div>
                        </FormItem>
                    }
                    <div className={styles.separatorHalf}></div>
                    {(user?.type === 'STAFF' || user?.type === 'SUPERVISOR') &&
                        <FormItem title={'target club'} errorMessage={''}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Dropdown items={clubsList} width={'180px'} height={'32px'} placeHolder={'select club'}
                                    //fontFamily='interRegular'
                                    fontSize='14px'
                                    initialItem={currentNotice!.fkClub?.toLowerCase()}
                                    onChange={(selectedData) => {
                                        setCurrentNotice({ ...currentNotice, fkClub: selectedData.value });
                                    }}
                                />
                            </div>
                        </FormItem>
                    }

                    <div className={styles.separatorHalf}></div>
                    {(user?.type !== 'STAFF' && user?.type !== 'SUPERVISOR') &&
                        currentNotice!.description
                    }
                    {(user?.type === 'STAFF' || user?.type === 'SUPERVISOR') &&
                        <FormItem title={'description'} errorMessage={''} width={'100%'}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Textbox width={'100%'} height={'90px'} placeHolder={'description'}
                                    text={currentNotice!.description}
                                    type='multiline'
                                    onChange={(value) => {
                                        setCurrentNotice({ ...currentNotice, description: value });
                                    }}
                                />
                            </div>
                        </FormItem>
                    }

                    <div className={styles.separatorHalf}></div>
                    {(user?.type === 'STAFF' || user?.type === 'SUPERVISOR') &&
                        <FormItem title={'items'} errorMessage={''} width='95%'>
                            <Button
                                fontFamily='interRegular' fontSize='18px' borderColor='#ADB8BA' color='#153147'
                                width='32px' height='32px' textColor='#EDEAE3'
                                borderRadius='5px' border='1px solid #C7CACE'
                                image={require('../../../../assets/icons/attach.svg').default}
                                imageWidth='28px'
                                imageHeight='28px'
                                onClick={() => {
                                    setShowSelector(true);
                                }} />
                        </FormItem>
                    }
                    <div className={styles.itemsList}
                        style={{
                        }}>
                        {
                            linkingItems!.map((item, index) => {
                                return (
                                    <div className={styles.item}>
                                        <AppItem listType='QUICKPICKER' index={index}
                                            onClick={() => {
                                                setSelectedItem(item.pkItem);
                                                setShowInfo(true);
                                            }}
                                            key={index}
                                            itemWidth={96}
                                            imageSize={96}
                                            titleFont={'interRegular'}
                                            titleColor={'#2C3D5B'}
                                            titleFontSize={14}
                                            image={`${Settings.api.path}/photos/items/${item.fkOwner}/${item.pkItem}_0.jpg`}
                                            data={
                                                JSON.stringify({
                                                    title: item.title,
                                                    description: item.description,
                                                    price: item.price
                                                })
                                            } />
                                        {(user?.type === 'SUPERVISOR' || (user?.type === 'STAFF' && !currentNotice.isConfirmed)) &&
                                            <Button
                                                borderRadius='50%'
                                                overlaySpec={'top-right'} color='white'
                                                border='2px solid red'
                                                width={'24px'}
                                                height={'24px'}
                                                imageWidth={'16px'}
                                                imageHeight={'16px'}
                                                image={require('../../../../assets/icons/closeRed.png')}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    removeItem(index);
                                                }} key={index} />
                                        }
                                    </div>
                                )
                            })
                        }

                    </div>

                    <div className={styles.separator}></div>
                    <div className={styles.separator}></div>
                    {(user?.type === 'STAFF' || user?.type === 'SUPERVISOR') &&
                        <div className={styles.button}>
                            <Button text='save'
                                textColor='#EDEAE3' fontFamily='interLight' fontSize='16px'
                                border='1px solid #ADB8BA' borderRadius='0px' color='#153147'
                                width='128px' height='38px'
                                onClick={async () => {
                                    if (props.noticeId!) {
                                        showBusy('busy', '');
                                        const response = await updateNotice(currentNotice);
                                        hideBusy();
                                        console.log(response);
                                        if (response!) {
                                            if (response.updatedNotice.pkNotice === props.noticeId)
                                                props.onClose!();
                                            else
                                                showModal('update failure', response.error, null, '', () => { }, () => { });
                                        }
                                    }
                                    else {
                                        showBusy('busy', '');
                                        const response = await createNotice(currentNotice!);
                                        if (response!) {
                                            if (response.insertedNotice!)
                                                props.onClose!();
                                            else
                                                showModal('failure', response.error, null, '', () => { }, () => { });
                                        }
                                        hideBusy();
                                    }
                                }}
                            />
                        </div>
                    }
                    {(user?.type === 'SUPERVISOR') && (currentNotice.isConfirmed) && props.noticeId! &&
                        <div className={styles.listExpandControl}
                            onClick={async () => {
                                const typeLabel = (props.noticeType! === 'AUCTION' ? 'auction' : 'newsletter');
                                showModal('delete ' + typeLabel, 'deleting a ' + typeLabel + ' will remove all its copies on the system and no one will be able to see it again, are you sure you want to continue?',
                                    <div>
                                        <div>to cancel: close, to delete: ok</div>
                                    </div>
                                    , 'ok',
                                    async () => {
                                        showBusy('busy', '');
                                        const result = await deleteNotice(props.noticeId!);
                                        if (result.pkNotice === props.noticeId) {
                                            showModal('delete ' + typeLabel, typeLabel + ' deleting, completed successfully!', null, '', () => { }, () => { });
                                        }
                                        props.onClose!();
                                        hideBusy();
                                    },
                                    () => {

                                    });
                            }}>
                            <Delete height={'24px'} fill={'#2C3D5B'} />
                        </div>
                    }
                </div>
                <div className={styles.separator}></div>
                <div className={styles.separator}></div>
            </div>
        </Modal>
    );
}

export default NoticeInfo;
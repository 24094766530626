import { FunctionComponent } from "react";
import { rejects } from "assert";
import { Settings } from "../helpers/Settings";

export enum EnquiryStatus {
    NEW = 'NEW',
    LISTED = 'LISTED',
    OPENED = 'OPENED'
}

export enum ListingTypes {
    /** the Member who made the enquiry */
    ENQUIRER = 'ENQUIRER',
    /** STAFF sees all enquiries made under the items they've made and handling */
    OWNER = 'OWNER',
    /** system admin sees all enquiries */
    SUPERVISOR = 'SUPERVISOR'
}

export interface EnquiryData {

}

export const createEnquiry = async (enquirerId:string, itemId:string, enquiryMessage:string): Promise<any | undefined> => {
    return new Promise((resolve, reject) => {
        fetch(Settings.api.path + "/enquiry/new", {
            method: 'POST',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React',
            }), 
            body: JSON.stringify({
                enquirerId,
                itemId,
                enquiryMessage
            })
        }).then((response) => {            
            response.json().then((result) => {                
                resolve(result);
            }).catch((error) => {resolve(undefined)});
        }).catch((error) => {            
            resolve(undefined);
        });
    });
}

export const listEnquiries = async (listForId:string, listForType:ListingTypes, lookupEnquiry:string, isAscending:boolean, sortField:string|undefined): Promise<any | undefined> => {
    console.log(`${listForId}\n${listForType}`);
    return new Promise((resolve, reject) => {
        fetch(Settings.api.path + `/enquiry/list/${isAscending ? (sortField ?? 'createdAt') + ':ASC' : (sortField ?? 'createdAt') + ':DESC'}`, {
            method: 'POST',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React',
            }),
            body: JSON.stringify({
                listForId,
                listingFor: listForType,
                lookupEnquiry
            })
        }).then((response) => {      
            console.log(response);
            response.json().then((result) => {
                resolve(JSON.stringify(result));
            }).catch((error) => {resolve(error)});
        }).catch((error) => {
            resolve(undefined);
        });
    });
}


export const loadEnquiry = async (enquiryId:string): Promise<any | undefined> => {
    return new Promise((resolve, reject) => {
        fetch(Settings.api.path + "/enquiry/view", {
            method: 'POST',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React',
            }),
            body: JSON.stringify({
                enquiryId
            })
        }).then((response) => {      
            console.log(response);
            response.json().then((result) => {
                resolve(JSON.stringify(result));
            }).catch((error) => {resolve(error)});
        }).catch((error) => {
            resolve(undefined);
        });
    });
}
import React, { FunctionComponent, ReactElement, useContext, useEffect, useRef, useState } from 'react';

import styles from "./appMenu.module.scss";
import Modal from '../../../components/Modal/Modal';
import { Auction, CloseModal, Enquiry, Exchange, Install, Logout, News, Profile, Updates } from '../../../components/icons/icons';

interface AppMenuProps {
    pageFunction?: 'LOGIN' | 'SIGNUP' | 'ّFORGOTPASSWORD' | 'ّRESETPASSWORD';
    showInstall?: boolean;
    deferredPrompt?: any;
    onClose?: () => void;
}

const AppMenu: FunctionComponent<AppMenuProps> = (props) => {

    const [showInstallButton, setShowInstallButton] = useState(props.showInstall ?? false);

    useEffect(() => {
        (async () => {

        })()
    }, [])

    // useEffect(() => {
    //     // window.addEventListener('beforeinstallprompt', (e) => {
    //     //     // Prevent the mini-infobar from appearing on mobile
    //     //     e.preventDefault();
    //     //     // Save the event so it can be triggered later
    //     //     deferredPrompt = e;
    //     //     // Show your "Install" button here
    //     //     setShowInstallButton(true);
    //     // });
        
    //     // return () => {
    //     //     window.removeEventListener('beforeinstallprompt', () => { });
    //     // };
    // }, []);


    const handleInstallClick = () => {
        if (props.deferredPrompt!) {
            props.deferredPrompt.prompt();
            props.deferredPrompt.userChoice.then((choiceResult: any) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the install prompt');
                } else {
                    console.log('User dismissed the install prompt');
                }
                //props.deferredPrompt = null;
            });
        }
    };

    return (
        <Modal zIndex='1000' glassEffect='CLEARGLASS'>
            <div className={styles.modalPage}>
                <div className={styles.headerContainer}>
                    <div className={styles.close} onClick={() => { props.onClose!() }}>
                        <CloseModal width={'32px'} /> 
                        <div className={styles.splitter}></div>
                        <div className={styles.splitter}></div>
                    </div>
                </div>
                <div className={styles.menuContainer}>
                    <div className={styles.menuItem}
                        onClick={() => {
                            document.location = '/enquiries';
                        }}>
                        <Enquiry fill={styles.darkColorMain} height={'32px'} />&nbsp;Enqiries&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                    <div className={styles.separatorHalf}></div>
                    <div className={styles.menuItem}
                        onClick={() => {
                            document.location = '/updates/NEWSLETTER';
                        }}>
                        <News fill={styles.darkColorMain} height={'32px'} />&nbsp;News&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                    <div className={styles.separatorHalf}></div>
                    <div className={styles.menuItem}
                        onClick={() => {
                            document.location = '/updates/AUCTION';
                        }}>
                        <Auction fill={styles.darkColorMain} height={'32px'} />&nbsp;Auctions&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                    <div className={styles.separatorHalf}></div>
                    <div className={styles.menuItem}
                        onClick={() => {
                            document.location = '/exchanges';
                        }}>
                        <Exchange fill={styles.darkColorMain} height={'28px'} />&nbsp;Exchange&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                    <div className={styles.separatorHalf}></div>
                    <div className={styles.menuItem}
                        onClick={() => {
                            document.location = '/profile';
                        }}>
                        <Profile fill={styles.darkColorMain} height={'32px'} />&nbsp;Profile&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                    <div className={styles.separatorHalf}></div>
                    {showInstallButton &&
                        <>
                            <div className={styles.menuItem}
                                onClick={() => {
                                    handleInstallClick();
                                }}>
                                <Install fill={styles.darkColorMain} height={'32px'} />&nbsp;Install&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </div>
                            <div className={styles.separatorHalf}></div>
                        </>
                    }
                    <div className={styles.menuItem}
                        onClick={() => {
                            localStorage.removeItem('activeAccount');
                            document.location = '/';
                            props.onClose!();
                        }}>
                        <Logout fill={styles.darkColorMain} height={'32px'} />&nbsp;Logout&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                </div>
                <div className={styles.separator}></div>
            </div>
        </Modal>
    );
}

export default AppMenu;
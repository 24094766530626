import React, { forwardRef, FunctionComponent, ReactElement, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';

import styles from "./items.module.scss";
import { Approve, AttachLink, Delete, Down, SortASC, SortDESC, Up } from '../../../components/icons/icons';
import Button from '../../../components/Button/Button';
import Textbox from '../../../components/Textbox/Textbox';
import ListItem from '../../../components/ListItem/ListItem';
import List from '../../../components/List/List';
import ToggleButton from '../../../components/ToggleButton/ToggleButton';
import ListItemEmpty from '../../../components/ListItemEmpty/ListItemEmpty';
import { PopupsContext } from '../../../handlers/PopupsContext';
import ItemInfo from './itemInfo/itemInfo';
import { listCategories } from '../../../restAPI/categoryGate';
import { approveItem, deleteItem, listItems } from '../../../restAPI/itemGate';
import { GlobalContext } from '../../../handlers/GlobalContext';
import { Settings } from '../../../helpers/Settings';
import { useParams } from 'react-router-dom';


interface ItemsProperties {
    itemsList?: any[]
    ignoreHeader?:boolean;
    isSelector?:boolean;
    onClose?: (selectedItem:any) => void;
}

export interface ItemCommunicators{
    onRefresh: () => void;
}

const Items = forwardRef<ItemCommunicators, ItemsProperties>((props, ref) => {
    
    const { user } = useContext(GlobalContext);
    const { showBusy, hideBusy } = useContext(PopupsContext);
    
    const {lookupFor} = useParams();
    const [showInfo, setShowInfo] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<string>('');
    const [lookupItem, setLookupItem] = useState<string>('*');    
    const [isAscending, setIsAscending] = useState<boolean>(false);
    const [itemsList, setItemsList] = useState<any[]>([]);

    // Expose methods using useImperativeHandle
    useImperativeHandle(ref, () => ({
        onRefresh() {
            loadList();
        },
    }));

    useEffect(() => {
        (async () => {
            setLookupItem(lookupFor?? '*');
            loadList();
        })()
    }, [props.itemsList, isAscending])
    
    useEffect(() => {
        if(lookupItem === '*')
            loadList();
    }, [selectedItem, lookupItem])

    const loadList = async () => {
        showBusy('busy', '');
        setItemsList([]);
        const result = await listItems(user, lookupItem.trim() === '' ? '*' : lookupItem, undefined, isAscending, undefined);

        if (result!) {
            const itemsToList = await JSON.parse(result).itemsList;
            if(props.isSelector!){
                console.log(props.isSelector!, props.itemsList!);
                if(props.itemsList!){
                    const selectedList = itemsToList.filter((element:any) => element.isConfirmed ).filter((element:any) => props.itemsList!.includes(element.pkItem));
                    console.log(selectedList + ' <> ' + itemsToList);
                    setItemsList(selectedList);
                }
                else
                    setItemsList(itemsToList.filter((element:any) => element.isConfirmed ));
            }
            else
                setItemsList(itemsToList);
        }
        hideBusy();
    }

    return (
        <div className={styles.container}>
            {showInfo && !props.isSelector! &&
                <ItemInfo itemId={selectedItem} 
                    onClose={() => { 
                        loadList();
                        setShowInfo(false); 
                    }} />
            }
            <div className={styles.separator}></div>
            {!props.ignoreHeader &&
                <div className={styles.userTopControls}>
                    <div className={styles.searchBox}>
                        <Textbox borderColor='#CA7C4E'
                            fontFamily='interRegular'
                            fontSize='14px'
                            placeHolder='Search...'
                            isSearchbox
                            text={lookupItem === '*' ? '' : lookupItem}
                            onClearSearch={ () => {
                                setLookupItem('*');
                            }}
                            width='80%' height='34px'
                            onChange={(value) => {
                                if(value.trim() !== '')
                                    setLookupItem(value);
                                else{
                                    setLookupItem('*');
                                }
                            }}
                        />
                        <div className={styles.splitter}></div>
                        <Button color='#D9B59D'
                            borderColor='#CA7C4E'
                            border='1px solid #CA7C4E'
                            borderRadius='3px'
                            width='34px' height='34px' image={require('../../../assets/icons/search.png')} imageHeight='24px' imageWidth='24px'
                            onClick={() => {
                                loadList();
                            }}
                        />
                    </div>
                    <div
                    onClick={() => {
                        setIsAscending(!isAscending);
                    }}>
                    {isAscending ? <SortASC width='32px' /> : <SortDESC width='32px' />}
                </div>
                </div>
            }
            <div className={styles.separatorHalf}></div>
            <div className={styles.contentItems}>                
                <List>
                    {itemsList.length === 0 && 
                            <ListItemEmpty text='no items to show!' />
                    }
                    {itemsList.length > 0 && 
                        
                        itemsList.map((item, index) => {
                            let date = ((new Date(item.createdAt)).toLocaleDateString('en-US', {
                                weekday: 'short',  // Full day name (e.g., Monday)
                                year: 'numeric',  // Full year (e.g., 2024)
                                month: 'short',    // Full month name (e.g., September)
                                day: 'numeric'    // Day of the month (e.g., 13)
                            }) + ' ' + item.createdAt.split('T')[1].substring(0,5));
                            if (!item.isConfirmed)
                                return (
                                    <ListItem index={index} key={index}
                                        onClick={() => {
                                            setSelectedItem(item.pkItem);
                                            setShowInfo(true);
                                        }}
                                        expandable
                                        expandIcon={<Down width={'32px'} fill={'#ADB8BA'} />}
                                        shrinkIcon={<Up width={'32px'} fill='#ADB8BA' />}

                                        imageSource={`${Settings.api.path}/photoThumbs/items/${item.fkOwner}/${item.pkItem}_0.jpg`}
                                        imageFrame='CIRCLE'
                                        imageSize={64}
                                        imageOpacity={0.5}

                                        data={JSON.stringify({
                                            title: item.title,
                                            details: item.description,
                                            extra: date
                                        })}
                                        >
                                        <div className={styles.listExpandControl} onClick={async () => {
                                            showBusy('busy', '');
                                            const result = await deleteItem(item.pkItem);
                                            if(result.pkItem === item.pkItem){
                                                const temp = {...itemsList.filter((element) => element.pkItem !== item.pkItem)};
                                                setItemsList(temp);
                                                loadList();
                                                setSelectedItem('1');
                                            }
                                            hideBusy();
                                        }}>
                                            <Delete height={'24px'} fill={'#A8623E'} />
                                            <div className={styles.text}>
                                                delete
                                            </div>
                                        </div>
                                        <div className={styles.splitter}></div>
                                        <div className={styles.splitter}></div>
                                        {(user.type === 'SUPERVISOR') &&
                                            <div className={styles.listExpandControl} onClick={async () => {
                                                showBusy('busy', '');
                                                await approveItem(item.pkItem);
                                                loadList();
                                                setSelectedItem(item.pkItem);
                                                hideBusy();
                                            }}>
                                                <Approve height={'24px'} fill={'#A8623E'} />
                                                <div className={styles.text}>
                                                    approve
                                                </div>
                                            </div>
                                        }
                                    </ListItem>                                    
                                );
                            else
                                return (
                                    <ListItem index={index} key={index}
                                        onClick={() => {
                                            setSelectedItem(item.pkItem);
                                            setShowInfo(true);
                                        }}
                                        imageSource={`${Settings.api.path}/photoThumbs/items/${item.fkOwner}/${item.pkItem}_0.jpg`}
                                        imageFrame='CIRCLE'
                                        imageSize={64}                                        
                                        // expandable
                                        // expandIcon={<Down width={'32px'} fill={'#ADB8BA'} />}
                                        // shrinkIcon={<Up width={'32px'} fill='#ADB8BA' />}
                                        data={JSON.stringify({
                                            title: item.title,
                                            details: item.description,
                                            extra: date
                                        })}>
                                        <div className={styles.listExpandControl} >
                                            {/* <div>{item.details}</div> */}
                                            {props.isSelector &&
                                            <div onClick={() => {props.onClose!(item);}}>
                                                <AttachLink />
                                                </div>
                                            }
                                        </div>
                                    </ListItem>
                                );
                        })
                    }
                </List>
            </div>

        </div>
    );
});

export default Items;
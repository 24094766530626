import React, { FunctionComponent, ReactElement, useContext, useEffect, useRef, useState } from 'react';

import styles from "./itemTagSelect.module.scss";
import Modal from '../../../components/Modal/Modal';
import { CloseModal, Auction, News, SigninHeader, Staff, Tag } from '../../../components/icons/icons';
import FormItem from '../../../components/FormItem/FormItem';
import Textbox from '../../../components/Textbox/Textbox';
import Button from '../../../components/Button/Button';
import { PopupsContext } from '../../../handlers/PopupsContext';
import ToggleButton from '../../../components/ToggleButton/ToggleButton';
import Dropdown from '../../../components/DropDown/Dropdown';

import { listClubs } from '../../../restAPI/clubGate';
import { approveNotice, createNotice, getNotice, NoticeData } from '../../../restAPI/noticeGate';
import { createTag, listTags, TagData, updateTag } from '../../../restAPI/tagGate';
import SelectList from '../../../components/SelectList/selectList';

interface ItemTagSelectProperties {
    tagId?: string | undefined;
    tagTitle?: string;
    selectedTags?: TagData[];
    onClose: (pickedTags:any[]) => void;
}

const ItemTagSelect: FunctionComponent<ItemTagSelectProperties> = (props) => {

    const { showModal, showInfoPopup, showBusy, hideBusy } = useContext(PopupsContext);
    const [lookupText, setlookupText] = useState<string>('');
    //const [currentTag, setCurrentTag] = useState<TagData>({ pkTag: props.tagId!, title: props.tagTitle });

    const [pickedTags, setPickedTags] = useState<any[]>(props.selectedTags?? []);
    const [similarTagsList, setSimilarTagsList] = useState<any[]>([]);

    useEffect(() => {
        (async () => {
            showBusy('busy', '');

            hideBusy();
        })()
    }, [])

    useEffect(() => {
        (async () => {
            listSimilarTags(lookupText);
        })()
    }, [lookupText, pickedTags])

    const listSimilarTags = async (value: string) => {
        const tempSimilarTags: any[] = [];
        while (tempSimilarTags.length > 0)
            tempSimilarTags.pop();
        const result = await listTags(value !== '' ? value : '*', true);
        if (result) {
            const tagsList = await JSON.parse(result).tagsList;

            if (tagsList) {
                tagsList.map((item: TagData) => {
                    const alreadyPicked = pickedTags.find((tagElement) => tagElement.value === item.pkTag);
                    if (alreadyPicked === undefined)
                        tempSimilarTags.push({ label: item.title, value: item.pkTag, isSelected: false });
                })
            }
        }
        setSimilarTagsList(tempSimilarTags);
    }

    const selectTag = (value: string) => {
        const tempPickedTags = [...pickedTags];

        const pickedTag = similarTagsList.find((tagElement) => tagElement.value === value);
        console.log(pickedTag);

        tempPickedTags.push(pickedTag);

        setPickedTags(tempPickedTags);
    }

    const unselectTag = (value: string) => {
        const tempPickedTags = pickedTags.filter((tagElement) => tagElement.value !== value);
//        console.log(itemIndex, pickedTags.length + ' <-> ' + tempPickedTags.length);
        setPickedTags(tempPickedTags);
    }

    return (
        <Modal zIndex='1000' glassEffect='GLASS'>
            <div className={styles.modalPage}>
                <div className={styles.headerContainer}>
                    <div className={styles.close} onClick={() => { props.onClose!(pickedTags) }}>
                        <CloseModal width={'32px'} />
                        <div className={styles.splitter}></div>
                        <div className={styles.splitter}></div>
                    </div>
                    <div className={styles.headerEx}>
                        <div className={styles.header}>
                            <div className={styles.splitter}></div>
                            <div className={styles.splitter}></div>
                            <Tag width={'64px'} fill={'#2C3D5B'} />
                            <div className={styles.splitter}></div>
                            <div className={styles.text}>
                                <div className={styles.title}>item tags</div>
                            </div>
                        </div>
                        <div className={styles.expansion}>
                            <div className={styles.splitter}></div>
                            <div className={styles.splitter}></div>
                        </div>
                    </div>
                </div>
                <div className={styles.bodyLeft}>
                    <div className={styles.separatorHalf}></div>
                    <FormItem title={'lookup'} errorMessage={''}>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Textbox width={'200px'} height={'32px'} placeHolder={'lookup tag'}
                                text={lookupText}
                                onChange={(value) => {
                                    //setCurrentTag({ ...currentTag, title: value });
                                    setlookupText(value);                                    
                                }} />
                        </div>
                    </FormItem>

                    <div className={styles.separatorHalf}></div>
                    <FormItem title={'tags'} errorMessage={''} width='100%'>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '200px', justifyContent: 'space-between', alignItems: 'center' }}>
                            <SelectList selectedColor={styles.darkColorMain} unselectedColor={styles.lightBackgrounds}
                                listItems={similarTagsList}
                                itemClicked={(value) => {
                                    selectTag(value);
                                    console.log(value);
                                }}
                            />
                        </div>
                    </FormItem>

                    <div className={styles.separatorDouble}></div>
                    <div className={styles.separatorDouble}></div>
                    <FormItem title={'selected tags'} errorMessage={''} width='100%'>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '150px', justifyContent: 'space-between', alignItems: 'center' }}>
                            <SelectList listBackgroundColor={'#EDEAE3'} 
                                selectedColor={styles.darkColorMain} unselectedColor={styles.lightBackgrounds} 
                                listItems={pickedTags}
                                itemClicked={(value) => {
                                    unselectTag(value);
                                    console.log(value);
                                }}
                            />
                        </div>
                    </FormItem>


                    <div className={styles.separator}></div>
                    <div className={styles.separator}></div>
                    <div className={styles.button}>
                        {/* <Button text='save'                         textColor='#EDEAE3' fontFamily='interLight' fontSize='16px'
                        border='1px solid #ADB8BA' borderRadius='0px' color='#153147'
                        width='128px' height='38px'
                            onClick={() => {
                                props.onClose(pickedTags);
                            }}
                        /> */}
                    </div>
                </div>
                <div className={styles.separator}></div>
                <div className={styles.separator}></div>
            </div>
        </Modal>
    );
}

export default ItemTagSelect;
import React, { FunctionComponent, ReactElement, useContext, useEffect, useRef, useState } from 'react';

import styles from "./menu.module.scss";
import Modal from '../../../components/Modal/Modal';
import { CloseModal, Enquiry, Exchange, Install, Logout, Profile, Updates } from '../../../components/icons/icons';
import { PopupsContext } from '../../../handlers/PopupsContext';

interface MenuProps {
    pageFunction?: 'LOGIN' | 'SIGNUP' | 'ّFORGOTPASSWORD' | 'ّRESETPASSWORD';
    showInstall?:boolean;
    deferredPrompt?: any;
    onClose?: () => void;
}

const Menu: FunctionComponent<MenuProps> = (props) => {

    const { showBusy, hideBusy } = useContext(PopupsContext);
    const [showInstallButton, setShowInstallButton] = useState(props.showInstall?? false);

    useEffect(() => {
        (async () => {

        })()
    }, [])

    // let deferredPrompt:any;
    // useEffect(() => {
    //     window.addEventListener('beforeinstallprompt', (e) => {
    //         // Prevent the mini-infobar from appearing on mobile
    //         e.preventDefault();
    //         // Save the event so it can be triggered later
    //         deferredPrompt = e;
    //         // Show your "Install" button here
    //         setShowInstallButton(true);
    //     });

    //     return () => {
    //         window.removeEventListener('beforeinstallprompt', () => { });
    //     };
    // }, []);


    const handleInstallClick = () => {
        if (props.deferredPrompt!) {
            props.deferredPrompt.prompt();
            props.deferredPrompt.userChoice.then((choiceResult: any) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the install prompt');
                } else {
                    console.log('User dismissed the install prompt');
                }
                //props.deferredPrompt = null;
            });
        }
    };

    return (
        <Modal zIndex='1000' glassEffect='CLEARGLASS'>
            <div className={styles.modalPage}>
                <div className={styles.headerContainer}>
                    <div className={styles.close} onClick={() => { props.onClose!() }}>
                        <CloseModal width={'32px'} />
                        <div className={styles.splitter}></div>
                        <div className={styles.splitter}></div>
                    </div>
                </div>
                <div className={styles.menuContainer}>
                    {showInstallButton &&
                        <div className={styles.menuItem}
                            onClick={() => {
                                handleInstallClick();
                            }}>
                            <Install fill={styles.darkColorMain} height={'44px'} />&nbsp;Install
                        </div>
                    }
                    <div className={styles.separatorHalf}></div>
                    <div className={styles.menuItem}
                        onClick={() => {
                            showBusy('busy', '');
                            localStorage.removeItem('activeAccount');
                            document.location = '/';
                            hideBusy();
                        }}>
                        <Logout fill={styles.darkColorMain} height={'48px'} />&nbsp;Logout
                    </div>
                    <div className={styles.separatorHalf}></div>
                </div>
                <div className={styles.splitter}></div>
            </div>
        </Modal>
    );
}

export default Menu;
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { register as registerServiceWorker } from './serviceWorkerRegistration';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

window.addEventListener('load', () => {
  if (window.location.hostname !== 'localhost') {
    if (navigator.serviceWorker) {
      navigator.serviceWorker.register('/service-worker.js').then((registration) => {
        registration.onupdatefound = () => {
          const installingWorker = registration.installing;
          installingWorker!.onstatechange = () => {
            if (installingWorker!.state === 'installed') {
              if (navigator.serviceWorker.controller) {
                // New version available, force reload
                window.location.reload();
              }
            }
          };
        };
      });
    }
  }
});


root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

//registerServiceWorker();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

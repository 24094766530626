import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import styles from './infoPopup.module.scss';

interface InfoPopupProps {
    fontFamily?: string;
    fontSize?: string;
    title: string;
    body: string;
    textColor?: string;
    color?: string;
    borderColor?: string;
    width?: string;
    height?: string;
    onOk: () => void;
    children?: React.ReactNode;
    opacity?: number;
}

const InfoPopup: FunctionComponent<InfoPopupProps> = (props) => {

    useEffect(() => {
    }, [])

    return (
        <div className={styles.modalContainer}
            style={{
                fontFamily: props.fontFamily!, fontSize: props.fontSize!,
                width: props.width!,
                height: props.height!,
            }}
            onClick={() => {
                props.onOk();
            }}>
            <div className={styles.infoContainer} >
                <div className={styles.headerContainer}>
                    <div className={styles.imageContainer}>
                        <img src={require('../../assets/icons/components/infoPopup/info.png')} />
                    </div>
                    <div className={styles.titleContainer}>
                        {props.title}
                    </div>
                </div>
                {props.children}
                <div className={styles.dataContainer} >
                    <div className={styles.message}>
                        {props.body}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InfoPopup;
import React, { FunctionComponent, ReactElement, useContext, useEffect, useRef, useState } from 'react';

import styles from "./exchanges.module.scss";
import { Buy, CurrencyDollar, CurrencyEuro, CurrencyPound, Sell, Exchange, Online } from '../../../components/icons/icons';
import Textbox from '../../../components/Textbox/Textbox';
import FormItem from '../../../components/FormItem/FormItem';
import Button from '../../../components/Button/Button';
import { Currency, ExchangeData, listExchanges, updateExchanges } from '../../../restAPI/exchangeGate';
import { PopupsContext } from '../../../handlers/PopupsContext';


interface ExchangesProperties {
    onClose?: () => void;
}

const Exchanges: FunctionComponent<ExchangesProperties> = (props) => {

    const { showModal, showInfoPopup, showAlertPopup, showBusy, hideBusy } = useContext(PopupsContext);
    const [currentExchanges, setCurrentExchanges] = useState<ExchangeData[]>([
        { currency: Currency.GBP, sellIRR: 0, buyIRR: 0 },
        { currency: Currency.GBPo, sellIRR: 0, buyIRR: 0 },
        { currency: Currency.EUR, sellIRR: 0, buyIRR: 0 },
        { currency: Currency.USD, sellIRR: 0, buyIRR: 0 },
        { currency: Currency.IRCoinFull, sellIRR: 0, buyIRR: 0 },
        { currency: Currency.IRCoinHalf, sellIRR: 0, buyIRR: 0 },
        { currency: Currency.IRCoinQuarter, sellIRR: 0, buyIRR: 0 },
        { currency: Currency.IRCoinGram, sellIRR: 0, buyIRR: 0 }
    ]);
    const [update, setUpdate] = useState<String>('');

    useEffect(() => {
        (async () => {
            showBusy('busy', '');
            const exchanges = await listExchanges();
            if (exchanges) {
                const list = await JSON.parse(exchanges).exchangesList;
                if (list) {
                    setCurrentExchanges(list);
                    const updated = new Date(list[0].updatedAt);
                    setUpdate(
                        updated.toLocaleDateString('en-GB', {
                            weekday: 'long',  // Full day name (e.g., Monday)                          
                        })
                        + '     ' +
                        updated.toLocaleTimeString('en-GB', {
                            hour: '2-digit',
                            minute: '2-digit'
                        })
                        + '     ' +
                        updated.toLocaleDateString('en-UK', {
                            year: 'numeric',  // Full year (e.g., 2024)
                            month: 'long',    // Full month name (e.g., September)
                            day: 'numeric'    // Day of the month (e.g., 13)
                        })
                    );
                }
            }
            hideBusy();
        })()
    }, [])

    const getRate = (currency: Currency, direction: string): string => {
        if (currentExchanges!.length !== 0) {
            const gettingExchange = currentExchanges.filter((element) => element.currency === currency)[0];
            if (!gettingExchange)
                return '0';
            if (direction === 'SELL')
                return (gettingExchange.sellIRR ?? 0).toString();
            if (direction === 'BUY')
                return (gettingExchange.buyIRR ?? 0).toString();
        }
        return '0';
    }

    const setRate = (currency: Currency, direction: string, value: number) => {
        // if(!Number.isNaN(value))
        //     return;
        if (currentExchanges) {//} && Number.isNaN(value)) {
            const tempExchanges = [...currentExchanges];

            const updatingExchange = tempExchanges.filter((element) => element.currency === currency)[0];
            const updatingIndex = tempExchanges.indexOf(updatingExchange);
            if (!updatingExchange) {
                console.log(value);
                tempExchanges.push({ currency, sellIRR: direction === 'SELL' ? value : 0, buyIRR: direction === 'BUY' ? value : 0 });
                setCurrentExchanges(tempExchanges);
            }
            else {
                if (direction === 'SELL') {
                    tempExchanges[updatingIndex].sellIRR = value;
                    setCurrentExchanges(tempExchanges);
                }
                if (direction === 'BUY') {
                    tempExchanges[updatingIndex].buyIRR = value;
                    setCurrentExchanges(tempExchanges);
                }
            }
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.contentItems}>
                <div className={styles.dateContainer}>
                    {update}
                </div>
                <div className={styles.separatorHalf}></div>
                <div className={styles.currency + ' ' + styles.bgPound}>
                    <div className={styles.sellContainer}>
                        <div className={styles.icon}>
                            <CurrencyPound />
                        </div>
                        <div className={styles.data}>
                            <FormItem title={'Tomans'} titleColor='#253D5B' titleFontFamily='interRegular' titleFontSize='10px' errorMessage={''} width='90%'>
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Textbox width={'100%'} height={'34px'} borderColor='#EDEAE3' type='number'
                                        placeHolder={'tomans'} fontFamily='interRegular' fontSize='14px'
                                        text={getRate(Currency.GBP, 'SELL')}
                                        onChange={(value) => {
                                            setRate(Currency.GBP, 'SELL', value);
                                        }} icon={<Sell />} />
                                </div>
                            </FormItem>
                            <div className={styles.label}>sell</div>
                        </div>
                    </div>
                    <div className={styles.buyContainer}>
                        <div className={styles.icon}>
                            <CurrencyPound />
                        </div>
                        <div className={styles.data}>
                            <FormItem title={'Tomans'} titleColor='#253D5B' titleFontFamily='interRegular' titleFontSize='10px' errorMessage={''} width='90%'>
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Textbox width={'100%'} height={'34px'} borderColor='#EDEAE3' type='number'
                                        placeHolder={'tomans'} fontFamily='interRegular' fontSize='14px'
                                        text={getRate(Currency.GBP, 'BUY')}
                                        onChange={(value) => {
                                            setRate(Currency.GBP, 'BUY', value);
                                        }}
                                        icon={<Buy />} />
                                </div>
                            </FormItem>
                            <div className={styles.label}>buy</div>
                        </div>
                    </div>
                </div>
                <div className={styles.separator}></div>
                <div className={styles.separator}></div>

                <div className={styles.currency + ' ' + styles.bgPoundCash}>
                    <div className={styles.sellContainer}>
                        <div className={styles.icon}>
                            <CurrencyPound />
                        </div>
                        <div className={styles.data}>
                            <FormItem title={'Tomans'} titleColor='#253D5B' titleFontFamily='interRegular' titleFontSize='10px' errorMessage={''} width='90%'>
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Textbox width={'100%'} height={'34px'} borderColor='#EDEAE3' type='number'
                                        placeHolder={'tomans'} fontFamily='interRegular' fontSize='14px'
                                        text={getRate(Currency.GBPo, 'SELL')}
                                        onChange={(value) => {
                                            setRate(Currency.GBPo, 'SELL', value);
                                        }} icon={<Sell />} />
                                </div>
                            </FormItem>
                            <div className={styles.label}>sell</div>
                        </div>
                    </div>
                    <div className={styles.buyContainer}>
                        <div className={styles.icon}>
                            <CurrencyPound />
                        </div>
                        <div className={styles.data}>
                            <FormItem title={'Tomans'} titleColor='#253D5B' titleFontFamily='interRegular' titleFontSize='10px' errorMessage={''} width='90%'>
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Textbox width={'100%'} height={'34px'} borderColor='#EDEAE3' type='number'
                                        placeHolder={'tomans'} fontFamily='interRegular' fontSize='14px'
                                        text={getRate(Currency.GBPo, 'BUY')}
                                        onChange={(value) => {
                                            setRate(Currency.GBPo, 'BUY', value);
                                        }}
                                        icon={<Buy />} />
                                </div>
                            </FormItem>
                            <div className={styles.label}>buy</div>
                        </div>
                    </div>
                </div>
                <div className={styles.separator}></div>
                <div className={styles.separator}></div>

                <div className={styles.currency + ' ' + styles.bgEuro}>
                    <div className={styles.sellContainer}>
                        <div className={styles.icon}>
                            <CurrencyEuro />
                        </div>
                        <div className={styles.data}>
                            <FormItem title={'Tomans'} titleColor='#253D5B' titleFontFamily='interRegular' titleFontSize='10px' errorMessage={''} width='90%'>
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Textbox width={'100%'} height={'34px'} borderColor='#EDEAE3' type='number'
                                        placeHolder={'tomans'} fontFamily='interRegular' fontSize='14px'
                                        text={getRate(Currency.EUR, 'SELL')}
                                        onChange={(value) => {
                                            setRate(Currency.EUR, 'SELL', value);
                                        }}
                                        icon={<Sell />} />
                                </div>
                            </FormItem>
                            <div className={styles.label}>sell</div>
                        </div>
                    </div>
                    <div className={styles.buyContainer}>
                        <div className={styles.icon}>
                            <CurrencyEuro />
                        </div>
                        <div className={styles.data}>
                            <FormItem title={'Tomans'} titleColor='#253D5B' titleFontFamily='interRegular' titleFontSize='10px' errorMessage={''} width='90%'>
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Textbox width={'100%'} height={'34px'} borderColor='#EDEAE3' type='number'
                                        placeHolder={'tomans'} fontFamily='interRegular' fontSize='14px'
                                        text={getRate(Currency.EUR, 'BUY')}
                                        onChange={(value) => {
                                            setRate(Currency.EUR, 'BUY', value);
                                        }}
                                        icon={<Buy />} />
                                </div>
                            </FormItem>
                            <div className={styles.label}>buy</div>
                        </div>
                    </div>
                </div>
                <div className={styles.separator}></div>
                <div className={styles.separator}></div>

                <div className={styles.currency + ' ' + styles.bgDollar}>
                    <div className={styles.sellContainer}>
                        <div className={styles.icon}>
                            <CurrencyDollar />
                        </div>
                        <div className={styles.data}>
                            <FormItem title={'Tomans'} titleColor='#253D5B' titleFontFamily='interRegular' titleFontSize='10px' errorMessage={''} width='90%'>
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Textbox width={'100%'} height={'34px'} borderColor='#EDEAE3' type='number'
                                        placeHolder={'tomans'} fontFamily='interRegular' fontSize='14px'
                                        text={getRate(Currency.USD, 'SELL')}
                                        onChange={(value) => {
                                            setRate(Currency.USD, 'SELL', value);
                                        }}
                                        icon={<Sell />} />
                                </div>
                            </FormItem>
                            <div className={styles.label}>sell</div>
                        </div>
                    </div>
                    <div className={styles.buyContainer}>
                        <div className={styles.icon}>
                            <CurrencyDollar />
                        </div>
                        <div className={styles.data}>
                            <FormItem title={'Tomans'} titleColor='#253D5B' titleFontFamily='interRegular' titleFontSize='10px' errorMessage={''} width='90%'>
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Textbox width={'100%'} height={'34px'} borderColor='#EDEAE3' type='number'
                                        placeHolder={'tomans'} fontFamily='interRegular' fontSize='14px'
                                        text={getRate(Currency.USD, 'BUY')}
                                        onChange={(value) => {
                                            setRate(Currency.USD, 'BUY', value);
                                        }}
                                        icon={<Buy />} />
                                </div>
                            </FormItem>
                            <div className={styles.label}>buy</div>
                        </div>
                    </div>
                </div>
                <div className={styles.separator}></div>
                <div className={styles.separator}></div>

                <div className={styles.currency + ' ' + styles.bgCoinFull}>
                    <div className={styles.sellContainer}>
                        <div className={styles.data}>
                            <FormItem title={'Tomans'} titleColor='#FFFFFF' titleFontFamily='interRegular' titleFontSize='10px' errorMessage={''} width='90%'>
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Textbox width={'100%'} height={'34px'} borderColor='#EDEAE3' type='number'
                                        placeHolder={'tomans'} fontFamily='interRegular' fontSize='14px'
                                        text={getRate(Currency.IRCoinFull, 'SELL')}
                                        onChange={(value) => {
                                            setRate(Currency.IRCoinFull, 'SELL', value);
                                        }}
                                        icon={<Sell />} />
                                </div>
                            </FormItem>
                            <div className={styles.label}>sell</div>
                        </div>
                    </div>
                    <div className={styles.buyContainer}>
                        <div className={styles.data}>
                            <FormItem title={'Tomans'} titleColor='#FFFFFF' titleFontFamily='interRegular' titleFontSize='10px' errorMessage={''} width='90%'>
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Textbox width={'100%'} height={'34px'} borderColor='#EDEAE3' type='number'
                                        placeHolder={'tomans'} fontFamily='interRegular' fontSize='14px'
                                        text={getRate(Currency.IRCoinFull, 'BUY')}
                                        onChange={(value) => {
                                            setRate(Currency.IRCoinFull, 'BUY', value);
                                        }}
                                        icon={<Buy />} />
                                </div>
                            </FormItem>
                            <div className={styles.label}>buy</div>
                        </div>
                    </div>
                </div>
                <div className={styles.separator}></div>
                <div className={styles.separator}></div>

                <div className={styles.currency + ' ' + styles.bgCoinHalf}>
                    <div className={styles.sellContainer}>
                        <div className={styles.data}>
                            <FormItem title={'Tomans'} titleColor='#FFFFFF' titleFontFamily='interRegular' titleFontSize='10px' errorMessage={''} width='90%'>
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Textbox width={'100%'} height={'34px'} borderColor='#EDEAE3' type='number'
                                        placeHolder={'tomans'} fontFamily='interRegular' fontSize='14px'
                                        text={getRate(Currency.IRCoinHalf, 'SELL')}
                                        onChange={(value) => {
                                            setRate(Currency.IRCoinHalf, 'SELL', value);
                                        }}
                                        icon={<Sell />} />
                                </div>
                            </FormItem>
                            <div className={styles.label}>sell</div>
                        </div>
                    </div>
                    <div className={styles.buyContainer}>
                        <div className={styles.data}>
                            <FormItem title={'Tomans'} titleColor='#FFFFFF' titleFontFamily='interRegular' titleFontSize='10px' errorMessage={''} width='90%'>
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Textbox width={'100%'} height={'34px'} borderColor='#EDEAE3' type='number'
                                        placeHolder={'tomans'} fontFamily='interRegular' fontSize='14px'
                                        text={getRate(Currency.IRCoinHalf, 'BUY')}
                                        onChange={(value) => {
                                            setRate(Currency.IRCoinHalf, 'BUY', value);
                                        }}
                                        icon={<Buy />} />
                                </div>
                            </FormItem>
                            <div className={styles.label}>buy</div>
                        </div>
                    </div>
                </div>
                <div className={styles.separator}></div>
                <div className={styles.separator}></div>

                <div className={styles.currency + ' ' + styles.bgCoinQuarter}>
                    <div className={styles.sellContainer}>
                        <div className={styles.data}>
                            <FormItem title={'Tomans'} titleColor='#FFFFFF' titleFontFamily='interRegular' titleFontSize='10px' errorMessage={''} width='90%'>
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Textbox width={'100%'} height={'34px'} borderColor='#EDEAE3' type='number'
                                        placeHolder={'tomans'} fontFamily='interRegular' fontSize='14px'
                                        text={getRate(Currency.IRCoinQuarter, 'SELL')}
                                        onChange={(value) => {
                                            setRate(Currency.IRCoinQuarter, 'SELL', value);
                                        }}
                                        icon={<Sell />} />
                                </div>
                            </FormItem>
                            <div className={styles.label}>sell</div>
                        </div>
                    </div>
                    <div className={styles.buyContainer}>
                        <div className={styles.data}>
                            <FormItem title={'Tomans'} titleColor='#FFFFFF' titleFontFamily='interRegular' titleFontSize='10px' errorMessage={''} width='90%'>
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Textbox width={'100%'} height={'34px'} borderColor='#EDEAE3' type='number'
                                        placeHolder={'tomans'} fontFamily='interRegular' fontSize='14px'
                                        text={getRate(Currency.IRCoinQuarter, 'BUY')}
                                        onChange={(value) => {
                                            setRate(Currency.IRCoinQuarter, 'BUY', value);
                                        }}
                                        icon={<Buy />} />
                                </div>
                            </FormItem>
                            <div className={styles.label}>buy</div>
                        </div>
                    </div>
                </div>
                <div className={styles.separator}></div>
                <div className={styles.separator}></div>

                <div className={styles.currency + ' ' + styles.bgCoinGrams}>
                    <div className={styles.sellContainer}>
                        <div className={styles.data}>
                            <FormItem title={'Pound'} titleColor='#FFFFFF' titleFontFamily='interRegular' titleFontSize='10px' errorMessage={''} width='90%'>
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Textbox width={'100%'} height={'34px'} borderColor='#EDEAE3' type='number'
                                        placeHolder={'tomans'} fontFamily='interRegular' fontSize='14px'
                                        text={getRate(Currency.IRCoinGram, 'SELL')}
                                        onChange={(value) => {
                                            setRate(Currency.IRCoinGram, 'SELL', value);
                                        }}
                                        icon={<Sell />} />
                                </div>
                            </FormItem>
                            <div className={styles.label}>sell</div>
                        </div>
                    </div>
                    <div className={styles.buyContainer}>
                        <div className={styles.data}>
                            <FormItem title={'Pound'} titleColor='#FFFFFF' titleFontFamily='interRegular' titleFontSize='10px' errorMessage={''} width='90%'>
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Textbox width={'100%'} height={'34px'} borderColor='#EDEAE3' type='number'
                                        placeHolder={'tomans'} fontFamily='interRegular' fontSize='14px'
                                        text={getRate(Currency.IRCoinGram, 'BUY')}
                                        onChange={(value) => {
                                            setRate(Currency.IRCoinGram, 'BUY', value);
                                        }}
                                        icon={<Buy />} />
                                </div>
                            </FormItem>
                            <div className={styles.label}>buy</div>
                        </div>
                    </div>
                </div>
                <div className={styles.separator}></div>
                <div className={styles.separator}></div>
                <div className={styles.button}>
                    <Button text='update'
                        textColor='#EDEAE3' fontFamily='interLight' fontSize='16px'
                        border='1px solid #ADB8BA' borderRadius='0px' color='#153147'
                        width='128px' height='38px'
                        onClick={async () => {
                            const result = await updateExchanges(currentExchanges);
                            //const result = await JSON.parse(updateResult);
                            if (result!.updatedExchanges > 0) {
                                showModal('', 'exchange rates updated successfully!', null,
                                    '', () => { }, () => { });
                            }
                            else
                                showModal('', result!.error, null,
                                    '', () => { }, () => { });
                        }}
                    />
                </div>
                <div className={styles.separator}></div>
                <div className={styles.separatorDouble}></div>
            </div>
        </div>
    );
}

export default Exchanges;
import React, { Children, FunctionComponent, ReactElement, useEffect, useState } from "react";
import styles from "./formItem.module.scss";

interface FormItemProps {
    title: string;
    titleColor?: string;
    titleFontFamily?: string;
    titleFontSize?: string;
    titleDescription? : string;
    width?: string;
    controlPos?: 'left' | 'right' | 'line' | undefined;
    errorMessage?: string;
    children?: React.ReactNode;
    onDescriptionClick?: () => void;
    border?:string;
    borderRadius?:number;
}

const FormItem: FunctionComponent<FormItemProps> = (props) => {

    const [controlPos, setControlPos] = useState<string>(props.controlPos!);

    useEffect(() => {
        switch (props.controlPos!) {
            case 'right':
                setControlPos('flex-end');
                break;
            case 'left':
                setControlPos('flex-start');
                break;
            case 'line':
                setControlPos('flex-end');
                break;
        }
    })

    
    return (
        <div className={styles.container}
            style={{
                border: props.border!,
                borderRadius: props.borderRadius!,
                width: props.width!,
                flexDirection: (props.controlPos ? (props.controlPos! === 'line' ? 'row' : 'column') : 'column'),
                alignItems: (props.controlPos ? (props.controlPos! === 'line' ? 'center' : 'center') : 'center'),
                justifyContent: (props.controlPos ? (props.controlPos! === 'line' ? 'space-between' : 'center') : 'center'),
            }}>
            <div className={styles.title}
             style={{ 
                width: (props.controlPos ? (props.controlPos! === 'line' ? '50%' : '100%') : '100%'),
                color: props.titleColor!,
                fontFamily: props.titleFontFamily!,
                fontSize: props.titleFontSize!
            }}            
            >{props.title}<span onClick={() => {
                if(props.onDescriptionClick)
                    props.onDescriptionClick();
                }} className={styles.titleDescription}>&nbsp;{props.titleDescription!}{props.titleDescription ? 'ⓘ' : ''}</span></div>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                width: (props.controlPos ? (props.controlPos! === 'line' ? '50%' : '100%') : '100%'),
                alignItems: 'center',
                justifyContent: controlPos!
            }}>
                {props.children}
            </div>            
            <div>
                <div className={styles.errorMessage}>{props.errorMessage!}</div>            
            </div>
        </div>
    );
}

export default FormItem;
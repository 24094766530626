import React, { forwardRef, FunctionComponent, ReactElement, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';

import styles from "./users.module.scss";
import { Approve, Delete, Down, EnquiryItem, SortASC, SortDESC, Up } from '../../../components/icons/icons';
import Button from '../../../components/Button/Button';
import Textbox from '../../../components/Textbox/Textbox';
import ListItem from '../../../components/ListItem/ListItem';
import List from '../../../components/List/List';
import ToggleButton from '../../../components/ToggleButton/ToggleButton';
import { approveUser, deleteUser, enableUser, listUsers } from '../../../restAPI/userGate';
import ListItemEmpty from '../../../components/ListItemEmpty/ListItemEmpty';
import { PopupsContext } from '../../../handlers/PopupsContext';
import UserInfo from './userInfo/userInfo';
import { GlobalContext } from '../../../handlers/GlobalContext';


interface UsersProperties {
    userType: 'MEMBER' | 'STAFF';
    onClose?: () => void;
}

export interface UserCommunicators {
    onRefresh: () => void;
}

const Users = forwardRef<UserCommunicators, UsersProperties>((props, ref) => {

    const { user } = useContext(GlobalContext);
    const [showInfo, setShowInfo] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<string>();
    const [lookupUser, setLookupUser] = useState<string>('*');
    const [isAscending, setIsAscending] = useState<boolean>(false);
    const [membersList, setMemebersList] = useState<any[]>([]);

    const { showBusy, hideBusy } = useContext(PopupsContext);
    const [showSortOptions, setShowSortOptions] = useState<boolean>(false);
    const [sortField, setSortField] = useState<string>();

    // Expose methods using useImperativeHandle
    useImperativeHandle(ref, () => ({
        onRefresh() {
            loadUsers();
        },
    }));

    useEffect(() => {
        loadUsers();
    }, [isAscending, sortField])

    useEffect(() => {
        if (lookupUser === '*')
            loadUsers();
    }, [lookupUser])

    const loadUsers = async () => {
        showBusy('busy', '');
        setMemebersList([]);
        const result = await listUsers(props.userType, lookupUser, isAscending, sortField);
        console.log(result);
        if (result !== undefined) {
            setMemebersList(await JSON.parse(result).usersList);
        }
        hideBusy();
    }

    return (
        <div className={styles.container}>
            {showInfo && selectedUser! &&
                <UserInfo userType={props.userType} userId={selectedUser}
                    onClose={() => {
                        setShowInfo(false);
                        loadUsers();
                    }} />
            }
            <div className={styles.separator}></div>
            <div className={styles.userTopControls}>
                <div className={styles.searchBox}>
                    <Textbox borderColor='#CA7C4E'
                        fontFamily='interRegular'
                        fontSize='14px'
                        placeHolder='Search...'
                        width='80%' height='34px'
                        isSearchbox
                        text={lookupUser === '*' ? '' : lookupUser}
                        onClearSearch={ () => {
                            setLookupUser('*');
                        }}
                        onChange={(value) => {
                            if (value.trim() !== '')
                                setLookupUser(value);
                            else {
                                setLookupUser('*');
                            }
                        }} />
                    <div className={styles.splitter}></div>
                    <Button color='#D9B59D'
                        borderColor='#CA7C4E'
                        border='1px solid #CA7C4E'
                        borderRadius='3px'
                        width='34px' height='34px' image={require('../../../assets/icons/search.png')} imageHeight='24px' imageWidth='24px'
                        onClick={() => {
                            loadUsers();
                        }} />
                </div>
                <div className={styles.sortButton} onClick={(e) => {
                    e.stopPropagation();
                    setShowSortOptions(!showSortOptions);
                }}>
                    {isAscending ? <SortASC width='32px' /> : <SortDESC width='32px' />}
                    {showSortOptions &&
                        <div className={styles.sortMenu}>

                            <ListItem width={250} margin={'0px'} imageSource={require('../../../assets/icons/sortMenuASC.svg').default} index={0}
                                data={JSON.stringify({ 'title': 'Name (a-z)' })}
                                onClick={() => {
                                    setIsAscending(true);
                                    setSortField('name');
                                }} />
                            <ListItem width={250} imageSource={require('../../../assets/icons/sortMenuDESC.svg').default} index={0}
                                data={JSON.stringify({ 'title': 'Name (z-a)' })}
                                onClick={() => {
                                    setIsAscending(false);
                                    setSortField('name');
                                }} />
                            <ListItem width={250} imageSource={require('../../../assets/icons/sortMenuASC.svg').default} index={0}
                                data={JSON.stringify({ 'title': 'Username (a-z)' })}
                                onClick={() => {
                                    setIsAscending(true);
                                    setSortField('username');
                                }} />
                            <ListItem width={250} imageSource={require('../../../assets/icons/sortMenuDESC.svg').default} index={0}
                                data={JSON.stringify({ 'title': 'Username (z-a)' })}
                                onClick={() => {
                                    setIsAscending(false);
                                    setSortField('username');
                                }} />
                            <ListItem width={250} imageSource={require('../../../assets/icons/sortMenuASC.svg').default} index={0}
                                data={JSON.stringify({ 'title': 'Date (newer first)' })}
                                onClick={() => {
                                    setIsAscending(false);
                                    setSortField(undefined);
                                }} />
                            <ListItem width={250} imageSource={require('../../../assets/icons/sortMenuDESC.svg').default} index={0}
                                data={JSON.stringify({ 'title': 'Date (older first)' })}
                                onClick={() => {
                                    setIsAscending(true);
                                    setSortField(undefined);
                                }} />
                            {/* <ListItem imageSource={require('../../../assets/icons/sortMenuASC.svg').default} index={0} data={JSON.stringify({ 'title': 'Tag (a-z)' })} />
                                <ListItem imageSource={require('../../../assets/icons/sortMenuDESC.svg').default} index={0} data={JSON.stringify({ 'title': 'Tag (z-a)' })} /> */}
                        </div>
                    }
                </div>
            </div>
            <div className={styles.separatorHalf}></div>
            <div className={styles.contentItems}>
                <List>
                    {membersList.length === 0 &&
                        <ListItemEmpty text='no members to show!' />
                    }
                    {membersList.length > 0 &&

                        membersList.map((item, index) => {
                            let date = ((new Date(item.createdAt)).toLocaleDateString('en-US', {
                                weekday: 'short',  // Full day name (e.g., Monday)
                                year: 'numeric',  // Full year (e.g., 2024)
                                month: 'short',    // Full month name (e.g., September)
                                day: 'numeric'    // Day of the month (e.g., 13)
                            }) + ' ' + item.createdAt.split('T')[1].substring(0, 5));
                            if (JSON.parse(item.isNew))
                                return (
                                    <ListItem index={index} key={item.pkUser}
                                        onClick={() => {
                                            setSelectedUser(item.pkUser);
                                            setShowInfo(true);
                                        }}
                                        expandable
                                        expandIcon={<Down width={'32px'} fill={'#ADB8BA'} />}
                                        shrinkIcon={<Up width={'32px'} fill='#ADB8BA' />}
                                        data={JSON.stringify({
                                            title: (item.name ?? '') + ' ' + (item.surname ?? ''),
                                            details: item.userPrimaryEmail ?? '',
                                            extra: date//item.createdAt.split('T')[0].substring(2).replaceAll('-', '/') + ' ' + item.createdAt.split('T')[1].substring(0, 5)
                                        })}>
                                        <div className={styles.listExpandControl} onClick={async () => {
                                            showBusy('busy', '');
                                            const result = await deleteUser(item.pkUser);
                                            if (result.pkUser === item.pkUser) {
                                                const temp = membersList.filter((element) => element.pkUser !== item.pkUser);
                                                setMemebersList(temp);
                                            }
                                            setSelectedUser(undefined);
                                            hideBusy();
                                        }}>
                                            <Delete height={'24px'} fill={'#A8623E'} />
                                            <div className={styles.text}>
                                                delete
                                            </div>
                                        </div>
                                        <div className={styles.splitter}></div>
                                        <div className={styles.splitter}></div>
                                        {(user.type === 'SUPERVISOR') &&
                                            <div className={styles.listExpandControl} onClick={async () => {
                                                showBusy('busy', '');
                                                await approveUser(item.pkUser);
                                                setSelectedUser(item.pkUser);
                                                loadUsers();
                                                hideBusy();
                                            }}>
                                                <Approve height={'24px'} fill={'#A8623E'} />
                                                <div className={styles.text}>
                                                    approve
                                                </div>
                                            </div>
                                        }
                                    </ListItem>
                                );
                            else
                                return (
                                    <ListItem index={index} key={item.pkUser}
                                        onClick={() => {
                                            setSelectedUser(item.pkUser);
                                            setShowInfo(true);
                                        }}
                                        data={JSON.stringify({
                                            title: (item.name ?? '') + ' ' + (item.surname ?? ''),
                                            details: item.userPrimaryEmail ?? '',
                                            extra: date//item.createdAt.split('T')[0].substring(2).replaceAll('-', '/') + ' ' + item.createdAt.split('T')[1].substring(0, 5)
                                        })}>
                                        <div className={styles.listExpandControl} >
                                            <ToggleButton buttonColor={'#153147'} height={18}
                                                fontFamily='interRegular'
                                                fontSize='10px'
                                                frameColor='#153147'
                                                switchedOnColor={styles.lightBackgrounds}
                                                switchedOffColor={styles.disabledControlContext}
                                                displayTexts='bottom'
                                                offText='disable' onText='enable'
                                                isOn={!item.isActive}
                                                onClick={async () => {
                                                    const result = await enableUser(item.pkUser, !item.isActive);
                                                    if (result.pkUser === item.pkUser)
                                                        item.isActive = !item.isActive;
                                                    console.log(item.isActive);
                                                    setSelectedUser(item.pkUser);
                                                }} />
                                        </div>
                                    </ListItem>
                                );
                        })
                    }
                </List>
            </div>

        </div>
    );
});

export default Users;
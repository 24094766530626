import React, { FunctionComponent, ReactElement, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';

import styles from "./main.module.scss";
import {
    AddNew,
    Exchange, LeftGold, Login, Logo, LogoDark, Item, RightGold,
    SocialsWhatsapp, UserDummy,
    Member,
    User,
    News,
    Auction,
    Category,
    Club,
    Tag,
    Enquiry,
    SocialsInstagram,
    SocialsTelegram,    
    SocialsEmail,
    SocialsPhone,    
} from '../../../components/icons/icons';
import { PopupsContext } from '../../../handlers/PopupsContext';

import backgroundLogo from '../../../assets/icons/logoBackground.png';
import AppLogin from '../../customer/appLogin/appLogin';
import Menu from '../menu/menu';
import AppHome from '../../customer/appHome/appHome';
import Users, { UserCommunicators } from '../users/users';
import Notices, { NoticeCommunicators } from '../notices/notices';
import Items, { ItemCommunicators } from '../items/items';
import Categories from '../categories/categories';
import Clubs from '../clubs/clubs';
import Tags, { TagCommunicators } from '../tags/tags';
import UserInfo from '../users/userInfo/userInfo';
import ItemInfo from '../items/itemInfo/itemInfo';
import NoticeInfo from '../notices/noticeInfo/noticeInfo';
import { GlobalContext } from '../../../handlers/GlobalContext';
import Exchanges from '../exchanges/exchanges';
import TagInfo from '../tags/tagInfo/tagInfo';
import Enquiries from '../enquiries/enquiries';

const menuColor = '#2C3D5B';
const menuSelected = '#CA7C4E';

interface MainProperties {
    routedPage?: string;
    onClose?: () => void;
}

const Main: FunctionComponent<MainProperties> = (props) => {

    const tagsRef = useRef<TagCommunicators>(null);
    const noticesRef = useRef<NoticeCommunicators>(null);
    const usersRef = useRef<UserCommunicators>(null);
    const itemsRef = useRef<ItemCommunicators>(null);

    const { user } = useContext(GlobalContext);
    const { showModal, showInfoPopup } = useContext(PopupsContext);
    const [selectedPage, setSelectedPage] = useState<string>(props.routedPage ?? 'ENQUIRIES');
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const [showLogin, setShowLogin] = useState<boolean>(false);
    const [showMenu, setShowMenu] = useState<boolean>(false);
    const [showRightArrow, setShowRightArrow] = useState<boolean>(true);
    const [showLeftArrow, setShowLeftArrow] = useState<boolean>(false);
    const [menuPos, setMenuPos] = useState<number>(0);
    const [showAddModal, setShowAddModal] = useState<boolean>(false);

    const [showInstallButton, setShowInstallButton] = useState(false);
    const [deferredPrompt, setDeferredPrompt] = useState<any>();

    useEffect(() => {
        (async () => {

        })()
    }, [])

    useEffect(() => {
        window.addEventListener('beforeinstallprompt', (e) => {
            console.log(`beforeins: ${e}`);
            // Prevent the mini-infobar from appearing on mobile
            e.preventDefault();
            // Save the event so it can be triggered later
            setDeferredPrompt(e);
            // Show your "Install" button here
            setShowInstallButton(true);
        });

        return () => {
            window.removeEventListener('beforeinstallprompt', () => { });
        };
    }, []);

    useEffect(() => {
        (() => {
        })()
    }, [showRightArrow, showLeftArrow])

    window.addEventListener('resize', () => {
        setShowRightArrow(document.getElementById('rollingMenue')?.getBoundingClientRect().width! < document.getElementById('rollingMenuebar')?.getBoundingClientRect().width!);
    });

    useEffect(() => {
        (async () => {
            setIsLoggedIn(user !== undefined);
            setShowRightArrow(document.getElementById('rollingMenue')?.getBoundingClientRect().width! < document.getElementById('rollingMenuebar')?.getBoundingClientRect().width!);
        })()
    }, [menuPos, selectedPage, isLoggedIn])

    const switchPage = (switchTo: string) => {
        setSelectedPage(switchTo);
    }

    return (
        <div className={styles.container}>
            {showLogin &&
                <AppLogin onClose={() => { setShowLogin(false) }} />
            }
            {showMenu &&
                <Menu
                    showInstall={showInstallButton} deferredPrompt={deferredPrompt}
                    onClose={() => { 
                        setShowMenu(false); 
                    }}
                />
            }

            {showAddModal &&
                (selectedPage === 'MEMBERS') &&
                <UserInfo userType={'MEMBER'}
                    onClose={() => {
                        setShowAddModal(false);
                        if (usersRef.current) {
                            usersRef.current.onRefresh!();
                        }
                    }} />
            }
            {showAddModal &&
                selectedPage === 'STAFF' &&
                <UserInfo userType={'STAFF'}
                    onClose={() => {
                        setShowAddModal(false);
                        if (usersRef.current) {
                            usersRef.current.onRefresh!();
                        }
                    }} />
            }
            {showAddModal &&
                selectedPage === 'NEWSLETTER' &&
                <NoticeInfo noticeType='NEWSLETTER'
                    onClose={() => {
                        setShowAddModal(false);
                        if (noticesRef.current) {
                            noticesRef.current.onRefresh!();
                        }
                    }} />
            }
            {showAddModal &&
                selectedPage === 'ITEMS' &&
                <ItemInfo onClose={() => {
                    setShowAddModal(false);
                    if (itemsRef.current) {
                        itemsRef.current.onRefresh!();
                    }
                }} />
            }
            {showAddModal &&
                selectedPage === 'AUCTIONS' &&
                <NoticeInfo noticeType='AUCTION'
                    onClose={() => {
                        setShowAddModal(false);
                        if (noticesRef.current) {
                            noticesRef.current.onRefresh!();
                        }
                    }} />
            }
            {showAddModal &&
                selectedPage === 'TAGS' &&
                <TagInfo onClose={() => {
                    setShowAddModal(false);
                    if (tagsRef.current) {
                        tagsRef.current.onRefresh!();
                    }
                }} />
            }
            {showAddModal &&
                selectedPage === 'EXCHANGES' &&
                (isLoggedIn ? <Exchanges /> : <AppHome />)
            }
            {/* {showAddModal &&
            selectedPage === 'CLUBS' &&
                (isLoggedIn ? <Clubs /> : <AppHome />)
            } 
            {showAddModal &&
            selectedPage === 'CATEGORIES' &&
                (isLoggedIn ? <Categories /> : <AppHome />)
            }*/}

            <div className={styles.headerContainer}>
                <div className={!isLoggedIn ? styles.header : styles.headerLoggedIn}>

                    <div style={{ paddingLeft: '30px' }}>
                        {isLoggedIn &&
                            <>
                                <div className={styles.dummyContainer}>
                                    <UserDummy onClick={() => {
                                        setShowMenu(true);
                                    }} />
                                </div>
                                <div className={styles.loggedUserHeader}>
                                    <div className={styles.name}>{user.name + ' ' + user.surname}</div>
                                    {/* <div className={styles.notifications}>updates</div> */}
                                </div>
                            </>
                        }
                        {!isLoggedIn &&
                            <div className={styles.loginContainer}>
                                <Login width='32px' height='32px' onClick={() => {
                                    setShowLogin(true);
                                }} />
                            </div>
                        }
                    </div>

                    <Logo height={'68px'} style={{ paddingRight: '30px' }} />
                </div>
            </div>
            {isLoggedIn &&
                <div className={styles.adminHeader}>
                    {showLeftArrow &&
                        <div className={styles.left} onClick={() => {
                            document.getElementById('rollingMenue')?.scroll({
                                left:
                                    document.getElementById('rollingMenue')?.getBoundingClientRect().width! - document.getElementById('rollingMenuebar')?.getBoundingClientRect().width!
                                , behavior: 'smooth'
                            });
                        }}>
                            <LeftGold />
                        </div>
                    }
                    <div id='rollingMenue' className={styles.rollerMenuContainer} style={{ scrollbarWidth: 'none' }}
                        onScroll={(event) => {
                            setMenuPos(event.currentTarget.getBoundingClientRect().left);
                            if (document.getElementById('rollingMenuebar')?.getBoundingClientRect().left! <= (document.getElementById('rollingMenue')?.getBoundingClientRect().width! - document.getElementById('rollingMenuebar')?.getBoundingClientRect().width! + 1))
                                setShowRightArrow(false);
                            else
                                setShowRightArrow(true);

                            if (document.getElementById('rollingMenuebar')?.getBoundingClientRect().left! < 0)
                                setShowLeftArrow(true);
                            else
                                setShowLeftArrow(false);
                        }}>
                        <div id='rollingMenuebar' className={styles.rollingMenubar}>

                            <div className={styles.item} onClick={() => {
                                switchPage('ENQUIRIES');
                            }}>
                                <div className={styles.icon}>
                                    <Enquiry fill={selectedPage === 'ENQUIRIES' ? menuSelected : menuColor} />
                                </div>
                                <div className={styles.title} style={{ color: `${selectedPage === 'ENQUIRIES' ? menuSelected : menuColor}` }}>
                                    Enquiries
                                </div>
                            </div>

                            {(user.type === 'SUPERVISOR') &&
                                <div className={styles.item} onClick={() => {
                                    switchPage('MEMBERS');
                                }}>
                                    <div className={styles.icon}>
                                        <Member fill={selectedPage === 'MEMBERS' ? menuSelected : menuColor} />
                                    </div>
                                    <div className={styles.title} style={{ color: `${selectedPage === 'MEMBERS' ? menuSelected : menuColor}` }}>
                                        Members
                                    </div>
                                </div>
                            }

                            {(user.type === 'SUPERVISOR') &&
                                <div className={styles.item} onClick={() => {
                                    switchPage('STAFF');
                                }}>
                                    <div className={styles.icon}>
                                        <User fill={selectedPage === 'STAFF' ? menuSelected : menuColor} />
                                    </div>
                                    <div className={styles.title} style={{ color: `${selectedPage === 'STAFF' ? menuSelected : menuColor}` }}>
                                        Staff
                                    </div>
                                </div>
                            }

                            <div className={styles.item} onClick={() => {
                                switchPage('NEWSLETTER');
                            }}>
                                <div className={styles.icon}>
                                    <News fill={selectedPage === 'NEWSLETTER' ? menuSelected : menuColor} />
                                </div>
                                <div className={styles.title} style={{ color: `${selectedPage === 'NEWSLETTER' ? menuSelected : menuColor}` }}>
                                    News
                                </div>
                            </div>

                            <div className={styles.item} onClick={() => {
                                switchPage('ITEMS');
                            }}>
                                <div className={styles.icon}>
                                    <Item fill={selectedPage === 'ITEMS' ? menuSelected : menuColor} />
                                </div>
                                <div className={styles.title} style={{ color: `${selectedPage === 'ITEMS' ? menuSelected : menuColor}` }}>
                                    Items
                                </div>
                            </div>

                            <div className={styles.item} onClick={() => {
                                switchPage('AUCTIONS');
                            }}>
                                <div className={styles.icon}>
                                    <Auction fill={selectedPage === 'AUCTIONS' ? menuSelected : menuColor} />
                                </div>
                                <div className={styles.title} style={{ color: `${selectedPage === 'AUCTIONS' ? menuSelected : menuColor}` }}>
                                    Auctions
                                </div>
                            </div>

                            {(user.type === 'SUPERVISOR') &&
                                <div className={styles.item} onClick={() => {
                                    switchPage('TAGS');
                                }}>
                                    <div className={styles.icon}>
                                        <Tag fill={selectedPage === 'TAGS' ? menuSelected : menuColor} />
                                    </div>
                                    <div className={styles.title} style={{ color: `${selectedPage === 'TAGS' ? menuSelected : menuColor}` }}>
                                        Tags
                                    </div>
                                </div>
                            }
                            <div className={styles.item} onClick={() => {
                                switchPage('CLUBS');
                            }}>
                                <div className={styles.icon}>
                                    <Club fill={selectedPage === 'CLUBS' ? menuSelected : menuColor} />
                                </div>
                                <div className={styles.title} style={{ color: `${selectedPage === 'CLUBS' ? menuSelected : menuColor}` }}>
                                    Clubs
                                </div>
                            </div>

                            <div className={styles.item} onClick={() => {
                                switchPage('CATEGORIES');
                            }}>
                                <div className={styles.icon}>
                                    <Category fill={selectedPage === 'CATEGORIES' ? menuSelected : menuColor} />
                                </div>
                                <div className={styles.title} style={{ color: `${selectedPage === 'CATEGORIES' ? menuSelected : menuColor}` }}>
                                    Categories
                                </div>
                            </div>

                            {(user.type === 'SUPERVISOR') &&
                                <div className={styles.item} onClick={() => {
                                    switchPage('EXCHANGES');
                                }}>
                                    <div className={styles.icon}>
                                        <Exchange fill={selectedPage === 'EXCHANGES' ? menuSelected : menuColor} />
                                    </div>
                                    <div className={styles.title} style={{ color: `${selectedPage === 'EXCHANGES' ? menuSelected : menuColor}` }}>
                                        Exchange
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    {showRightArrow &&
                        <div className={styles.right} onClick={() => {
                            document.getElementById('rollingMenue')?.scroll({
                                left:
                                    document.getElementById('rollingMenuebar')?.getBoundingClientRect().width! - document.getElementById('rollingMenue')?.getBoundingClientRect().width!
                                , behavior: 'smooth'
                            });
                        }}>
                            <RightGold />
                        </div>
                    }
                </div>
            }

            <div style={{
                display: 'flex', alignItems: 'center',
                justifyContent: 'center', width: '100%', height: '100%',
                overflowY: 'scroll',
                background: `center no-repeat url(${backgroundLogo})`
            }}>

                {selectedPage === 'ENQUIRIES' &&
                    (isLoggedIn ? <Enquiries /> : <AppHome />)
                }
                {selectedPage === 'MEMBERS' &&
                    (isLoggedIn ? <Users ref={usersRef} userType='MEMBER' /> : <AppHome />)
                }
                {selectedPage === 'STAFF' &&
                    (isLoggedIn ? <Users ref={usersRef} userType='STAFF' /> : <AppHome />)
                }
                {selectedPage === 'NEWSLETTER' &&
                    (isLoggedIn ? <Notices ref={noticesRef} noticeType='NEWSLETTER' /> : <AppHome />)
                }
                {selectedPage === 'ITEMS' &&
                    (isLoggedIn ? <Items ref={itemsRef} /> : <AppHome />)
                }
                {selectedPage === 'AUCTIONS' &&
                    (isLoggedIn ? <Notices ref={noticesRef} noticeType='AUCTION' /> : <AppHome />)
                }
                {selectedPage === 'TAGS' &&
                    (isLoggedIn ? <Tags ref={tagsRef} /> : <AppHome />)
                }
                {selectedPage === 'CATEGORIES' &&
                    (isLoggedIn ? <Categories /> : <AppHome />)
                }
                {selectedPage === 'CLUBS' &&
                    (isLoggedIn ? <Clubs /> : <AppHome />)
                }
                {selectedPage === 'EXCHANGES' &&
                    (isLoggedIn ? <Exchanges /> : <AppHome />)
                }

            </div>

            <div className={styles.footerStandard}>
                <div className={styles.decoration} style={{ boxShadow: '1px -1px 1em #00000088' }}>
                    <div className={styles.boxDecoration}>
                        <div className={styles.navyBox}>
                        </div>
                        <div className={styles.goldBox}>
                        </div>
                    </div>
                    <div className={styles.logoDecoration}>
                        <LogoDark width={'68px'} />
                    </div>
                </div>
                <div className={styles.adminData}>
                    <div className={styles.floatingButtons} style={{ boxShadow: '5px -1px 1em #00000088' }}>
                        {
                            ((selectedPage !== 'ENQUIRIES') &&
                                (selectedPage !== 'CLUBS') &&
                                (selectedPage !== 'CATEGORIES') &&
                                (selectedPage !== 'EXCHANGES')) &&
                            <div className={styles.newItem} style={{ boxShadow: '2px -2px 3px #00000088' }}
                                onClick={() => {
                                    setShowAddModal(true);
                                }}>
                                <AddNew width={'48px'} fill={styles.darkColorMain} />
                            </div>
                        }
                    </div>
                    <div className={styles.contactsPanel}>
                        <div className={styles.rights}>
                        </div>
                        <div className={styles.connections}>
                            <div className={styles.contacts}>
                                <SocialsPhone width='32px' onClick={() => {
                                    window.open('tel:+447379337707', '_self');
                                }} />
                                <SocialsEmail width='32px' onClick={() => {
                                    window.open('mailto:info@crowngoldgallery.co.uk?cc=&subject=sending from webApp&body=', '_self');
                                }} />
                            </div>
                            <div className={styles.socials}>
                                <SocialsInstagram width='32px' onClick={() => {
                                    const newWin = window.open('https://instagram.com/crown.goldgallery', '_blank');
                                    newWin?.focus();
                                }} />
                                <SocialsWhatsapp width='32px' onClick={() => {
                                    const newWin = window.open('https://wa.me/+447379337707?text=' + encodeURIComponent('Hello, I\'d like to enquire ...'), '_blank');
                                    newWin?.focus();
                                }} />
                                <SocialsTelegram width='32px' onClick={() => {
                                    const newWin = window.open('https://t.me/crowngoldgallery', '_blank');
                                    newWin?.focus();
                                }} />
                            </div>
                        </div>
                        <div className={styles.rights}>
                            <span style={{ fontSize: '1em' }} onClick={() => {
                                window.open('mailto:info@crowngoldgallery.co.uk?cc=&subject=sending from webApp&body=', '_self');
                            }}>
                                info@crowngoldgallery.co.uk
                            </span>
                            &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;CGG has All Rights ©
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Main;
import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import SplashScreen from './pages/splashScreen/splashScreen';
import GlobalWrapper from './handlers/GlobalWrapper';
import PopupsWrapper from './handlers/PopupsWrapper';
import AppRoutes from './handlers/appRoutes';

function App() {

  const splashed = sessionStorage.getItem('splashed');
  const [showSplashscreen, setShowSplashscreen] = useState(splashed === null ? true : false);

  useEffect(() => {
    setTimeout(() => {
      setShowSplashscreen(false);
    }, 3000);

    if (window.location.hostname !== 'localhost') {
      if (navigator.serviceWorker) {
        navigator.serviceWorker.addEventListener('controllerchange', () => {
          // Force a reload when a new service worker takes control
          window.location.reload();
        });
      }
    }

  }, []);

  return showSplashscreen ? (
    <SplashScreen />
  ) : (
    <GlobalWrapper>
      <PopupsWrapper>
        <AppRoutes />
      </PopupsWrapper>
    </GlobalWrapper>
  );
}

export default App;

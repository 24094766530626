import React, { FunctionComponent, ReactElement, useContext, useEffect, useRef, useState } from 'react';

import styles from "./enquiryDetails.module.scss";
import Modal from '../../../../components/Modal/Modal';
import { CloseModalDark, Copy, Enquiry } from '../../../../components/icons/icons';
import { PopupsContext } from '../../../../handlers/PopupsContext';
import { loadEnquiry } from '../../../../restAPI/enquiryGate';
import { Settings } from '../../../../helpers/Settings';
import { getUser } from '../../../../restAPI/userGate';
import AppItemDetails from '../../../customer/appItemDetails/appItemDetails';

interface EnquiryDetailsProps {
    enquiryId: string;
    onClose?: () => void;
}

const EnquiryDetails: FunctionComponent<EnquiryDetailsProps> = (props) => {

    const { showModal, showInfoPopup } = useContext(PopupsContext);


    const [zooming, setZooming] = useState<string>('');
    const [showItem, setShowItem] = useState<boolean>(false);
    const [enquiryMode, setEnquiryMode] = useState<boolean>(false);

    const [currentEnquiry, setCurrentEnquiry] = useState<any>();
    const [currentEnquirer, setCurrentEnquirer] = useState<any>();

    // = {        
    //     'date': '2024 03 12 - 13:10',
    //     'description': 'whole set: necklace, bracelet, ear rings crafted on high 975 white plated gold, whole diamonds are grade A international and this item is is what you gain as a 5 part items!',
    //     'item': {
    //         'title': 'Versache diamond Set', 
    //         'photo': 'photos/items/itemSample.jpg'
    //     }
    // };

    useEffect(() => {
        (async () => {
            const loadedEnquiry = await loadEnquiry(props.enquiryId!);
            if (loadedEnquiry) {
                const enquiry = await JSON.parse(loadedEnquiry).enquiry;
                setCurrentEnquiry(enquiry);
                const enquirerContacts = await getUser(enquiry.fkMember);
                if (enquirerContacts) {
                    const enquirer = await JSON.parse(enquirerContacts);
                    if (enquirer!) {
                        console.log('ENQUIRER: ' + enquirer!.phone);
                        setCurrentEnquirer({ phone: enquirer!.phone! ?? '', email: enquirer!.email! });
                    }
                }
            }
        })()
    }, [])

    useEffect(() => {
        if (currentEnquirer!) {
            if(currentEnquirer!.phone!)
                document.getElementById('divContactPhone')!.innerHTML = `<a href='tel:${currentEnquirer!.phone!}'>${currentEnquirer!.phone!}</a>`
            if(currentEnquirer!.email!)
                document.getElementById('divContactMail')!.innerHTML = `<a style='text-decoration: none; color: #2C3D5B' href='mailto:${currentEnquirer!.email!}'>${currentEnquirer!.email!}</a>`;
        }
    }, [currentEnquirer])

    return (
        <Modal zIndex='1000' glassEffect='CLEARGLASS'>
            {showItem &&
                <AppItemDetails itemId={currentEnquiry.fkItem} onClose={() => { setShowItem(false) }} />
            }
            <div className={styles.modalPageDarkFrame} style={{ justifyContent: 'flex-start' }}>

                <div className={styles.headerContainer}>
                    <div className={styles.close} onClick={() => { props.onClose!() }}>
                        <CloseModalDark width={'32px'} />
                        <div className={styles.splitter}></div>
                        <div className={styles.splitter}></div>
                    </div>
                    <div className={styles.header}>
                        <div className={styles.splitter}></div>
                        <div className={styles.splitter}></div>
                        <Enquiry width={'64px'} fill={'#2C3D5B'} /> enquiry details
                    </div>
                </div>
                {currentEnquiry &&
                    <div className={styles.body}>
                        <div className={styles.date}>
                            {(currentEnquiry!.createdAt!.split('T')[0].substring(2).replaceAll('-', '/') + ' ' + currentEnquiry!.createdAt!.split('T')[1].substring(0, 5))}
                        </div>
                        <div className={styles.separatorDouble}></div>
                        <div className={styles.message}>{currentEnquiry.message!}</div>
                        <div className={styles.signature}>{currentEnquiry.enquirerFullName}</div>
                        {currentEnquirer! &&
                            <div className={styles.enquirerContacts}>
                                {currentEnquirer!.phone! &&
                                    <div className={styles.contact}>
                                        tel:&nbsp;&nbsp;&nbsp;<div id='divContactPhone'></div>&nbsp;&nbsp;
                                        <Copy height={24} width={24} fill={'#253D5B'}
                                            onClick={() => {
                                                navigator.clipboard.writeText(currentEnquirer!.phone!);
                                            }} />
                                    </div>
                                }
                                {currentEnquirer!.email! &&
                                    <div className={styles.contact}>
                                        email:&nbsp;&nbsp;&nbsp;<div id='divContactMail'></div>&nbsp;&nbsp;
                                        <Copy height={24} width={24} fill={'#253D5B'} onClick={() => {
                                            navigator.clipboard.writeText(currentEnquirer!.email!);
                                        }} />
                                    </div>
                                }
                            </div>
                        }
                        <div className={styles.preview} onClick={() => {
                            setShowItem(true);
                        }}>
                            <div className={styles.photo}>
                                <img src={`${Settings.api.path}/photos/items/${currentEnquiry!.fkOwner}/${currentEnquiry!.fkItem!}_0.jpg`} width='128px' height='128px' /></div>
                            <div className={styles.name}>{currentEnquiry.title!}</div>
                        </div>
                    </div>
                }
            </div>
        </Modal>
    );
}

export default EnquiryDetails;
import { FunctionComponent } from "react";
import { rejects } from "assert";
import { Settings } from "../helpers/Settings";
//import { getUserPickupCount } from "./pickup";

export interface CategoryData {
    pkCategory?:string;
    title?:string;   
}

export const listCategories = async (): Promise<any | undefined> => {
    return new Promise((resolve, reject) => {
        fetch(Settings.api.path + "/category/list", {
            method: 'GET',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React',
            }), 
        }).then((response) => {           
            response.json().then((result) => {
                resolve(JSON.stringify(result));
            }).catch((error) => {
                resolve(error);
            });
        }).catch((error) => { 
            alert(error);
            resolve(undefined);
        });    
    });
}


export const listDeepCategories = async (): Promise<any | undefined> => {
    return new Promise((resolve, reject) => {
        fetch(Settings.api.path + "/category/listDeep", {
            method: 'GET',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React',
            }), 
        }).then((response) => {           
            response.json().then((result) => {
                resolve(JSON.stringify(result));
            }).catch((error) => {
                resolve(error);
            });
        }).catch((error) => { 
            alert(error);
            resolve(undefined);
        });    
    });
}
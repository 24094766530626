import React, { FunctionComponent, ReactElement, useContext, useEffect, useRef, useState } from 'react';

import styles from "./appMain.module.scss";
import { Home, Login, Logo, LogoDark, SocialsEmail, SocialsInstagram, SocialsPhone, SocialsTelegram, SocialsWhatsapp, UserDummy } from '../../../components/icons/icons';
import headerRugs from '../../../assets/photos/categoryLargeRugs.jpg';
import headerBooks from '../../../assets/photos/categoryLargeBooks.jpg';
import headerPaintings from '../../../assets/photos/categoryLargePaintings.jpg';
import headerItems from '../../../assets/photos/categoryLargeItems.jpg';
import headerGold from '../../../assets/photos/categoryLargeGold.jpg';
import { PopupsContext } from '../../../handlers/PopupsContext';

import AppMenu from '../appMenu/appMenu';
import AppHome from '../appHome/appHome';
import AppGalleries from '../appGalleries/appGalleries';
import AppItems from '../appItems/appItems';
import AppEnquiries from '../appEnquiries/appEnquiries';
import AppExchanges from '../appExchange/appExchange';
import AppProfile from '../appProfile/appProfile';
import backgroundLogo from '../../../assets/icons/logoBackground.png';
import AppLogin from '../appLogin/appLogin';
import { GlobalContext } from '../../../handlers/GlobalContext';
import Notices from '../../admin/notices/notices';
import AppVerification from '../appVerification/appVerification';
import { useParams } from 'react-router-dom';

interface AppMainProperties {
    routedPage?: string;
    onClose?: () => void;
}

const AppMain: FunctionComponent<AppMainProperties> = (props) => {
    
    const headerTimer = useRef<NodeJS.Timer>();
    const { user, updateCategory } = useContext(GlobalContext);
    const { showModal, showInfoPopup } = useContext(PopupsContext);
    const { noticeType } = useParams();
    const [selectedPage, setSelectedPage] = useState<string>(props.routedPage?? 'HOME');
    const [selectedGallery, setSelectedGallery] = useState<string>('');
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const [showLogin, setShowLogin] = useState<boolean>(props.routedPage! === 'LOGIN');
    const [showMenu, setShowMenu] = useState<boolean>(false);
    const headerPhotos = [headerRugs, headerBooks, headerPaintings, headerItems, headerGold];
    const headerDescriptions = [
        'Persian whole natural silk carpet handmade',
        'Adam Smith\’s Wealth of Nations first edition 1776',
        'The death of Caesar - Jean Leon Gerome 1867',
        'French antique Gold Plated Clock',
        'versache necklace & bracelet set, whole grade A diamonds set'
    ]
    const [displayingPos, setDisplayingPos] = useState<number>(0);
    const [showInstallButton, setShowInstallButton] = useState(false);
    const [deferredPrompt, setDeferredPrompt] = useState<any>();
    
    useEffect(() => {
        window.addEventListener('beforeinstallprompt', (e) => {
            console.log(`beforeins: ${e}`);
            // Prevent the mini-infobar from appearing on mobile
            e.preventDefault();
            // Save the event so it can be triggered later
            setDeferredPrompt(e);
            // Show your "Install" button here
            setShowInstallButton(true);
        });

        return () => {
            window.removeEventListener('beforeinstallprompt', () => { });
        };
    }, []);

    useEffect(() => {
        (() => {
            headerTimer.current = setInterval(() => {
                console.log('authentication completed ... ' + Date()); 
                setDisplayingPos(displayingPos >= 4 ? 0 : displayingPos + 1);
            }, 5000);
        })()
        return () => clearInterval(headerTimer.current);
    }, [])

    useEffect(() => {
        setIsLoggedIn(user);
        if(headerTimer.current)
            clearInterval(headerTimer.current);
        return () => clearInterval(headerTimer.current);
    }, [user])

    return (
        <div className={styles.container}>

            {showLogin &&
                <AppLogin onClose={async () => {                    
                    setShowLogin(false);
                    if(props.routedPage === 'LOGIN')
                        setSelectedPage('HOME');
                }} />
            }
            {showMenu &&
                <AppMenu showInstall={showInstallButton} deferredPrompt={deferredPrompt} onClose={async () => {
                    setShowMenu(false);                                   
                }} />
            }

            <div className={styles.headerContainer}>
                <div className={!isLoggedIn ? styles.header : styles.headerLoggedIn}>

                    <div style={{ paddingLeft: '30px' }}>
                        {isLoggedIn &&
                            <>
                                <div className={styles.dummyContainer}>
                                    <UserDummy onClick={() => {
                                        setShowMenu(true);
                                    }} />
                                </div>
                                <div className={styles.loggedUserHeader}>
                                    <div className={styles.name}>{user.name + ' ' +  user.surname}</div>
                                    {/* <div className={styles.notifications}>updates</div> */}
                                </div>
                            </>
                        }
                        {!isLoggedIn &&
                            <div className={styles.loginContainer}>
                                <Login width='48px' height='48px' fill={'#D9B59D'} onClick={() => {
                                    setShowLogin(true);
                                }} />
                            </div>
                        }
                    </div>

                    <Logo height={'68px'} style={{ paddingRight: '30px' }} onClick={() => {
                        document.location = '/';
                    }} />
                </div>
            </div>
            {(selectedPage !== 'HOME' || isLoggedIn) &&
                <div className={styles.headerContainer}>
                    <div className={styles.routesHeader}>
                        <div className={styles.map}>
                            <Home onClick={() => {
                                document.location = '/';
                            }} />
                        </div>
                        <div className={styles.headerSlogan}>
                            <div className={styles.sloganTiny}>
                                your online auction of rarities
                            </div>
                            <div className={styles.sloganBig}>
                                Crown Gold Gallery
                            </div>
                        </div>
                    </div>
                </div>
            }

            <div style={{display: 'flex', 
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start', width: '100%', height: '100%',
                overflowY: 'scroll', 
                background: `center no-repeat url(${backgroundLogo})`
                }}>
                {selectedPage === 'HOME' &&
                    <AppHome />
                    // (!isLoggedIn ? <AppHome /> : <AppGalleries onClick={(categoryId:string, categoryName:string) => {                        
                    //     updateCategory(categoryId);
                    //     setSelectedGallery(categoryName);
                    //     setSelectedPage('ITEMS');
                    // }} />)
                }
                {selectedPage === 'GALLERY' &&
                    <AppGalleries onClick={(categoryId:string, categoryName:string) => {                        
                        updateCategory(categoryId);
                        setSelectedGallery(categoryName);
                        setSelectedPage('ITEMS');
                    }} />
                }
                {selectedPage === 'ENQUIRIES' &&
                    <AppEnquiries />
                }
                {selectedPage === 'UPDATES' &&
                    <Notices noticeType={ noticeType! ? (noticeType === 'NEWSLETTER' ? 'NEWSLETTER' : 'AUCTION') : 'ALL' }  />
                }
                {selectedPage === 'ITEMS' &&
                    <AppItems itemsGallery={selectedGallery} />
                }
                {selectedPage === 'EXCHANGES' &&
                    <AppExchanges />
                }
                {selectedPage === 'PROFILE' &&
                    <AppProfile />
                }
                {selectedPage === 'VERIFICATION' &&
                    <AppVerification />
                }
            </div>

            <div className={styles.footerStandard}>
                <div className={styles.decoration}>
                    <div className={styles.boxDecoration}>
                        <div className={styles.navyBox}>
                        </div>
                        <div className={styles.goldBox}>
                        </div>
                    </div>
                    <div className={styles.logoDecoration}>
                        <LogoDark width={'68px'} />
                    </div>
                </div>
                <div className={styles.data}>
                    {!isLoggedIn &&
                        <div className={styles.newsLetter}>
                            {/* <Textbox borderColor='#CA7C4E'
                                fontFamily='interRegular'
                                fontSize='14px'
                                placeHolder='send me newsletter ...'
                                width='205px' height='34px' />
                            <Button color='#D9B59D'
                                width='34px' height='34px' 
                                borderColor='#CA7C4E'
                                border='1px solid #CA7C4E'
                                borderRadius='3px'
                                imageHeight='24px' imageWidth='24px'
                                image={require('../../../assets/icons/send.png')} 
                                onClick={() => {
                                    if (userData.password !== '' && isValidMail) {
                                        showBusy('busy', '');
                                        if (retypePassword === userData.password) {
                                            const result = await createUser(userData);
                                            if (result.pkUser !== undefined) {
                                                showModal('', 'Your membership request is being processed, you will receive your activation email message afterwards!', null, '',
                                                    () => {
                                                        document.location = '/login';
                                                    },
                                                    () => { });
                                            }
                                        }
                                        hideBusy();
                                    }
                                }}/> */}
                        </div>
                    }
                    {isLoggedIn &&
                        <div className={styles.footerMenuLink} 
                            onClick={() => { 
                                setShowMenu(true) 
                                }}>
                            MENU
                        </div>
                    }
                    <div className={styles.contactsPanel}>
                        <div className={styles.rights}>
                        </div>
                        <div className={styles.connections}>
                        <div className={styles.contacts}>
                                <SocialsPhone width='32px' onClick={() => {
                                    window.open('tel:+447379337707', '_self');
                                }} />
                                <SocialsEmail width='32px' onClick={() => {
                                    window.open('mailto:info@crowngoldgallery.co.uk?cc=&subject=sending from webApp&body=', '_self');
                                }} />
                            </div>
                            <div className={styles.socials}>
                                <SocialsInstagram width='32px' onClick={() => {
                                    const newWin = window.open('https://instagram.com/crown.goldgallery', '_blank');
                                    newWin?.focus();
                                }} />
                                <SocialsWhatsapp width='32px' onClick={() => {
                                    const newWin = window.open('https://wa.me/+447379337707?text=' + encodeURIComponent('Hello, I\'d like to enquire ...'), '_blank');
                                    newWin?.focus();
                                }} />
                                <SocialsTelegram width='32px' onClick={() => {
                                    const newWin = window.open('https://t.me/crowngoldgallery', '_blank');
                                    newWin?.focus();
                                }} />
                            </div>
                        </div>
                        <div className={styles.rights}>
                            <span style={{fontSize: '1em'}} onClick={() => {
                                window.open('mailto:info@crowngoldgallery.co.uk?cc=&subject=sending from webApp&body=', '_self');
                            }}>
                                info@crowngoldgallery.co.uk
                            </span>
                            &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;CGG has All Rights ©
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AppMain;
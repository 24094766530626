import React, { FunctionComponent, ReactElement, useContext, useEffect, useRef, useState } from 'react';

import styles from "./itemSelector.module.scss";
import Modal from '../../../../components/Modal/Modal';
import { CloseModal, Auction, News, SigninHeader, Staff, Attach } from '../../../../components/icons/icons';
import FormItem from '../../../../components/FormItem/FormItem';
import Textbox from '../../../../components/Textbox/Textbox';
import Button from '../../../../components/Button/Button';
import { PopupsContext } from '../../../../handlers/PopupsContext';
import ToggleButton from '../../../../components/ToggleButton/ToggleButton';
import Dropdown from '../../../../components/DropDown/Dropdown';

import { listClubs } from '../../../../restAPI/clubGate';
import { approveNotice, createNotice, getNotice, NoticeData } from '../../../../restAPI/noticeGate';
import Items from '../items';

interface ItemSelectorProperties {
    onClose?: (selectedValue:string|undefined) => void;
}

const ItemSelector: FunctionComponent<ItemSelectorProperties> = (props) => {

    const { showModal, showInfoPopup, showBusy, hideBusy } = useContext(PopupsContext);
        
    //const [clubsList, setClubsList] = useState<[]>([]);
    
    useEffect(() => {        
    }, [])

    useEffect(() => {
        (async () => {
        })()
    }, [])

    return (
        <Modal zIndex='1000' glassEffect='GLASS'>
            <div className={styles.modalPage} style={{justifyContent: 'flex-start'}}> 
                <div className={styles.headerContainer}>
                    <div className={styles.close} onClick={() => { props.onClose!(undefined) }}>
                        <CloseModal width={'32px'} />
                        <div className={styles.splitter}></div>
                        <div className={styles.splitter}></div>
                    </div>
                    <div className={styles.header}>
                        <div className={styles.header}>
                            <div className={styles.splitter}></div>
                            <div className={styles.splitter}></div>
                                <Attach width={'64px'} fill={'#2C3D5B'} />
                            <div className={styles.splitter}></div>
                            <div className={styles.text}>
                                <div className={styles.title}>select item</div>
                                <div className={styles.data}></div>
                            </div>
                        </div>
                        <div className={styles.expansion}>
                            <div className={styles.splitter}></div>
                            <div className={styles.splitter}></div>
                        </div>
                    </div>
                </div>
                <div className={styles.body}>
                    <Items isSelector={true} onClose={(value) => {
                        props.onClose!(value);
                    }} />
                    </div>
                <div className={styles.separator}></div>
                <div className={styles.separator}></div> 
            </div>
        </Modal>
    );
}

export default ItemSelector;
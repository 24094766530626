import React, { Children, FunctionComponent, ReactElement, useEffect, useState } from "react";
import styles from "./listItem.module.scss";
import { Close, Enquiry } from "../icons/icons";

interface ListItemProps {
    index: number;
    imageSource?: string;
    imageComponent?:React.ReactNode;
    imageFrame?: 'CIRCLE' | 'SQUARE';
    imageSize?: number;
    width?: number;
    margin?: string;
    imageOpacity?: number;
    onClick?: () => void;
    type?: 'FULLLEFT' | 'FULLRIGHT' | 'QUICKPICKER' | 'PICKER';
    data?: string;
    expandable?: boolean|false;
    expandIcon?: React.ReactNode;
    shrinkIcon?: React.ReactNode;
    children?: React.ReactNode;
}

const ListItem: FunctionComponent<ListItemProps> = (props) => {

    const [item, setItem] = useState<any>(JSON.parse(props.data!));
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [displayMode, setDisplayMode] = useState<string>('FULLLEFT');

    useEffect(() => {
        return() => {  }
    }, [isExpanded])

    return (
        // <div  className={styles.grandContainer}>
            <div className={styles.container + ` ${props.index % 2 === 0 ? styles.evenBackground : styles.oddBackground}`}
                style={{
                    width: props.width?? 'none',
                    margin: props.margin?? 'none'
                }}>
            
                <div className={styles.item}
                    style={{
                    }}
                >
                {props.imageSource &&
                    <div className={styles.image}>
                        <img src={props.imageSource}
                            // onError={() => {
                            //     this.src='alternative.jpg';`
                            // }}
                            style={{
                                borderRadius: props.imageFrame === 'CIRCLE' ? '50%' : 'notset',
                                width: props.imageSize! ? props.imageSize : '48px',
                                height: props.imageSize! ? props.imageSize : '48px',
                                opacity: props.imageOpacity ?? '1'
                            }} />
                    </div>
                }
                    {
                        props.imageComponent
                    }
                    <div className={styles.data}
                        onClick={() => {
                            props.onClick!();
                        }}>
                        <div className={styles.title}>
                        <div className={styles.titleDisplay}>
                            {item.title}
                            </div>
                        </div>
                        <div className={styles.details}>{item.details}</div>
                        <div className={styles.extra}>{item.extra}</div>
                    </div>

                    {(props.expandable &&
                        (props.expandIcon! && !isExpanded)) &&
                        <div className={styles.button} onClick={() => {
                            setIsExpanded(true);
                        }}>
                            {props.expandIcon}
                        </div>
                    }
                    {(props.expandable &&
                        (isExpanded)) &&
                        <div className={styles.button} onClick={() => {
                            setIsExpanded(false);
                        }}>
                            {props.shrinkIcon}
                        </div>
                    }
                    {!props.expandable &&
                        <div className={styles.button} onClick={() => {
                            setIsExpanded(false);
                        }}>
                            {props.children}
                        </div>
                    }
                </div>

                {props.expandable &&
                    (props.children! && isExpanded) &&
                    <div className={styles.expansion}>
                        {props.children}
                    </div>
                }
            </div>
            // <div className={styles.separatorDouble}></div>
        // </div>
    );
}

export default ListItem;
import React, { Children, FunctionComponent, useEffect, useState } from "react";
import styles from "./modal.module.scss";
import { inherits } from "util";

interface ModalProps {
    fontFamily?: string;
    fontSize?: string;
    placeHolder?: string;
    textColor?: string;
    color?: string;
    borderColor?: string;
    disabledColor?: string;
    disabled?: boolean;
    width?: string;
    height?: string;
    onClick?: (value: any) => void
    onChange?: (value: any) => void;
    children?: React.ReactNode;
    opacity?: number;
    zIndex?: string;
    glassEffect?: 'GLASS' | 'CLEARGLASS' | undefined;
}

const Modal: FunctionComponent<ModalProps> = (props) => {

    const [containerStyle, setContainerStyle] = useState<string>(styles.modalContainer);

    useEffect(() => {
        switch (props.glassEffect) {
            case undefined:
                setContainerStyle(styles.modalContainer);
                break;
            case 'GLASS':
                setContainerStyle(styles.modalContainerGlass);
                break;
            case 'CLEARGLASS':
                setContainerStyle(styles.modalContainerClearGlass);
                break;
        }
    }, [])

    return (
        <div className={containerStyle}
            style={{
                fontFamily: props.fontFamily!, fontSize: props.fontSize!,
                width: props.width!,
                height: props.height!,
                backgroundColor: props.glassEffect ? 'unset' : (!props.disabled ? props.color! : props.disabledColor!),
                borderColor: !props.disabled ? props.borderColor! : props.disabledColor!,
                opacity: props.opacity,
                zIndex: props.zIndex ? props.zIndex! : '10000',
            }}
            onClick={() => {
                if (!props.disabled && props.onClick)
                    props.onClick(false);
            }}>
            {props.children}
        </div>
    );
}

export default Modal;
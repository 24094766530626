import React, { Children, FunctionComponent, ReactElement, useEffect, useState } from "react";
import styles from "./appItem.module.scss";
import { Close, Enquiry } from "../../icons/icons";
import { setConstantValue } from "typescript";

interface AppItemProps {
    index: number;
    image?: string;
    imageFrame?: 'CIRCLE' | 'SQUARE';
    imageSize?: number;
    listType?: 'FULL' | 'QUICKPICKER' | 'PICKER';
    itemWidth?: number;
    onClick?: () => void;
    data?: string;
    expandable?: boolean | false;
    expandIcon?: React.ReactNode;
    shrinkIcon?: React.ReactNode;
    children?: React.ReactNode;
    titleFont?:string;
    titleColor?:string;
    titleFontSize?:number;
}

const AppItem: FunctionComponent<AppItemProps> = (props) => {

    const [item, setItem] = useState<any>(JSON.parse(props.data!));
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    useEffect(() => {
        (async() => {
            setItem(await JSON.parse(props.data!));
        })();
        return () => { }
    }, [isExpanded, props.data])

    return (
        <div className={styles.contentItems}>
            {(props.listType === undefined || props.listType === 'FULL') &&
                <div className={styles.item}
                    style={{
                        transition:'500mx linear'
                    }}
                    onClick={() => {
                        props.onClick!();
                    }}>
                    <div className={styles.data}>
                        <div className={styles.title}
                            style={{
                                fontFamily: props.titleFont!,
                                color: props.titleColor!,
                                fontSize: props.titleFontSize!,
                                transition:'500mx linear'
                            }}>
                            {item.title}
                        </div>
                        <div className={styles.description}>
                            <div className={styles.descriptionContainer}>
                                {item.description}
                            </div>
                        </div>
                        <div className={styles.price}>
                            <span className={styles.currency}>£&nbsp;</span>{item.price! ? item.price.toLocaleString() : ''}
                        </div>
                    </div>
                    <div className={styles.splitter}></div>
                    <div className={styles.splitter}></div>
                    <div className={styles.splitter}></div>
                    <div className={styles.imageContainer}>
                        <img src={props.image!} style={{
                            borderRadius: props.imageFrame === 'CIRCLE' ? '50%' : 'notset',
                            width: props.imageSize! ? props.imageSize : '164px',
                            height: props.imageSize! ? props.imageSize : '164px'
                        }} />
                    </div>
                </div>
            }

            {props.listType === 'QUICKPICKER' &&
                <div className={styles.item}
                    style={{
                        flexDirection: 'column',
                        width: props.itemWidth! ? props.itemWidth : '164px',
                        height: 'unset'
                    }}
                    onClick={() => {
                        props.onClick!();
                    }}>
                    <div className={styles.photo}>
                        <img src={props.image!} style={{
                            borderRadius: props.imageFrame === 'CIRCLE' ? '50%' : 'notset',
                            width: props.imageSize! ? props.imageSize : '164px',
                            height: props.imageSize! ? props.imageSize : '164px'
                        }} />
                    </div>
                    <div className={styles.data}
                        style={{
                            padding: '0px'
                        }}>
                        <div className={styles.title} style={{
                                fontFamily: props.titleFont!,
                                color: props.titleColor!,
                                fontSize: props.titleFontSize!,
                                height: 'unset'
                            }}>
                            <div className={styles.titleContainer}>
                                {item.title}
                            </div>
                        </div>
                    </div>
                </div>
            }

            {props.listType === 'PICKER' &&
                <div className={styles.item}
                    style={{
                        flexDirection: 'column',
                        width: props.itemWidth! ? props.itemWidth : 164
                    }}
                    onClick={() => {
                        props.onClick!();
                    }}><div className={styles.photo}>
                        <img src={props.image!} style={{
                            borderRadius: props.imageFrame === 'CIRCLE' ? '50%' : 'notset',
                            width: props.imageSize! ? props.imageSize : '164px',
                            height: props.imageSize! ? props.imageSize : '164px'
                        }} />
                    </div>
                    <div className={styles.data}>
                        <div className={styles.title}style={{
                                fontFamily: props.titleFont!,
                                color: props.titleColor!,
                                fontSize: props.titleFontSize!
                            }}>
                            {item.title}
                        </div>
                        <div className={styles.description}>
                            {item.description}
                        </div>
                        <div className={styles.price}>
                            <span className={styles.currency}>£&nbsp;</span>{item.price! ? item.price.toLocaleString() : ''}
                        </div>
                    </div>

                </div>
            }


            {/* <div className={styles.item}>
                {props.image &&
                    <div className={styles.image}>
                        <img src={props.image!} style={{
                            borderRadius: props.imageFrame === 'CIRCLE' ? '50%' : 'notset',
                            width: props.imageSize!,
                            height: props.imageSize!,

                        }} width='48px' height='48px' />
                    </div>
                }

                <div className={styles.data}
                    onClick={() => {
                        props.onClick!();
                    }}>
                    <div className={styles.title}>{item.title}</div>
                    <div className={styles.details}>{item.details}</div>
                    <div className={styles.price}>{item.price}</div>
                </div>

                {(props.expandable &&
                    (props.expandIcon! && !isExpanded)) &&
                    <div className={styles.button} onClick={() => {
                        setIsExpanded(true);
                    }}>
                        {props.expandIcon}
                    </div>
                }
                {(props.expandable &&
                    (isExpanded)) &&
                    <div className={styles.button} onClick={() => {
                        setIsExpanded(false);
                    }}>
                        {props.shrinkIcon}
                    </div>
                }
                {!props.expandable &&
                    <div className={styles.button} onClick={() => {
                        setIsExpanded(false);
                    }}>
                        {props.children}
                    </div>
                }
            </div>

            {props.expandable &&
                (props.children! && isExpanded) &&
                <div className={styles.expansion}>
                    {props.children}
                </div>
            } */}
        </div>
    );
}

export default AppItem;
import { FunctionComponent } from "react";
import { rejects } from "assert";
import { Settings } from "../helpers/Settings";
//import { getUserPickupCount } from "./pickup";

export interface TagData {
    pkTag?:string;
    title?:string;
    used?:number;
    createdAt?:string;
}


export const createTag = async (tagData:TagData): Promise<any | undefined> => {
    return new Promise((resolve, reject) => {
        fetch(Settings.api.path + "/tag/new", {
            method: 'POST',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React',
            }), 
            body: JSON.stringify({
                tagData: tagData
            })
        }).then((response) => {            
            response.json().then((result) => {                
                resolve(result);
            }).catch((error) => {resolve(undefined)});
        }).catch((error) => {            
            resolve(undefined);
        });
    });
}

export const updateTag = async (tagData:TagData): Promise<any | undefined> => {
    
    return new Promise((resolve, reject) => {
        fetch(Settings.api.path + "/tag", {
            method: 'PUT',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React',
            }), 
            body: JSON.stringify({
                tagData: tagData
            })
        }).then((response) => {            
            response.json().then((result) => {                
                resolve(result);
            }).catch((error) => {resolve(undefined)});
        }).catch((error) => {            
            resolve(undefined);
        });
    });
}

export const listTags = async (lookup:String, isAscending:boolean): Promise<any | undefined> => {
    lookup = lookup.trim() === '' ? '*' : lookup.trim().toLowerCase();
    return new Promise((resolve, reject) => {
        fetch(Settings.api.path + `/tag/list/${isAscending ? 'title:ASC' : 'title:DESC'} `, {
            method: 'POST',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React',
            }), 
            body: JSON.stringify({
                lookupTag: lookup
            })
        }).then((response) => {            
            response.json().then((result) => {
                resolve(JSON.stringify(result));
            }).catch((error) => {resolve(error)});
        }).catch((error) => {
            resolve(undefined);
        });
    });
}

export const usageCount = async (tagId:String): Promise<any | undefined> => {    
    return new Promise((resolve, reject) => {
        fetch(Settings.api.path + `/tag/usageCount`, {
            method: 'POST',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React',
            }), 
            body: JSON.stringify({
                tagId 
            })
        }).then((response) => {            
            response.json().then((result) => {
                resolve(JSON.stringify(result));
            }).catch((error) => {resolve(error)});
        }).catch((error) => {
            resolve(undefined);
        });
    });
}
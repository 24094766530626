import { FunctionComponent } from "react";
import { rejects } from "assert";
import { Settings } from "../helpers/Settings";
//import { getUserPickupCount } from "./pickup";

export interface NoticeData {
    pkNotice?:string;
    fkOwner?:string;
    fkClub?:string;
    title?: string;
    description?:string;
    items?:string[];
    isConfirmed?: boolean;
    type?:'NEWSLETTER'|'AUCTION'|'ALL';
    createdAt?:string;    
}

export const createNotice = async (noticeData:NoticeData): Promise<any | undefined> => {
    console.log('Notice Data: ' + noticeData.items?.length);
    return new Promise((resolve, reject) => {
        fetch(Settings.api.path + "/notice/new", {
            method: 'POST',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React',
            }), 
            body: JSON.stringify({
                noticeData
            })
        }).then((response) => {            
            response.json().then((result) => {                
                resolve(result);
            }).catch((error) => {resolve(undefined)});
        }).catch((error) => {            
            resolve(undefined);
        });
    });
}

export const listNotices = async (user:any, lookupFor: string, type:'NEWSLETTER'|'AUCTION'|'ALL', isAscending: boolean): Promise<any | undefined> => {
    return new Promise((resolve, reject) => {
        fetch(Settings.api.path + `/notice/list/${isAscending ? 'createdAt:ASC' : 'createdAt:DESC'}`, {
            method: 'POST',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React',
            }), 
            body: JSON.stringify({
                userId: user ? user!.pkUser : undefined,
                userRole: user ? user!.type : undefined,
                userClubRate: user ? user!.clubRate : undefined,
                type,
                lookupFor
            })
        }).then((response) => {            
            response.json().then((result) => {
                resolve(JSON.stringify(result));
            }).catch((error) => {resolve(error)});
        }).catch((error) => {
            resolve(undefined);
        });
    });
}

export const getNotice = async (noticeId:string): Promise<any | undefined> => {
    return new Promise((resolve, reject) => {
        fetch(Settings.api.path + "/notice/details", {
            method: 'POST',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React',
            }), 
            body: JSON.stringify({
                pkNotice: noticeId
            })
        }).then((response) => {
            response.json().then((result) => {
                resolve(JSON.stringify(result));
            }).catch((error) => {resolve(error)});            
        }).catch((error) => {
            resolve(undefined);
        });
    });
}

export const updateNotice = async (noticeData:NoticeData): Promise<any | undefined> => {
    return new Promise((resolve, reject) => {
        fetch(Settings.api.path + "/notice/details", {
            method: 'PUT',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React',
            }), 
            body: JSON.stringify({
                noticeData
            })
        }).then((response) => {            
            response.json().then((result) => {                
                resolve(result);
            }).catch((error) => {resolve(undefined)});            
        }).catch((error) => {            
            reject(undefined);
        });
    });
}

export const approveNotice = async (noticeId:string): Promise<any | undefined> => {
    return new Promise((resolve, reject) => {
        fetch(Settings.api.path + "/notice/approved", {
            method: 'PATCH',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React',
            }), 
            body: JSON.stringify({
                pkNotice: noticeId
            })
        }).then((response) => {            
            response.json().then((result) => {                
                resolve(result);
            }).catch((error) => {resolve(undefined)});            
        }).catch((error) => {            
            reject(undefined);
        });
    });
}

export const deleteNotice = async (noticeId:string): Promise<any | undefined> => {
    return new Promise((resolve, reject) => {
        fetch(Settings.api.path + "/notice", {
            method: 'DELETE',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React',
            }), 
            body: JSON.stringify({
                pkNotice: noticeId
            })
        }).then((response) => {            
            response.json().then((result) => {                
                resolve(result);
            }).catch((error) => {resolve(undefined)});            
        }).catch((error) => {            
            reject(undefined);
        });
    });
}

import React, { FunctionComponent, ReactElement, useContext, useEffect, useRef, useState } from 'react';

import styles from "./tagInfo.module.scss";
import Modal from '../../../../components/Modal/Modal';
import { CloseModal, Auction, News, SigninHeader, Staff, Tag } from '../../../../components/icons/icons';
import FormItem from '../../../../components/FormItem/FormItem';
import Textbox from '../../../../components/Textbox/Textbox';
import Button from '../../../../components/Button/Button';
import { PopupsContext } from '../../../../handlers/PopupsContext';
import ToggleButton from '../../../../components/ToggleButton/ToggleButton';
import Dropdown from '../../../../components/DropDown/Dropdown';

import { listClubs } from '../../../../restAPI/clubGate';
import { approveNotice, createNotice, getNotice, NoticeData } from '../../../../restAPI/noticeGate';
import { createTag, listTags, TagData, updateTag } from '../../../../restAPI/tagGate';

interface TagInfoProperties {
    tagId?: string | undefined;
    tagTitle?: string;
    onClose?: () => void;
}

const TagInfo: FunctionComponent<TagInfoProperties> = (props) => {

    const { showModal, showInfoPopup, showBusy, hideBusy } = useContext(PopupsContext);
    const [currentTag, setCurrentTag] = useState<TagData>({ pkTag: props.tagId!, title: props.tagTitle! });

    const [similarTagsList, setSimilarTagsList] = useState<string>('');

    useEffect(() => {
        (async () => {
            showBusy('busy', '');
            if (props.tagId!) {
                    setCurrentTag({ pkTag: props.tagId!, title: props.tagTitle! });
                    listSimilarTags();
            }
            hideBusy();
        })()
    }, [])

    useEffect(() => {
        (async () => {
            listSimilarTags();
        })()
    }, [currentTag])
    
    const listSimilarTags = async () => {
        let resultList: string = '';
        console.log(currentTag.title);
        const result = await listTags(currentTag.title ?? '*', true);
        if (result) {
            const tagsList = await JSON.parse(result).tagsList;
            if (tagsList) {
                tagsList.map((item: TagData) => {
                    if(item.pkTag! !== props.tagId!)
                        resultList += '#' + item.title!.trim();
                })
            }
        }
        setSimilarTagsList(resultList);
    }

    return (
        <Modal zIndex='1000' glassEffect='CLEARGLASS'>
            <div className={styles.modalPage}>
                <div className={styles.headerContainer}>
                    <div className={styles.close} 
                        onClick={() => { 
                            props.onClose!();
                        }}>
                        <CloseModal width={'32px'} />
                        <div className={styles.splitter}></div>
                        <div className={styles.splitter}></div>
                    </div>
                    <div className={props.tagId! ? styles.headerEx : styles.header}>
                        <div className={styles.header}>
                            <div className={styles.splitter}></div>
                            <div className={styles.splitter}></div>
                            <Tag width={'64px'} fill={'#2C3D5B'} />
                            <div className={styles.splitter}></div>
                            <div className={styles.text}>
                                <div className={styles.title}>{ props.tagId! ? 'tag details' : 'new tag' }</div>
                                <div className={styles.data}>{currentTag.createdAt ? (currentTag.createdAt.split('T')[0].substring(2).replaceAll('-', '/') + ' ' + currentTag.createdAt.split('T')[1].substring(0, 5)) : ''}</div>
                            </div>
                        </div>
                        <div className={styles.expansion}>
                            <div className={styles.splitter}></div>
                            <div className={styles.splitter}></div>
                        </div>
                    </div>
                </div>
                <div className={styles.bodyLeft}>
                    <div className={styles.separatorHalf}></div>
                    <FormItem title={'tag name'} errorMessage={''} width='80%'>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Textbox width={'100%'} height={'32px'} placeHolder={'tag name'}
                                text={currentTag.title}
                                onChange={(value) => {
                                    setCurrentTag({ ...currentTag, title: value.toLowerCase() });
                                    
                                }} />
                        </div>
                    </FormItem>

                    <div className={styles.separatorHalf}></div>
                    <FormItem title={'similar tags'} errorMessage={''} width='100%'>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Textbox width={'100%'} height={'150px'} placeHolder={'similar tags'}
                                readOnly
                                type='multiline'
                                text={similarTagsList.replaceAll('#', '  #').trim()}
                                onChange={(value) => { }}
                            />
                        </div>
                    </FormItem>

                    <div className={styles.separator}></div>
                    <div className={styles.separator}></div>
                    <div className={styles.button}>
                        <Button text='save'
                            textColor='#EDEAE3' fontFamily='interLight' fontSize='16px'
                            border='1px solid #ADB8BA' borderRadius='0px' color='#153147'
                            width='128px' height='38px'
                            onClick={async () => {
                                if(similarTagsList.split('#').includes(currentTag.title!))
                                    showModal('duplicate tag detected', 'Tag already exists, please check similar tags list before submitting!', null, '', () => { }, () => { });
                                else if (props.tagId){
                                    const result = await updateTag(currentTag);
                                    if(result.pkTag === props.tagId)
                                        props.onClose!();
                                }
                                else {
                                    const result = await createTag({...currentTag, title: currentTag.title?.trim()});
                                    if(result.pkTag!)
                                        props.onClose!();
                                }
                            }}
                        />
                    </div>
                </div>
                <div className={styles.separator}></div>
                <div className={styles.separator}></div>
            </div>
        </Modal>
    );
}

export default TagInfo;
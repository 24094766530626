import React, { useContext, useEffect } from "react";
import { Routes, Route, useLocation, redirectDocument } from "react-router-dom";
import AppLogin from "../pages/customer/appLogin/appLogin";
import AppHome from "../pages/customer/appHome/appHome";
import AppGalleries from "../pages/customer/appGalleries/appGalleries";
import AppMain from "../pages/customer/appMain/appMain";
import AppEnquiries from "../pages/customer/appEnquiries/appEnquiries";
import Main from "../pages/admin/main/main";
import { GlobalContext } from "./GlobalContext";
import { lookup } from "dns";

const AppRoutes = () => {

    const { user } = useContext(GlobalContext);

    const location = useLocation().pathname;
    const authenticableComponents = [
        '/admin', 
        '/profile',
    ];

    const requiresAuthentication = async (path: string) => {
        const result = authenticableComponents.some((component) => {
            return path.startsWith(component);
        });
        return result;
    }

    useEffect(() => {
        (async () => {            
            if (await requiresAuthentication(location) && user === null) {//localStorage.getItem("activeAccount") === null) {
                document.location = '/';
            }            
        })();
    })

    return (
        <Routes>
            <Route path={"/"} element={user ? (user.type === 'MEMBER' ? <AppMain routedPage='GALLERY' /> : <Main />) : <AppMain /> } />
            <Route path={"/galleries"} element={<AppMain routedPage='GALLERY' />} />
            <Route path={"/galleries/*"} element={<AppMain routedPage='GALLERY' />} />
            <Route path={"/items"} element={<AppMain routedPage='ITEMS' />} />
            <Route path={"/items/:lookupFor"} element={<AppMain routedPage='ITEMS' />} />
            <Route path={"/enquiries"} element={<AppMain routedPage='ENQUIRIES' />} />
            <Route path={"/updates/:noticeType"} element={<AppMain routedPage='UPDATES' />} />
            <Route path={"/exchanges"} element={<AppMain routedPage='EXCHANGES' />} />
            <Route path={"/profile"} element={<AppMain routedPage='PROFILE' />} />
            <Route path={"/login"} element={<AppMain routedPage='LOGIN' />} />
            <Route path={"/login/reset"} element={<AppMain routedPage='LOGIN' />} />
            <Route path={"/login/reset/:verificationKey"} element={<AppMain routedPage='LOGIN' />} />
            <Route path={"/verification"} element={<AppMain routedPage='VERIFICATION' />} />
            <Route path={"/verification/:verificationKey"} element={<AppMain routedPage='VERIFICATION' />} />
            
            <Route path={"/admin"} element={<Main />} />
            {/* <Route path={"/pickupProgram"} element={<PickupProgram />} />
            <Route path={"/reportStack"} element={<ReportStack />} />
            <Route path={"/profile"} element={<Profile />} />
            <Route path={"/appointment"} element={<Appointment onClose={() => { }} onOK={() => { }} />} />
            <Route path={"/programDetails"} element={<ProgramDetails pickupProgram="" />} />
            <Route path={"/programDetails/:category/:neighbourhood/:date"} element={<ProgramDetails pickupProgram="" />} /> */}
        </Routes>
    );
};

export default AppRoutes;

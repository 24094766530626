import React, { FunctionComponent, ReactElement, useContext, useEffect, useRef, useState } from 'react';

import styles from "./appGalleries.module.scss";
import Modal from '../../../components/Modal/Modal';
import { Close, Contacts, Login, Logo, LogoDark, Search, SloganBig, SloganTiny, SocialsLinkedin, SocialsWhatsapp, SocialsYouTube } from '../../../components/icons/icons';
import Button from '../../../components/Button/Button';
// import galleryRugs from '../../assets/photos/categorySmallRugs.jpg';
// import galleryBooks from '../../assets/photos/categorySmallBooks.jpg';
// import galleryPaintings from '../../assets/photos/categorySmallPaintings.jpg';
// import galleryItems from '../../assets/photos/categorySmallItems.jpg';
// import galleryGold from '../../assets/photos/categorySmallGold.jpg';
import Textbox from '../../../components/Textbox/Textbox';
import { CategoryData, listCategories } from '../../../restAPI/categoryGate';
import { Settings } from '../../../helpers/Settings';
import { GlobalContext } from '../../../handlers/GlobalContext';
import { PopupsContext } from '../../../handlers/PopupsContext';


interface AppGalleriesProperties {
    onClick: (categoryId:string, categoryName:string) => void;
    onClose?: () => void;
}

const AppGalleries: FunctionComponent<AppGalleriesProperties> = (props) => {

    const {user, displayingCategory, updateCategory} = useContext(GlobalContext);
    const { showBusy, hideBusy } = useContext(PopupsContext);
    const [categoriesList, setCategoriesList] = useState<CategoryData[]>([]);
    const [searchFor, setSearchFor] = useState<string|undefined>(undefined);    


    useEffect(() => {
        (async () => {
            showBusy('busy', 'please wait ...');
            const categories = await listCategories();
            if (categories) {
                const list = await JSON.parse(categories).categoriesList;
                if (list)
                    setCategoriesList(list);
            }
            hideBusy();
        })()
    }, [])

    return (
        <div className={styles.container}  style={{height: '100%'}}>
            <div className={styles.userPageTitle}>
                Galleries
            </div>
            <div className={styles.separator}></div>
            <div className={styles.userTopControls}>
                <div className={styles.searchBoxFull}>
                    <Textbox borderColor='#CA7C4E'
                        fontFamily='interRegular'
                        fontSize='14px'
                        placeHolder='Search...'
                        width='80%' height='34px'                        
                        onChange={(value) => {
                            setSearchFor(value);
                        }}
                         />
                        <div className={styles.splitter}></div>
                    <Button color='#D9B59D'
                        borderColor='#CA7C4E'
                        border='1px solid #CA7C4E'
                        borderRadius='3px'
                        onClick={() => {
                            if(searchFor !== undefined)
                                document.location = `/items/${searchFor}`;
                        }}
                        width='34px' height='34px' image={require('../../../assets/icons/search.png')} imageHeight='24px' imageWidth='24px' />
                </div>
            </div>

            <div className={styles.separatorHalf}></div>
            <div className={styles.contentGalleries}>
                {
                    categoriesList.map((gallery, index) => {
                        //console.log(document.location.href);
                        return (
                            <div className={styles.gallery} key={index}
                                style={{                                    
                                    background: `center / contain no-repeat url('${Settings.api.path}/photos/categories/categoryLarge${gallery.pkCategory}.jpg')`
                                }} onClick={() => {
                                    if(props.onClick)                                        
                                        props.onClick(gallery.pkCategory!, gallery.title!);
                                }}>
                                    {/* <img src={document.location.href + gallery.photo} alt={'image'} key={'img' + index} style={{width: '100px'}} /> */}
                                <div className={styles.titleBox}>
                                    <div className={styles.title}>
                                        {gallery.title}
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
                {/* <div className={styles.gallery}
                    style={{
                        background: `center no-repeat url(${galleryRugs})`
                    }} onClick={() => {
                        document.location = '/items/';
                    }}>
                    <div className={styles.titleBox}>
                        <div className={styles.title}>
                            Rugs & Carpets
                        </div>
                    </div>
                </div>
                <div className={styles.gallery}
                    style={{
                        background: `center no-repeat url(${galleryBooks})`
                    }}>
                    <div className={styles.titleBox}>
                        <div className={styles.title}>
                            Bools & Publications
                        </div>
                    </div>
                </div>
                <div className={styles.gallery}
                    style={{
                        background: `center no-repeat url(${galleryPaintings})`
                    }}>
                    <div className={styles.titleBox}>
                        <div className={styles.title}>
                            Paintings & Sculpture
                        </div>
                    </div>
                </div>
                <div className={styles.gallery}
                    style={{
                        background: `center no-repeat url(${galleryItems})`
                    }}>
                    <div className={styles.titleBox}>
                        <div className={styles.title}>
                            Rare Items
                        </div>
                    </div>
                </div>
                <div className={styles.gallery}
                    style={{
                        background: `center no-repeat url(${galleryGold})`
                    }}>
                    <div className={styles.titleBox}>
                        <div className={styles.title}>
                            Gold & Jewelry
                        </div>
                    </div>
                </div> */}
            </div>
            <div className={styles.separatorDouble}></div>
        </div>
    );
}

export default AppGalleries;
import React, { Children, FunctionComponent, ReactElement, useEffect, useState } from "react";
import styles from "./selectList.module.scss";
import { Close, Enquiry } from "../icons/icons";
import Textbox from "../Textbox/Textbox";
import Button from "../Button/Button";

interface SelectListProps {
    width?: number;
    height?: number;
    listItems?: any[];
    selectedColor: string;
    unselectedColor: string;
    listBackgroundColor?: string;
    clickControl?: React.ReactNode;
    itemClicked?: (value: string) => void;
    onClick?: () => void;
}

const SelectList: FunctionComponent<SelectListProps> = (props) => {
    //{label:string, value:string, isSelected: boolean}
    const [selectItems, setSelectItems] = useState<any[]>([]);
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    useEffect(() => {
        if (props.listItems!) {
            setSelectItems(props.listItems);
        }

        return () => { }
    }, [props.listItems])

    return (
        <div className={styles.selectContainer}            
            style={{
                backgroundColor: props.listBackgroundColor ?? 'white'
            }}
        >
            {props.clickControl! &&
                <div className={styles.clickControl} onClick={
                    () => {
                        props.onClick!();
                    }
                }>
                    {props.clickControl!}
                </div>
            }
            {
                selectItems.map((selectItem) => {
                    return (
                        <div className={styles.selectItem}
                            style={{
                                borderWidth: '1px',
                                borderStyle: 'solid',
                                borderRadius: '5px',
                                borderColor: `${selectItem.isSelected ? 'unset' : props.selectedColor}`,
                                backgroundColor: `${selectItem.isSelected ? props.selectedColor : props.unselectedColor}`,
                                color: `${selectItem.isSelected ? props.unselectedColor : props.selectedColor}`,
                            }}
                            onClick={() => {
                                const temp = [...selectItems];
                                const togglingItem = temp.filter((element) => element.value === selectItem.value);
                                togglingItem[0].isSelected = !togglingItem[0].isSelected;
                                setSelectItems(temp);
                                props.itemClicked!(selectItem.value);
                            }}
                        >
                            {'#' + selectItem.label}
                        </div>);
                })
            }
        </div>
    );
}

export default SelectList;
import { FunctionComponent } from "react";
import { rejects } from "assert";
import { Settings } from "../helpers/Settings";
//import { getUserPickupCount } from "./pickup";

export interface UserData {
    pkUser?:string;
    fkClub?:string;
    name?:string;
    surname?:string;
    username?:string|undefined;
    birthday?:string|undefined;
    password?:string;
    type?:'MEMBER'|'STAFF'|'SUPERVISOR';
    clubRate?:number;
    clubName?:string;
    isActive?:boolean;
    isNew?:boolean;
    createdAt?:string;

    email?:string;
    phone?:string;
    address?:string;
    postalCode?:string;
}

export const saveUser = async (userData: UserData): Promise<any | undefined> => {
    return new Promise((resolve, reject) => {
        fetch(Settings.api.path + "/user/profile", {
            method: 'PUT',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React',
            }), 
            body: JSON.stringify({
                userData
            })
        }).then((response) => {            
            response.json().then((result) => {                
                resolve(result);
            }).catch((error) => {resolve(undefined)});
        }).catch((error) => {            
            resolve(undefined);
        });
    });
}

{/**
    only for new user signup from signup form
     */}
export const createUser = async (userData:UserData): Promise<any | undefined> => {
    return new Promise((resolve, reject) => {
        fetch(Settings.api.path + "/user/emailAccount", {
            method: 'POST',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React',
            }), 
            body: JSON.stringify({
                userData
            })
        }).then((response) => {            
            response.json().then((result) => {                
                resolve(result);
            }).catch((error) => {resolve(undefined)});
        }).catch((error) => {            
            resolve(undefined);
        });
    });
}

export const listUsers = async (type:'MEMBER'|'STAFF'|'SUPERVISOR', lookupUser: string|'*', isAscending:boolean, sortField:string|undefined): Promise<any | undefined> => {
    return new Promise((resolve, reject) => {
        fetch(Settings.api.path + `/user/list/${isAscending ? (sortField ?? 'createdAt') + ':ASC' : (sortField ?? 'createdAt') + ':DESC'}`, {
            method: 'POST',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React',
            }),
            body: JSON.stringify({
                type,
                lookupUser
            })
        }).then((response) => {            
            response.json().then((result) => {
                resolve(JSON.stringify(result));
            }).catch((error) => {resolve(error)});
        }).catch((error) => {
            resolve(undefined);
        });
    });
}

//uses POST method for security
export const getUser = async (userId:string): Promise<any | undefined> => {
    return new Promise((resolve, reject) => {
        fetch(Settings.api.path + "/user/profile", {
            method: 'POST',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React',
            }), 
            body: JSON.stringify({
                pkUser: userId
            })
        }).then((response) => {
            response.json().then((result) => {
                resolve(JSON.stringify(result));
            }).catch((error) => {resolve(error)});            
        }).catch((error) => {
            resolve(undefined);
        });
    });
}

//uses POST method for security
export const verifyUser = async (verificationCode:string): Promise<any | undefined> => {
    return new Promise((resolve, reject) => {
        fetch(Settings.api.path + `/user/verification`, {
            method: 'POST',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React',
            }), 
            body: JSON.stringify({
                verificationCode
            })
        }).then((response) => {
            response.json().then((result) => {
                resolve(JSON.stringify(result));
            }).catch((error) => {resolve(error)});            
        }).catch((error) => {
            resolve(undefined);
        });
    });
}

export const checkDuplicates = async (contactToCheck:string, type:'EMAIL'|'CELL', ignoreId:string|undefined): Promise<any | undefined> => {
    console.log(contactToCheck);
    return new Promise((resolve, reject) => {
        fetch(Settings.api.path + `/user/duplicates`, {
            method: 'POST',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React',
            }), 
            body: JSON.stringify({
                contactToCheck,
                type, 
                ignoreId
            })
        }).then((response) => {
            response.json().then((result) => {
                resolve(JSON.stringify(result));
            }).catch((error) => {resolve(error)});            
        }).catch((error) => {
            resolve(undefined);
        });
    });
}

export const authenticateUser = async (user:UserData): Promise<any | undefined> => {
    return new Promise((resolve, reject) => {
        fetch(Settings.api.path + "/user/authentication", {
            method: 'POST',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React',
            }), 
            body: JSON.stringify({
                username: user.username,
                password: user.password
            })
        }).then((response) => {
            response.json().then((result) => {
                resolve(JSON.stringify(result));
            }).catch((error) => {resolve(error)});            
        }).catch((error) => {
            resolve(undefined);
        });
    });
}

export const approveUser = async (userId:string): Promise<any | undefined> => {
    return new Promise((resolve, reject) => {
        fetch(Settings.api.path + "/user/approved", {
            method: 'PATCH',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React',
            }), 
            body: JSON.stringify({
                pkUser: userId
            })
        }).then((response) => {            
            response.json().then((result) => {                
                resolve(result);
            }).catch((error) => {resolve(undefined)});            
        }).catch((error) => {            
            reject(undefined);
        });
    });
}

export const enableUser = async (userId:string, enable:boolean): Promise<any | undefined> => {
    return new Promise((resolve, reject) => {
        fetch(Settings.api.path + "/user/activityState", {
            method: 'PATCH',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React',
            }), 
            body: JSON.stringify({
                pkUser: userId, 
                enable
            })
        }).then((response) => {            
            response.json().then((result) => {                
                resolve(result);
            }).catch((error) => {resolve(undefined)});            
        }).catch((error) => {            
            reject(undefined);
        });
    });
}

export const deleteUser = async (userId:string): Promise<any | undefined> => {
    return new Promise((resolve, reject) => {
        fetch(Settings.api.path + "/user", {
            method: 'DELETE',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React',
            }), 
            body: JSON.stringify({
                pkUser: userId
            })
        }).then((response) => {            
            response.json().then((result) => {                
                resolve(result);
            }).catch((error) => {resolve(undefined)});            
        }).catch((error) => {            
            reject(undefined);
        });
    });
}

export const updateUser = async (userData:UserData): Promise<any | undefined> => {
    return new Promise((resolve, reject) => {
        fetch(Settings.api.path + "/user/approved", {
            method: 'PATCH',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React',
            }), 
            body: JSON.stringify({
                userData
            })
        }).then((response) => {            
            response.json().then((result) => {                
                resolve(result);
            }).catch((error) => {resolve(undefined)});            
        }).catch((error) => {            
            reject(undefined);
        });
    });
}

export const forgotPassword = async (userData:UserData): Promise<any | undefined> => {
    return new Promise((resolve, reject) => {
        fetch(Settings.api.path + "/user/forgot", {
            method: 'PATCH',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React',
            }), 
            body: JSON.stringify({
                email: userData.username
            })
        }).then((response) => {           
                resolve(response);
        }).catch((error) => {            
            reject(undefined);
        });
    });
}

export const resetPassword = async (newPassword:string, 
    //email:string, 
    verificationCode:string): Promise<any | undefined> => {
    return new Promise((resolve, reject) => {
        fetch(Settings.api.path + "/user/reset", {
            method: 'PATCH',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React',
            }), 
            body: JSON.stringify({
                //email,
                verificationCode,
                newPassword
            })
        }).then((response) => {
            resolve(response);
        }).catch((error) => {            
            reject(undefined);
        });
    });
}

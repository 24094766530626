import React, { FunctionComponent, ReactElement, useContext, useEffect, useRef, useState } from 'react';

import styles from "./categories.module.scss";
import { Approve, Delete, Down, EnquiryItem, SortASC, Up } from '../../../components/icons/icons';
import Button from '../../../components/Button/Button';
import Textbox from '../../../components/Textbox/Textbox';
import ListItem from '../../../components/ListItem/ListItem';
import List from '../../../components/List/List';
import ToggleButton from '../../../components/ToggleButton/ToggleButton';
import { CategoryData, listCategories, listDeepCategories } from '../../../restAPI/categoryGate';
import { Settings } from '../../../helpers/Settings';
import { PopupsContext } from '../../../handlers/PopupsContext';


interface CategoriesProperties {
    onClose?: () => void;
}

const Categories: FunctionComponent<CategoriesProperties> = (props) => {

    const { showInfoPopup, showAlertPopup, showBusy, hideBusy } = useContext(PopupsContext);
    const [categoriesList, setCategoriesList] = useState<any[]>([]);
    const [showDetails, setShowDetails] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            showBusy('busy', '');
            const clubs = await listDeepCategories();
            if (clubs) {
                const list = await JSON.parse(clubs).categoriesList;
                if (list)
                    console.log(list);
                    setCategoriesList(list);
            }
            hideBusy();
        })()
    }, [])


    return (
        <div className={styles.container}>
            <div className={styles.separator}></div>
            {/* <div className={styles.userTopControls}>
                <div className={styles.searchBox}>
                    <Textbox borderColor='#CA7C4E'
                        fontFamily='interRegular'
                        fontSize='14px'
                        placeHolder='Search...'
                        width='80%' height='34px' />
                        <div className={styles.splitter}></div>
                    <Button color='#D9B59D'
                        borderColor='#CA7C4E'
                        border='1px solid #CA7C4E'
                        borderRadius='3px'
                        width='34px' height='34px' image={require('../../../assets/icons/search.png')} imageHeight='24px' imageWidth='24px' />
                </div>
                <SortASC width='32px' />
            </div> */}
            <div className={styles.separatorHalf}></div>
            <div className={styles.contentItems}>
                <List>
                    {
                        categoriesList.map((item, index) => {
                                return (
                                    <ListItem index={index} key={index}
                                        onClick={() => {}}                                        
                                        imageSource={`${Settings.api.path}/photos/categories/categorySmall${item.id}.jpg`}
                                        imageFrame='CIRCLE'
                                        imageSize={64}
                                        // expandable
                                        // expandIcon={<Down width={'32px'} />}
                                        // shrinkIcon={<Up width={'32px'} />}
                                        data={JSON.stringify({
                                            title: item.title
                                        })}>
                                        <div className={styles.listExpandControl} >
                                            <div>{item.itemsCount}</div>
                                        </div>
                                    </ListItem>
                                );                            
                        })
                    }
                </List>
            </div>

        </div>
    );
}

export default Categories;
import React, { FunctionComponent, ReactElement, useContext, useEffect, useRef, useState } from 'react';

import styles from "./appItemDetails.module.scss";
import Modal from '../../../components/Modal/Modal';
import { Close, CloseModal, CloseModalGold, Collapse, Enquiry, EnquiryItem, Expand, LeftGold, Logo, RightGold, SigninHeader } from '../../../components/icons/icons';
import FormItem from '../../../components/FormItem/FormItem';
import Textbox from '../../../components/Textbox/Textbox';
import Button from '../../../components/Button/Button';
import { PopupsContext } from '../../../handlers/PopupsContext';
import { GlobalContext } from '../../../handlers/GlobalContext';
import { getItem, getItemPhotos, ItemData } from '../../../restAPI/itemGate';
import { Settings } from '../../../helpers/Settings';
import { createEnquiry } from '../../../restAPI/enquiryGate';

interface AppItemDetailsProps {
    itemId: string;
    onClose?: () => void;
}

const AppItemDetails: FunctionComponent<AppItemDetailsProps> = (props) => {

    const { user } = useContext(GlobalContext);
    const { showModal, showInfoPopup, showBusy, hideBusy } = useContext(PopupsContext);

    const [currentItem, setCurrentItem] = useState<ItemData>({});
    const [photos, setPhotos] = useState<any[]>([]);
    
    const [enquiryMessage, setEnquiryMessage] = useState<string>('');

    const [zooming, setZooming] = useState<string>('');
    const [showZoomed, setShowZoomed] = useState<boolean>(false);
    const [enquiryMode, setEnquiryMode] = useState<boolean>(false);

    // const item = {
    //     'title': 'Versache diamond set',
    //     'description': 'whole set: necklace, bracelet, ear rings crafted on high 975 white plated gold, whole diamonds are grade A international and this item is is what you gain as a 5 part items!',
    //     'details': '   whole set: necklace, bracelet, ear rings whole set: necklace, bracelet, ear rings, lorem ipsum ips lorem lo rem ip su mwhole set: necklace, bracelet, ear rings whole set: necklace, bracelet, ear rings, lorem ipsum ips lorem lo rem ip sum.whole set: necklace, bracelet, ear rings whole set: necklace, bracelet, ear rings, lorem ipsum ips lorem lo rem ip sum whole set: necklace, bracelet, ear rings whole set: necklace, bracelet.',
    //     'expert': '',
    //     'price': '23500'
    // };

    useEffect(() => { 
        // window.addEventListener('popstate', (e) => {
        //     e.preventDefault();
        //     props.onClose!();
        // });

        (async () => {
            showBusy('busy', '');
            // setCurrentItem({ ...currentItem, fkOwner: user!.pkUser });
            // const clubs = await listClubs();            
            // if (clubs)
            //     setClubsList(JSON.parse(JSON.stringify(JSON.parse(clubs).clubsList.sort((a: any, b: any) => { return (a.rate < b.rate ? 1 : -1) })).replaceAll('pkClub', 'value').replaceAll('name', 'label')));

            // const categories = await listCategories();
            // if (categories){
            //     setCategoriesList(JSON.parse(JSON.stringify(JSON.parse(categories).categoriesList.sort((a: any, b: any) => { return (a.title > b.title ? 1 : -1) })).replaceAll('pkCategory', 'value').replaceAll('title', 'label')));
            // }

            if (props.itemId) {
                const result = await getItem(props.itemId);
                if (result) {
                    const itemDetails = await JSON.parse(result).itemDetails;
                    setCurrentItem(itemDetails);

                    const resultPhotos = await getItemPhotos(itemDetails.fkOwner, props.itemId!);
                    if (resultPhotos) {

                        const itemPhotos = await JSON.parse(resultPhotos).itemPhotos;
                        if (itemPhotos) {
                            const tempPhotos: any[] = [];
                            itemPhotos.map((element: any, index: number) => {
                                // const tempPhoto = {
                                //     id: index,
                                //     photo: `${Settings.api.path}/photos/items/${user!.pkUser}/${element}`,
                                //     index,
                                // };
                                tempPhotos.push(`${Settings.api.path}/photos/items/${itemDetails.fkOwner}/${element}`);
                            })

                            setPhotos(tempPhotos);
                        }
                    }
                }
                    
                    // const resultItemTags = await listItemTags(props.itemId);

                // if(resultItemTags){
                //     //because of Raw Query result JSON format
                //     const itemTagListJSON = await JSON.parse(resultItemTags).itemTags[0];

                //     if (itemTagListJSON) {
                //         const linkedTags: any[] = [];
                //         itemTagListJSON.map(async (element: any) => {
                //              const itemTag = { label: element.title, value: element.fkTag, isSelected: true, pkItemTag: element.pkItemTag };
                //              linkedTags.push(itemTag);
                //          });
                //         setCurrentItem({ ...currentItem, linkedTags: linkedTags });
                //     }
                // }
            }
            hideBusy();
        })()
    }, [enquiryMode])

    return (
        <Modal zIndex='1000' glassEffect='CLEARGLASS'>
            {showZoomed &&
                <Modal zIndex='1001' glassEffect='CLEARGLASS' onClick={() => { 
                    //setShowZoomed(false); 
                    }}>
                    <div className={styles.imageClose} onClick={() => { setShowZoomed(false); }}>
                        <CloseModalGold width={'32px'} />
                    </div>
                    <img className={styles.imagePreview} src={zooming} />
                </Modal>
            }
            <div className={styles.modalItemDetails}>

                <div className={styles.headerContainer}>
                    <div className={styles.logo}>
                        <Logo height={'68px'} />
                    </div>
                    <div className={styles.close} onClick={() => { props.onClose!() }}>
                        <CloseModalGold width={'32px'} />
                    </div>
                </div>
                <div className={styles.body}>

                    <div className={styles.photoAlbum}>

                        <div className={styles.left}>
                            <LeftGold />
                        </div>

                        <div className={styles.photoFrame}>

                        </div>
                        {/* <div className={styles.leftSpace}></div> */}
                        <div className={styles.photoRail}>
                            {
                                photos.map((photo, index) => {
                                    return (
                                        <div key={index} className={styles.photo} onClick={() => {
                                            setZooming(photo);
                                            setShowZoomed(true);
                                        }}>
                                            <img src={photo} height={'130px'} />
                                        </div>
                                    );
                                })
                            }
                        </div>
                        {/* <div className={styles.rightSpace}></div> */}

                        <div className={styles.right}>
                            <RightGold />
                        </div>
                    </div>

                    <div className={styles.data}>
                        <div className={styles.primary}>
                            <div className={styles.container}>
                                <div className={styles.title}>
                                    {currentItem.title}
                                </div>
                                <div className={styles.description}>
                                    {currentItem.description}
                                </div>
                                {enquiryMode &&
                                    <div className={styles.lineContainer}>
                                        <div className={styles.line}></div>
                                    </div>
                                }
                                <div className={styles.actionsContainer}>
                                    <div className={styles.actions}>
                                        <div className={styles.price}>
                                            <span className={styles.currency}>£&nbsp;</span>{currentItem.price?.toLocaleString()}
                                        </div>
                                        <div className={styles.enquiry} onClick={() => {
                                            if (user!)
                                                setEnquiryMode(!enquiryMode);
                                            else
                                                showModal('', 'You need to login to your account, to see auctions: login!',
                                                    <div onClick={() => {
                                                        document.location = '/login';
                                                    }}>go to LOGIN!</div>,
                                                    '', () => { }, () => { });
                                        }}>
                                            <EnquiryItem height='28px' fill={'#CA7C4E'} />&nbsp;enquiry&nbsp;
                                            {enquiryMode &&
                                                <Collapse width='28px' fill='#EDEAE388' />
                                            }
                                            {!enquiryMode &&
                                                <Expand width='28px' fill='#F9F8F688' />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.enquiryFrame}
                                style={{ display: `${enquiryMode ? 'flex' : 'none'}` }}>
                                {/* <div className={styles.header}>
                                    <div className={styles.tag}>
                                        enquire this item
                                    </div>
                                </div> */}
                                <div className={styles.separatorDouble}></div>
                                <FormItem title={'request description'} titleColor='white' titleFontFamily='interThin' titleFontSize='12px' errorMessage={''} width='90%'>
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Textbox type='multiline' width={'100%'} height={'100px'} 
                                            placeHolder={'we\'ll get back to you considering ...'} fontFamily='interRegular' fontSize='14px' 
                                            onChange={(value) => { 
                                                setEnquiryMessage(value);
                                            }} />
                                    </div>
                                </FormItem>
                                <div className={styles.separatorDouble}></div>
                                <Button text='enquire'
                                    textColor='#EDEAE3' fontFamily='interLight' fontSize='16px'
                                    border='1px solid #ADB8BA' borderRadius='0px' color='#153147'
                                    width='128px' height='38px'
                                    onClick={async () => {
                                        showBusy('busy', 'please wait ...');
                                        const enquiryResult = await createEnquiry(user!.pkUser, props.itemId, enquiryMessage);
                                        if(enquiryResult)
                                            showModal('', 'Your request sent successfully!\nCrown Gold Gallery experts, will contact\nyou soon, about the Item!', null, '', () => { }, () => { })
                                        hideBusy();
                                    }} />
                            </div>
                        </div>

                        <div className={styles.details}>
                            <div className={styles.container}>
                                <div className={styles.title}>
                                    details
                                </div>
                                <div className={styles.description}>
                                    {currentItem.details?? <div className={styles.noDetails}>no details available</div> }
                                </div>
                            </div>
                        </div>

                        <div className={styles.expert}>
                            <div className={styles.container}>
                                <div className={styles.title}>
                                    expert
                                </div>
                                <div className={styles.description}>
                                    {currentItem.expert?? <div className={styles.noDetails}>no details available</div> }
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </Modal>
    );
}

export default AppItemDetails;
import React, { Children, FunctionComponent, useEffect, useState } from "react";
import styles from "./modalDialog.module.scss";
import { inherits } from "util";
import Modal from "../Modal/Modal";
import Button from "../Button/Button";
import { ModalClose } from "../icons/icons";

interface ModalProps {
    title?: string;
    body?: string;
    okLabel?: string;
    /**
     * @property {property} description supports multiple items, semi colon character ';' works as separator
    */
    description?: string;
    fontFamily?: string;
    onOk?: (value: any) => void
    onCancel?: () => void
    children?: React.ReactNode;
}

const ModalDialog: FunctionComponent<ModalProps> = (props) => {
    return (
        <Modal color='#EEEBDF' glassEffect='CLEARGLASS'>
            <div className={styles.dialogContainer} style={{}}>
                <div className={styles.frame}>
                    <div className={styles.titleBar}>
                        <div className={styles.title}>{props.title}</div>
                        <div className={styles.closeButton}
                            onClick={() => {
                                if (props.onCancel!)
                                    props.onCancel();
                            }}>
                            <ModalClose height='32px' />
                        </div>
                    </div>
                    <div id='modalBody' className={styles.body}>
                        {props.body}
                    </div>
                    {props.children}
                    {props.description &&
                        <div className={styles.description}>
                            {
                                props.description.split(';').map((item) => {
                                    return (<div className={styles.descriptionItem}>{item}</div>)
                                })}
                        </div>
                    }
                    <div className={styles.spacer}></div>
                    <div className={styles.buttons}>
                        {props.okLabel !== '' &&
                            <Button text={props.okLabel}
                                onClick={async (event) => {
                                    event.stopPropagation();
                                    if (props.onOk!)
                                        props.onOk(event);
                                }}
                                width='128px' height={'38px'} borderRadius="0px" fontSize='18px' fontFamily='interMedium' color='#153147' />
                        }
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default ModalDialog;
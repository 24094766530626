import React, { Children, FunctionComponent, ReactElement, useEffect, useState } from "react";
import styles from "./listItemEmpty.module.scss";

interface ListItemEmptyProps {
    text?: string;
}

const ListItemEmpty: FunctionComponent<ListItemEmptyProps> = (props) => {


    useEffect(() => {

    }, [])

    return (
            <div className={styles.container + ' ' + styles.evenBackground}>        
                <div className={styles.item}>
                    {props.text?? 'nothing to show!'}
                </div>
            </div>
    );
}

export default ListItemEmpty;
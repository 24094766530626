import { FunctionComponent } from "react";
import { rejects } from "assert";
import { Settings } from "../helpers/Settings";

export enum Currency {
    GBP = 'GBP',
    GBPo = 'GBPo',
    USD = 'USD',
    EUR = 'EUR',
    IRCoinFull = 'IRCoinFull',
    IRCoinHalf = 'IRCoinHalf',
    IRCoinQuarter = 'IRCoinQuarter',    
    IRCoinGram = 'IRCoinGram'
}

export interface ExchangeData {
    pkExchange?:string;
    currency?:Currency;
    buyIRR?:number;
    sellIRR?:number;    
    createdAt?:string;
}

export const updateExchanges = async (exchangesData:ExchangeData[]): Promise<any | undefined> => {
    return new Promise((resolve, reject) => {
        fetch(Settings.api.path + "/exchange/new", {
            method: 'PUT',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React',
            }), 
            body: JSON.stringify({
                exchangesData
            })
        }).then((response) => {            
            response.json().then((result) => {                
                resolve(result);
            }).catch((error) => {resolve(undefined)});
        }).catch((error) => {            
            resolve(undefined);
        });
    });
}

export const listExchanges = async (): Promise<any | undefined> => {
    return new Promise((resolve, reject) => {
        fetch(Settings.api.path + "/exchange/list", {
            method: 'GET',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React',
            }),
        }).then((response) => {      
            response.json().then((result) => {
                resolve(JSON.stringify(result));
            }).catch((error) => {resolve(error)});
        }).catch((error) => {
            resolve(undefined);
        });
    });
}
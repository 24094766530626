import React, { FunctionComponent, ReactElement, useContext, useEffect, useRef, useState } from 'react';

import styles from "./appLogin.module.scss";
import Modal from '../../../components/Modal/Modal';
import { Close, CloseModal, SigninHeader, Staff } from '../../../components/icons/icons';
import FormItem from '../../../components/FormItem/FormItem';
import Textbox from '../../../components/Textbox/Textbox';
import Button from '../../../components/Button/Button';
import { PopupsContext } from '../../../handlers/PopupsContext';
import { authenticateUser, checkDuplicates, createUser, forgotPassword, resetPassword, UserData } from '../../../restAPI/userGate';
import { GlobalContext } from '../../../handlers/GlobalContext';
import { useLocation, useParams } from 'react-router-dom';

interface AppLoginProps {
    userType?: 'MEMBER' | 'STAFF'
    pageFunction?: 'LOGIN' | 'SIGNUP' | 'ّFORGOTPASSWORD' | 'ّRESETPASSWORD';
    onClose?: () => void;
}

const AppLogin: FunctionComponent<AppLoginProps> = (props) => {

    const regexMail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    const regexCell = /^(\+|00)[1-9][0-9]{7,32}$/;

    const { user } = useContext(GlobalContext);
    const { showModal, showBusy, hideBusy } = useContext(PopupsContext);

    const { verificationKey } = useParams();
    const location = useLocation();
    const [activeFunc, setActiveFunc] = useState<string>(props.pageFunction ?? 'LOGIN');
    const [userData, setUserData] = useState<UserData>({ type: 'MEMBER' });
    const [password, setPassword] = useState<string>('');
    const [retypePassword, setRetypePassword] = useState<string>();
    const [isValidMail, setIsValidMail] = useState<boolean>(true);
    const [isValidPhone, setIsValidPhone] = useState<boolean>(true);
    const [existingEmail, setExistingEmail] = useState<boolean>(false);
    const [existingCell, setExistingCell] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            if (location.pathname.split('/')[2]! === 'reset')
                setActiveFunc('ّRESETPASSWORD');
        })()
    }, [])

    useEffect(() => {
        (async () => {
        })()
    }, [password, retypePassword])

    // use ONLY for settings when page 
    // switches between functionalities
    useEffect(() => {
        setIsValidMail(true);
        setRetypePassword(undefined);
        setUserData((alreadyUser) => ({ ...alreadyUser, password: undefined }));
        (async () => {
            if (props.userType)
                setUserData({ ...userData, type: props.userType });
            else
                setUserData({ ...userData, type: 'MEMBER' });
        })()
    }, [activeFunc])

    const login = async () => {
        if (isValidMail) {
            showBusy('busy', '');
            try {
                console.log(userData);
                const user = await authenticateUser(userData);//.then((response: any) => {
                if (user!) {
                    const result = await JSON.parse(user);

                    if (result.user) {
                        localStorage.setItem('activeAccount', JSON.stringify(result.user));
                        document.location = '/';
                        props.onClose!();
                    }
                    else
                        showModal('login result', result.error, null, '', () => { }, () => { });
                }
            }
            catch (error: any) {
                showModal('login fail\n', error!, null, '', () => { }, () => { });
            }
            hideBusy();
        }
    }

    return (
        <Modal zIndex='1000' glassEffect='CLEARGLASS'>
            {activeFunc === 'LOGIN' &&
                <div className={styles.modalPage}>
                    <div className={styles.headerContainer}>
                        <div className={styles.close} onClick={() => { props.onClose!() }}>
                            <CloseModal width={'32px'} />
                            <div className={styles.splitter}></div>
                            <div className={styles.splitter}></div>
                        </div>
                        <div className={styles.header}>
                            <div className={styles.splitter}></div>
                            <div className={styles.splitter}></div>
                            {props.userType === 'STAFF' ? <Staff width={'64px'} /> : <SigninHeader width={'64px'} />}
                            <div className={styles.splitter}></div>
                            login
                        </div>
                    </div>
                    <div className={styles.body}>
                        <FormItem title={'username (user/email/cell)'} errorMessage={''}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Textbox width={'241px'} height={'42px'} placeHolder={'username / email / cell number'}
                                    onChange={(value) => {
                                        setUserData({ ...userData, username: value });
                                    }} />
                            </div>
                        </FormItem>
                        <div className={styles.separator}></div>
                        <FormItem title={'password'} errorMessage={''}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Textbox width={'241px'} height={'42px'} placeHolder={'password'} type='password'
                                    onEnter={() => {
                                        login();
                                    }}
                                    onChange={(value) => {
                                        setUserData({ ...userData, password: value });
                                    }} />
                            </div>
                        </FormItem>
                        <div className={styles.separator}></div>
                        <div className={styles.otherFunctions}>
                            <div className={styles.link} onClick={() => {
                                setActiveFunc('ّFORGOTPASSWORD');
                            }}>forgot password?</div>
                            <div className={styles.link} onClick={() => {
                                setActiveFunc('SIGNUP');
                            }}>sign me up</div>
                        </div>
                        <div className={styles.separatorDouble}></div>
                        <div className={styles.button}>
                            <Button text='login'
                                textColor='#EDEAE3' fontFamily='interLight' fontSize='16px'
                                border='1px solid #ADB8BA' borderRadius='0px' color='#153147'
                                width='128px' height='38px'
                                onClick={ () => login() } />
                        </div>
                    </div>
                    <div className={styles.separator}></div>
                    <div className={styles.separator}></div>
                </div>
            }

            {activeFunc === 'SIGNUP' &&
                <div className={styles.modalPage}>
                    <div className={styles.headerContainer}>
                        <div className={styles.close} onClick={() => { props.onClose!() }}>
                            <CloseModal width={'32px'} />
                            <div className={styles.splitter}></div>
                            <div className={styles.splitter}></div>
                        </div>
                        <div className={styles.header}>
                            <div className={styles.splitter}></div>
                            <div className={styles.splitter}></div>
                            {props.userType === 'STAFF' ? <Staff width={'64px'} /> : <SigninHeader width={'64px'} />}
                            <div className={styles.splitter}></div>
                            signup
                        </div>
                    </div>
                    <div className={styles.body}>
                        <FormItem title={'name'} errorMessage={''}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Textbox width={'241px'} height={'42px'} placeHolder={'Name'}
                                    onChange={(value: string) => {
                                        setUserData((alreadyUser) => ({
                                            ...alreadyUser,
                                            name: value
                                        }));
                                    }} />
                            </div>
                        </FormItem>
                        <div className={styles.separator}></div>
                        <FormItem title={'surename'} errorMessage={''}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Textbox width={'241px'} height={'42px'} placeHolder={'Surename'}
                                    onChange={(value: string) => {
                                        setUserData((alreadyUser) => ({
                                            ...alreadyUser,
                                            surname: value
                                        }));
                                    }} />
                            </div>
                        </FormItem>
                        <div className={styles.separator}></div>
                        <FormItem title={'phone'} errorMessage={existingCell! ? 'another account is using this phone' : !isValidPhone ? 'invalid phone number' : ''}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Textbox width={'241px'} height={'42px'} placeHolder={'+112312345 / 004412312345'} type='phone' validationIcon={existingCell?? undefined}
                                    onChange={(value: string) => {
                                        setUserData((alreadyUser) => ({
                                            ...alreadyUser,
                                            phone: value
                                        }));
                                        (async () => {

                                            if (value !== '') {
                                                const cellExists = (await JSON.parse(await checkDuplicates(value.replace('00', '+'), 'CELL', undefined)).result);
                                                console.log(value.replace('00', '+'), cellExists);

                                                if (cellExists) {
                                                    setExistingCell(true);
                                                }
                                                else {
                                                    setExistingCell(false);
                                                    if (regexCell.test(value)) {
                                                        setIsValidPhone(true);
                                                    }
                                                    else
                                                        setIsValidPhone(false);
                                                }
                                            }
                                        })();
                                    }} />
                            </div>
                        </FormItem>
                        <div className={styles.separator}></div>
                        <FormItem title={'email (username)'} errorMessage={existingEmail! ? 'another account is using this email' : !isValidMail ? 'invalid email address' : ''}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Textbox width={'241px'} height={'42px'} placeHolder={'email/username)'} type='email' validationIcon={existingEmail?? undefined}
                                    onChange={ (value: string) => {
                                        setUserData((alreadyUser) => ({
                                            ...alreadyUser,
                                            email: value
                                        }));
                                        (async () => {
                                            if (value !== '') {
                                                const emailExists = (await JSON.parse(await checkDuplicates(value, 'EMAIL', undefined)).result);

                                                if (emailExists) {
                                                    setExistingEmail(true);
                                                }
                                                else {
                                                    setExistingEmail(false);
                                                    if (regexMail.test(value)) {
                                                        setIsValidMail(true);
                                                    }
                                                    else
                                                        setIsValidMail(false);
                                                }
                                            }
                                        })();
                                    }} />
                            </div>
                        </FormItem>
                        <div className={styles.separator}></div>
                        <FormItem title={'password'} errorMessage={userData.password === '' ? 'password required' : (retypePassword !== userData.password ? 'password & retype are not same!' : '')}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Textbox width={'241px'} height={'42px'} placeHolder={'password'} type='password'
                                    onChange={(value: string) => {
                                        setUserData((alreadyUser) => ({
                                            ...alreadyUser,
                                            password: value
                                        }));
                                    }} />
                            </div>
                        </FormItem>
                        <div className={styles.separator}></div>
                        <FormItem title={'retype password'} errorMessage={''}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Textbox width={'241px'} height={'42px'} placeHolder={'retype password'} type='password'
                                    onChange={(value: string) => {
                                        setRetypePassword(value);
                                    }} />
                            </div>
                        </FormItem>
                        <div className={styles.separator}></div>
                        <div className={styles.otherFunctions}>                            
                            <div className={styles.splitter}></div>
                            <div className={styles.link} onClick={() => {
                                setActiveFunc('LOGIN');
                            }}>back to login</div>
                        </div>
                        <div className={styles.separatorDouble}></div>
                        <div className={styles.button}>
                            <Button text='signup'
                                textColor='#EDEAE3' fontFamily='interLight' fontSize='16px'
                                border='1px solid #ADB8BA' borderRadius='0px' color='#153147'
                                width='128px' height='38px'
                                onClick={async () => {
                                    console.log('CELL PHONE: ' + userData.phone);
                                    if (userData.password !== '' && 
                                        isValidMail && !existingEmail && 
                                        (userData.phone === '' || (isValidPhone && !existingCell))) {
                                        showBusy('busy', '');
                                        if (retypePassword === userData.password) {
                                            const result = await createUser(userData);
                                            console.log('SIGNUP res: ' + result.insertedUser);
                                            if (result!) {
                                                showModal('', 'Your membership request is being processed, you will receive your activation email message afterwards!', <div style={{fontSize:'12px'}}>make sure to check your spams/junk mails folder too!</div>, '',
                                                    () => {
                                                        document.location = '/';
                                                        props.onClose!();
                                                    },
                                                    () => {
                                                        document.location = '/';
                                                        props.onClose!();
                                                     });
                                            }
                                            else{

                                            }
                                        }
                                        hideBusy();
                                    }
                                }} />
                        </div>
                    </div>
                    {/* <div className={styles.otherFunctions}>
                        <div className={styles.text}>forgot password?</div>
                        <div className={styles.link} onClick={() => {
                            setActiveFunc('ّFORGOTPASSWORD');
                        }}>reset</div>
                    </div> */}
                    <div className={styles.separator}></div>
                    <div className={styles.separator}></div>
                </div>
            }

            {activeFunc === 'ّFORGOTPASSWORD' &&
                <div className={styles.modalPage}>
                    <div className={styles.headerContainer}>
                        <div className={styles.close} onClick={() => { props.onClose!() }}>
                            <CloseModal width={'32px'} />
                            <div className={styles.splitter}></div>
                            <div className={styles.splitter}></div>
                        </div>
                        <div className={styles.header}>
                            <div className={styles.splitter}></div>
                            <div className={styles.splitter}></div>
                            {props.userType === 'STAFF' ? <Staff width={'64px'} /> : <SigninHeader width={'64px'} />}
                            <div className={styles.splitter}></div>
                            forgot password
                        </div>
                    </div>
                    <div className={styles.body}>
                        <div className={styles.otherFunctions} style={{width: '300px'}}>
                            <div className={styles.text}>forgot my password, please send an email to reset my password:</div>
                        </div>
                        <div className={styles.separator}></div>
                        <FormItem title={'email'} errorMessage={!isValidMail ? 'invalid email address' : ''}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Textbox width={'300px'} height={'42px'} placeHolder={'email'}
                                    onChange={(value) => {
                                        if (regexMail.test(value)) {
                                            setIsValidMail(true);
                                            setUserData({ ...userData, username: value });
                                        }
                                        else
                                            setIsValidMail(false);
                                    }} />
                            </div>
                        </FormItem>
                        <div className={styles.otherFunctions} style={{width: '300px'}}>
                        <div className={styles.splitter}></div>
                            <div className={styles.link} onClick={() => {
                                setActiveFunc('LOGIN');
                            }}>back to login</div>
                        </div>
                        <div className={styles.separatorDouble}></div>
                        <div className={styles.button}>
                            <Button text='send'
                                textColor='#EDEAE3' fontFamily='interLight' fontSize='16px'
                                border='1px solid #ADB8BA' borderRadius='0px' color='#153147'
                                width='128px' height='38px'
                                onClick={async () => {
                                    showBusy('busy', '');
                                    const resetResult = await forgotPassword(userData);
                                    if (resetResult.status === 200) {
                                        showModal('', 'You will receive an email with reset password link in it, please click the link to reset password!', <div style={{fontSize:'12px'}}>make sure to check your spams/junk mails folder too!</div>, '',
                                            () => { },
                                            () => { document.location = '/' });
                                    }
                                    else {
                                        resetResult.json().then((result: any) => {
                                            showModal('', result.message, null, '',
                                                () => { },
                                                () => { document.location = '/' });
                                        }).catch((error: any) => {
                                            showModal('', error, null, '',
                                                () => { },
                                                () => { document.location = '/' });
                                        });
                                    }
                                    hideBusy();
                                }} />
                        </div>
                        <div className={styles.separatorDouble}></div>                        
                    </div>

                    <div className={styles.separator}></div>
                    <div className={styles.separator}></div>
                </div>
            }

            {activeFunc === 'ّRESETPASSWORD' &&
                <div className={styles.modalPage}>
                    <div className={styles.headerContainer}>
                        <div className={styles.close} onClick={() => { props.onClose!() }}>
                            <CloseModal width={'32px'} />
                            <div className={styles.splitter}></div>
                            <div className={styles.splitter}></div>
                        </div>
                        <div className={styles.header}>
                            <div className={styles.splitter}></div>
                            <div className={styles.splitter}></div>
                            {props.userType === 'STAFF' ? <Staff width={'64px'} /> : <SigninHeader width={'64px'} />}
                            <div className={styles.splitter}></div>
                            reset password
                        </div>
                    </div>
                    <div className={styles.body}>
                        <FormItem title={'new password'} errorMessage={password !== retypePassword ? 'password & retype do not match' : ''}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Textbox width={'241px'} height={'42px'} placeHolder={'new password'} type='password'
                                    onChange={(value) => {
                                        setPassword(value);
                                    }} />
                            </div>
                        </FormItem>
                        <div className={styles.separator}></div>
                        <FormItem title={'retype new password'} errorMessage={''}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Textbox width={'241px'} height={'42px'} placeHolder={'retype new password'} type='password'
                                    onChange={(value) => {
                                        setRetypePassword(value);
                                    }} />
                            </div>
                        </FormItem>
                        <div className={styles.separatorDouble}></div>
                        <div className={styles.button}>
                            <Button text='reset'
                                textColor='#EDEAE3' fontFamily='interLight' fontSize='16px'
                                border='1px solid #ADB8BA' borderRadius='0px' color='#153147'
                                width='128px' height='38px'
                                onClick={async () => {
                                    showBusy('busy', '');
                                    //const resetResult = await resetPassword(password, location.pathname.split('/')[3], location.pathname.split('/')[4]);
                                    const resetResult = await resetPassword(password, verificationKey!);
                                    if (resetResult.status === 200) {
                                        showModal('', 'Please log in with your new password!', null, '',
                                            () => { },
                                            () => { document.location = '/login' });
                                    }
                                    else {
                                        resetResult.json().then((result: any) => {
                                            showModal('', result.message, null, '',
                                                () => { },
                                                () => { document.location = '/' });
                                        }).catch((error: any) => {
                                            showModal('', error, null, '',
                                                () => { },
                                                () => { document.location = '/' });
                                        });
                                    }
                                    hideBusy();
                                }} />
                        </div>
                    </div>

                    <div className={styles.separator}></div>
                </div>
            }
        </Modal>
    );
}

export default AppLogin;
import React, { FunctionComponent, ReactElement, useContext, useEffect, useRef, useState } from 'react';

import styles from "./enquiries.module.scss";
import { EnquiryItem, SortASC, SortDESC } from '../../../components/icons/icons';
import Button from '../../../components/Button/Button';
import Textbox from '../../../components/Textbox/Textbox';
import ListItem from '../../../components/ListItem/ListItem';
import EnquiryDetails from './enquiryDetails/enquiryDetails';
import { listEnquiries, ListingTypes } from '../../../restAPI/enquiryGate';
import { GlobalContext } from '../../../handlers/GlobalContext';
import { PopupsContext } from '../../../handlers/PopupsContext';
import { Settings } from '../../../helpers/Settings';



interface EnquiriesProperties {
    onClose?: () => void;
}

const Enquiries: FunctionComponent<EnquiriesProperties> = (props) => {

    const {user} = useContext(GlobalContext);
    const {showBusy, hideBusy} = useContext(PopupsContext);

    const [enquiriesList, setEnquiriesList] = useState<any[]>([]);
    const [showDetails, setShowDetails] = useState<boolean>(false);
    const [selectedEnquiry, setSelectedEnquiry] = useState<string>();

    const [lookupEnquiry, setLookupEnquiry] = useState<string>('*');
    const [isAscending, setIsAscending] = useState<boolean>(false);

    useEffect(() => {
        (() => {
            if(lookupEnquiry === '*')
                loadEnquiries();
        })()
    }, [user, isAscending, lookupEnquiry, selectedEnquiry])

    const loadEnquiries = async () => {
        showBusy('busy', 'please wait ...');
        setEnquiriesList([]);
        let listingType = ListingTypes.ENQUIRER;
        switch (user!.type) {
            case 'MEMBER':
                listingType = ListingTypes.ENQUIRER;
                break;
            case 'STAFF':
                listingType = ListingTypes.OWNER;
                break;
            case 'SUPERVISOR':
                listingType = ListingTypes.SUPERVISOR;
                break;
        }
        const enquiryListResult = await listEnquiries(user!.pkUser, listingType, lookupEnquiry, isAscending, undefined);
        if (enquiryListResult) {
            const list = await JSON.parse(enquiryListResult).enquiriesList;
            if (list)
                setEnquiriesList(list);
        }
        hideBusy();
    }    

    return (
        <div className={styles.container}>
            {showDetails &&
                <EnquiryDetails enquiryId={selectedEnquiry!} onClose={() => { setShowDetails(false) }} />
            }
            <div className={styles.separator}></div>
            <div className={styles.userTopControls}>
                <div className={styles.searchBox}>
                    <Textbox borderColor='#CA7C4E'
                        fontFamily='interRegular'
                        fontSize='14px'
                        placeHolder='Search...'
                        width='80%' height='34px' 
                        isSearchbox
                        text={lookupEnquiry === '*' ? '' : lookupEnquiry}
                        onClearSearch={ () => {
                            setLookupEnquiry('*');
                        }}
                        onChange={(value) => {
                            if(value.trim() !== '')
                                setLookupEnquiry(value);
                            else{
                                setLookupEnquiry('*');
                            }
                        }} />
                    <div className={styles.splitter}></div>
                    <Button color='#D9B59D'
                        borderColor='#CA7C4E'
                        border='1px solid #CA7C4E'
                        borderRadius='3px'
                        width='34px' height='34px' 
                        image={require('../../../assets/icons/search.png')} 
                        imageHeight='24px' imageWidth='24px' 
                        onClick={() => {
                            loadEnquiries();
                        }}/>
                </div>
                <div
                    onClick={() => {
                        setIsAscending(!isAscending);
                    }}>
                    {isAscending ? <SortASC width='32px' /> : <SortDESC width='32px' />}
                </div>
            </div>
            <div className={styles.separatorHalf}></div>
            <div className={styles.contentItems}>
                    {
                        enquiriesList.map((item, index) => {
                            let date = ((new Date(item.createdAt)).toLocaleDateString('en-US', {
                                    weekday: 'short',  // Full day name (e.g., Monday)
                                    year: 'numeric',  // Full year (e.g., 2024)
                                    month: 'short',    // Full month name (e.g., September)
                                    day: 'numeric'    // Day of the month (e.g., 13)
                                }) + ' ' + item.createdAt.split('T')[1].substring(0,5));
                            return (
                                <div className={styles.item} key={index}>
                                    <ListItem index={index}
                                        onClick={() => {
                                            setSelectedEnquiry(item.id);
                                            setShowDetails(true);
                                        }}
                                        //image={require('../../../assets/icons/enquiry.svg').default}
                                        imageSource={`${Settings.api.path}/photoThumbs/items/${item.fkOwner}/${item.fkItem}_0.jpg`}
                                        imageFrame='CIRCLE'
                                        imageSize={64}   

                                        data={JSON.stringify({
                                            title: item.title,
                                            details: item.enquirerFullName, 
                                            extra: date// item.createdAt.split('T')[0].substring(2).replaceAll('-', '/') + ' ' + item.createdAt.split('T')[1].substring(0,5)
                                        })}>
                                    </ListItem>
                                </div>
                            );
                        })
                    }
                
            </div>

        </div>
    );
}

export default Enquiries;
import React, { FunctionComponent, ReactElement, useContext, useEffect, useRef, useState } from 'react';

import styles from "./itemInfo.module.scss";
import Modal from '../../../../components/Modal/Modal';
import { Calendar, CloseModal, Delete, Gallery, GalleryArtistic, Item, SigninHeader, Staff, Tag } from '../../../../components/icons/icons';
import FormItem from '../../../../components/FormItem/FormItem';
import Textbox from '../../../../components/Textbox/Textbox';
import Button from '../../../../components/Button/Button';
import { PopupsContext } from '../../../../handlers/PopupsContext';
import ToggleButton from '../../../../components/ToggleButton/ToggleButton';
import Dropdown from '../../../../components/DropDown/Dropdown';
import { getUser, saveUser, createUser, UserData, enableUser } from '../../../../restAPI/userGate';
import { listClubs } from '../../../../restAPI/clubGate';
import { listCategories } from '../../../../restAPI/categoryGate';
import { createItem, deleteItem, getItem, getItemPhotos, ItemData, recordItemPhoto, removeExistingPhoto, removeTempItemPhoto, removeTempItemPhotos, updateItem } from '../../../../restAPI/itemGate';
import { GlobalContext } from '../../../../handlers/GlobalContext';
import Progress from '../../../../components/progress/Progress';
import ItemTagSelect from '../../itemTagSelect/itemTagSelect';
import SelectList from '../../../../components/SelectList/selectList';
import { Settings } from '../../../../helpers/Settings';
import { ItemTag, listItemTags } from '../../../../restAPI/itemTagsGate';
import { TagData } from '../../../../restAPI/tagGate';

interface ItemInfoProperties {
    itemId?: string;
    onClose?: () => void;
}

const ItemInfo: FunctionComponent<ItemInfoProperties> = (props) => {

    const { user } = useContext(GlobalContext);
    const { showModal, showInfoPopup, showBusy, hideBusy } = useContext(PopupsContext);

    const [currentItem, setCurrentItem] = useState<ItemData>({});

    const [categoriesList, setCategoriesList] = useState<[]>([]);
    const [clubsList, setClubsList] = useState<[]>([]);
    const [linkedTags, setLinkedTags] = useState<TagData[]>([]);

    //#region Item Photo Handling
    const initialRef: any = null;
    const itemFilePicker = useRef(initialRef);
    const [photos, setPhotos] = useState<any[]>([]);
    const [lastPhotoIndex, setLastPhotoIndex] = useState<number>(0);
    const [uploadingPhoto, setUploadingPhoto] = useState<boolean>(false);
    const [showTagSelect, setShowTagSelect] = useState<boolean>(false);
    const [showLargeImage, setShowLargeImage] = useState<boolean>(false);
    const [largeImage, setLargeImage] = useState<string>('');
    //#endregion

    useEffect(() => {
        (async () => {                        
            //const res = await SON.parse(currentItem));
            console.log('current ITem: ' + currentItem.pkItem);
        })()
    }, [currentItem, photos])

    useEffect(() => { 
        (async () => {
            showBusy('busy', '');
            if (!props.itemId)
                setCurrentItem({ ...currentItem, fkOwner: user!.pkUser });

            const clubs = await listClubs();            
            if (clubs)
                setClubsList(JSON.parse(JSON.stringify(JSON.parse(clubs).clubsList.sort((a: any, b: any) => { return (a.rate < b.rate ? 1 : -1) })).replaceAll('pkClub', 'value').replaceAll('name', 'label')));
            
            const categories = await listCategories();
            if (categories){
                setCategoriesList(JSON.parse(JSON.stringify(JSON.parse(categories).categoriesList.sort((a: any, b: any) => { return (a.title > b.title ? 1 : -1) })).replaceAll('pkCategory', 'value').replaceAll('title', 'label')));
            }

            if (props.itemId!) {
                const result = await getItem(props.itemId);
                if (result) {
                    const itemDetails = await JSON.parse(result).itemDetails;
                    setCurrentItem(itemDetails);                    
                                        
                    const resultPhotos = await getItemPhotos(itemDetails.fkOwner, props.itemId!);
                    if (resultPhotos) {
                        
                        const itemPhotos = await JSON.parse(resultPhotos).itemPhotos;
                        if (itemPhotos) {
                            const tempPhotos: any[] = [];
                            itemPhotos.map((element: any, index: number) => {
                                const tempPhoto = {
                                    id: index,
                                    photo: `${Settings.api.path}/photos/items/${itemDetails.fkOwner}/${element}`,
                                    index,
                                };
                                tempPhotos.push(tempPhoto);
                                console.log('photo: ' + tempPhoto.index);
                            })
                            
                            setPhotos(tempPhotos);
                        }
                    }                    
                }
                
                const resultItemTags = await listItemTags(props.itemId);
                
                if(resultItemTags){
                    //because of Raw Query result JSON format
                    const itemTagListJSON = await JSON.parse(resultItemTags).itemTags[0];
                    console.log('current TAGS: ' + resultItemTags);
                    if (itemTagListJSON) {
                        const linkedTags: any[] = [];
                        await itemTagListJSON.map(async (element: any) => {
                            const itemTag = { label: element.title, value: element.fkTag, isSelected: true, pkItemTag: element.pkItemTag };
                            linkedTags.push(itemTag);                            
                        });
                        setLinkedTags(linkedTags);
                        
                        console.log('current ITM: ' + currentItem.pkItem);
                        console.log(linkedTags);
                        //currentItem.linkedTags = linkedTags;
                    }
                }
            }

            try {
                const res = await removeTempItemPhotos(user!.pkUser);
            } catch (error) {
                console.log('---{ unable to remove user\'s temp item photos: ' + error);
            }            
            hideBusy();
        })()
    }, [])


    const addItemPhoto = (dataUri: any) => {
        if (dataUri !== null) {
            setUploadingPhoto(true);
            const photoURL = URL.createObjectURL(dataUri);
            let photoJSON = { id: null, photo: photoURL, index: lastPhotoIndex };
            const photosTemp = [...photos];//spread operator
            photosTemp.push(photoJSON);
            let activeIndex = photos.length;
            setPhotos(photosTemp);

            const reader = new FileReader();
            reader.onloadend = async () => {
                const base64Image = reader.result!.toString().split(',')[1];
                const result = await recordItemPhoto(user.pkUser!, base64Image, lastPhotoIndex, dataUri);

                if (result) {
                    photosTemp[activeIndex] = {
                        id: result.photoIndex === undefined ? 'undefined' : result.photoIndex,
                        photo: photosTemp[activeIndex].photo,
                        index: result.photoIndex
                    };
                    setPhotos(photosTemp);
                    setLastPhotoIndex(lastPhotoIndex + 1);
                    setUploadingPhoto(false);
                }
            }
            reader.readAsDataURL(dataUri);
        }
    }

    const removeItemPhoto = async (index: number) => {
        setUploadingPhoto(true);
        
        const photosTemp = [...photos.filter((element) => element.id !== photos[index].id)];
        
        const result = await removeTempItemPhoto(user!.pkUser, index);
        if (result.affectedFiles === 1)
            setPhotos(photosTemp);

        setUploadingPhoto(false);        
    }

    return (
        <Modal zIndex='1000' glassEffect='CLEARGLASS'>
            {
                showTagSelect &&
                <ItemTagSelect selectedTags={linkedTags} onClose={(selectedTags) => {
                    setShowTagSelect(false);
                    const tempTags = [...selectedTags];
                    setLinkedTags(tempTags);
                }} />
            }
            <div className={styles.modalPage}>
                <div className={styles.headerContainer}>
                    <div className={styles.close} onClick={() => { props.onClose!() }}>
                        <CloseModal width={'32px'} />
                        <div className={styles.splitter}></div>
                        <div className={styles.splitter}></div>
                    </div>
                    <div className={props.itemId ? styles.headerEx : styles.header}>
                        <div className={styles.header}>
                            <div className={styles.splitter}></div>
                            <div className={styles.splitter}></div>
                            <Item width={'64px'} fill={'#2C3D5B'} />
                            <div className={styles.splitter}></div>
                            <div className={styles.text}>
                                <div className={styles.title}>{props.itemId! ? 'item details' : 'new item'}</div>
                                <div className={styles.data}>{currentItem.createdAt ? (currentItem.createdAt.split('T')[0].substring(2).replaceAll('-', '/') + ' ' + currentItem.createdAt.split('T')[1].substring(0, 5)) : ''}</div>
                            </div>
                        </div>
                        <div className={styles.expansion}>
                            <div className={styles.splitter}></div>
                            <div className={styles.splitter}></div>
                        </div>
                    </div>
                </div>
                <div className={styles.separatorDouble}></div>
                <div className={styles.bodyLeft}>

                    <FormItem title={'category'} errorMessage={''}>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Dropdown items={categoriesList} width={'180px'} height={'32px'} placeHolder={'category'}
                                initialItem={currentItem.fkCategory}
                                onChange={(selectedData) => {                                    
                                    setCurrentItem({ ...currentItem, fkCategory: selectedData.value });
                                }}
                            />
                        </div>
                    </FormItem>
                    <div className={styles.separator}></div>
                    <FormItem title={'minimum target club'} errorMessage={''}>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Dropdown items={clubsList} width={'180px'} height={'32px'} placeHolder={'visible club & higher'}
                                initialItem={currentItem.fkClub}
                                onChange={(selectedData) => {                                    
                                    setCurrentItem({ ...currentItem, fkClub: selectedData.value });
                                }}
                            />
                        </div>
                    </FormItem>

                    <div className={styles.separator}></div>
                    <FormItem title={'title'} errorMessage={''} width='90%'>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Textbox width={'100%'} height={'32px'} placeHolder={'title'}
                                text={currentItem.title}
                                onChange={(value) => {
                                    setCurrentItem({ ...currentItem, title: value });
                                }} />
                        </div>
                    </FormItem>
                    <div className={styles.separator}></div>
                    <FormItem title={'tags'} errorMessage={''} width='95%'>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '150px', justifyContent: 'space-between', alignItems: 'center' }}>

                            <SelectList listBackgroundColor={'#EDEAE3'}
                                selectedColor={styles.darkColorMain} unselectedColor={styles.lightBackgrounds}
                                listItems={linkedTags}
                                onClick={() => {
                                    setShowTagSelect(true);
                                }}
                                clickControl={<Tag width={'32px'} fill={styles.darkColorMain} />} itemClicked={(value) => {
                                    // unselectTag(value);
                                    // console.log(value);
                                }}
                            />
                        </div>
                    </FormItem>
                    {/* <div className={styles.separatorHalf}></div> */}
                    {/* <FormItem title={''} titleColor='#253D5B' titleFontFamily='interRegular' titleFontSize='10px' width='95%' border='1px solid orange'> */}
                        <div style={{ display: 'flex', flexDirection: 'row', width: '95%', color: '#1d3046', justifyContent: 'space-between', alignItems: 'center', fontFamily: 'interRegular', fontSize: '10px' }}>
                            click on tags to ignore or reconsider adding them: only blue tags are added to the item as its tags!
                        </div>
                    {/* </FormItem> */}
                    <div className={styles.separator}></div>
                    <FormItem title={'description'} errorMessage={''}>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Textbox width={'290px'} height={'110px'} placeHolder={'description'}
                                type='multiline'
                                disabledColor={styles.disabledControlContext}
                                text={currentItem.description}
                                onChange={(value) => {
                                    setCurrentItem({ ...currentItem, description: value });
                                }} />
                        </div>
                    </FormItem>
                    <div className={styles.separator}></div>
                    <FormItem title={'details'} errorMessage={''}>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Textbox width={'290px'} height={'85px'} placeHolder={'details'}
                                type='multiline'
                                text={currentItem.details}
                                onChange={(value) => {
                                    setCurrentItem({ ...currentItem, details: value });
                                }} />
                        </div>
                    </FormItem>
                    <div className={styles.separator}></div>
                    <FormItem title={'expert'} errorMessage={''}>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Textbox width={'290px'} height={'85px'} placeHolder={'expert'}
                                type='multiline'
                                text={currentItem.expert}
                                onChange={(value) => {
                                    setCurrentItem({ ...currentItem, expert: value });
                                }} />
                        </div>
                    </FormItem>

                    <div className={styles.separator}></div>
                    <FormItem title={'photos'} errorMessage={''} width='100%'>
                        <div className={styles.images}>
                            <Button width={'64px'} height={'64px'} border={`1px dotted ${styles.darkColorMain}`}
                                margin={'10px 10px'}
                                imageSVG={<GalleryArtistic width={'32px'} fill={styles.darkColorMain} />}
                                color={'#D9D9D9'}
                                disabledColor='grey'
                                disabled={uploadingPhoto}
                                onClick={() => { itemFilePicker.current!.click() }}
                            />
                            <input
                                type="file"
                                id='itemFilePickerId'
                                name='itemFilePickerName'
                                //ToDO: add other image types support                                
                                accept='image/jpg'//, image/png, image/bmp, image/jpeg, image/webp'
                                ref={itemFilePicker}
                                style={{ display: 'none' }}
                                onChange={(event) => {
                                    if (event.target.files !== null) {
                                        addItemPhoto(event.target.files[0]);
                                    }
                                }}
                            />
                            {
                                photos.map((imageData: any, index: number) => {
                                    const photoJSON = imageData;
                                    //console.log('MAPPING photo no.(' + photoJSON.index + ',' + index + ')>>>>>>> ' + photoJSON.id);
                                    return (
                                        <div className={styles.imagePlaceholder} key={index}
                                            onClick={() => {
                                                if (photoJSON.id !== 'undefined') {
                                                    
                                                    console.log(photoJSON);
                                                    // setLargeImage(photoJSON.photo);
                                                    // setShowLargeImage(true);
                                                }
                                            }}>
                                            <img src={photoJSON.photo.replace('/photos/', '/photoThumbs/')} key={'img' + photoJSON.id} />
                                            <Button overlaySpec={photoJSON.id === 'undefined' ? 'center-center' : 'top-right'} color='transparent'
                                                border='0px solid transparent'
                                                width={photoJSON.id === 'undefined' ? '100%' : '24px'}
                                                height={photoJSON.id === 'undefined' ? '100%' : '24px'}
                                                imageWidth={photoJSON.id === 'undefined' ? '80%' : '24px'}
                                                imageHeight={photoJSON.id === 'undefined' ? '80%' : '24px'}
                                                image={photoJSON.id === 'undefined' ? require('../../../../assets/icons/alertedRemove.png') : require('../../../../assets/icons/closeRed.png')}
                                                onClick={(e) => {
                                                    if(props.itemId!){
                                                        showModal('delete photo', 'are you sure you want to delete this photo from item\'s gallery?', 
                                                            <div>
                                                                <div>to cancel: close, to delete: ok</div>
                                                            </div>
                                                            , 'ok',
                                                            async () => {
                                                                showBusy('busy', '');
                                                                e.stopPropagation();
                                                                removeItemPhoto(index);
                                                                removeExistingPhoto(currentItem.fkOwner!, photoJSON.photo);
                                                                hideBusy();
                                                            },
                                                            () => {
                    
                                                            });
                                                    }
                                                    else{
                                                        e.stopPropagation();
                                                        removeItemPhoto(index);
                                                    }
                                                }} key={'btn' + photoJSON.id} />
                                            {(photoJSON.id === null) &&
                                                <Progress opacity={0.9} />
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </FormItem>

                    <div className={styles.separator}></div>
                    <FormItem title={'price'} errorMessage={''}>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Textbox width={'241px'} height={'32px'} placeHolder={'price'} type='number'
                                text={currentItem.price?.toString()}
                                onChange={(value) => {                                    
                                    setCurrentItem({ ...currentItem, price: value });
                                }} />
                        </div>
                    </FormItem>
                    <div className={styles.separator}></div>
                    <div className={styles.separator}></div>
                    <div className={styles.button}>
                        <Button text='save'
                            textColor='#EDEAE3' fontFamily='interLight' fontSize='16px'
                            border='1px solid #ADB8BA' borderRadius='0px' color='#153147'
                            width='128px' height='38px'
                            onClick={async () => {
                                console.log('saving Owner: '  + currentItem.fkOwner);
                                console.log('saving Owner: '  + linkedTags.filter((element:any) => element.isSelected ));

                                showBusy('busy', '');
                                if (props.itemId!) {
                                    const result = await updateItem(currentItem, user!.pkUser, linkedTags.filter((element:any) => element.isSelected ));
                                    console.log('UPDATED' + result.updatedItem.pkItem);
                                    if (result.updatedItem.pkItem === props.itemId!) {
                                        hideBusy();
                                        props.onClose!();
                                    }
                                    else{
                                        showModal('item update', result.error, null, '', () => {}, () => {});
                                    }
                                }
                                else {
                                    const createdItem = await createItem(currentItem, linkedTags);
                                    console.log(createdItem);
                                    if (createdItem) {
                                        while (photos.length > 0)
                                            photos.pop();
                                        props.onClose!();
                                        hideBusy();
                                    }
                                    else{
                                        showModal('item update', createdItem.error, null, '', () => {}, () => {});
                                    }
                                }
                                hideBusy();                                
                            }}
                        />
                    </div>
                    <div className={styles.separator}></div>
                    <div className={styles.separator}></div>
                    {(user.type === 'SUPERVISOR') &&  props.itemId! &&
                        <div className={styles.listExpandControl}
                            onClick={async () => {
                                    console.log(user.type, currentItem.isConfirmed, props.itemId!);
                                    //currentItem.isConfirmed) &&
                                    showModal('delete item', 'deleting an item will remove all their enquiries and other related data, are you sure you want to continue?', 
                                        <div>
                                            <div>to cancel: close, to delete: ok</div>
                                        </div>
                                        , 'ok',
                                        async () => {
                                            showBusy('busy', '');
                                            const result = await deleteItem(props.itemId!);
                                            if (result.pkItem === props.itemId) {
                                                showModal('delete item', 'item deleting, completed successfully!', null, '', () => { }, () => { });
                                            }
                                            props.onClose!();
                                            hideBusy();
                                        },
                                        () => {

                                        });
                            }}>
                            <Delete height={'24px'} fill={'#2C3D5B'} />
                        </div>
                    }
                </div>
                <div className={styles.separator}></div>
                <div className={styles.separator}></div>
            </div>
        </Modal>
    );
}

export default ItemInfo;


import React, { FunctionComponent, createRef, useEffect, useState } from "react";
import styles from "./textbox.module.scss";
import { inherits } from "util";

interface TextBoxProps {
    fontFamily?: string;
    fontSize?: string;
    placeHolder?: string;
    textColor?: string;
    color?: string;
    borderColor?: string;
    disabledColor?: string;
    disabled?: boolean;
    readOnly?: boolean;
    width?: string;
    height?: string;
    type?: string;
    text?: string;
    validationIcon?: boolean,
    isSearchbox?: boolean;
    icon?: React.ReactNode;
    onClick?: (value: any) => void
    onChange?: (value: any) => void;
    onClearSearch?: () => void;
    onEnter?:  () => void;
}

const Textbox: FunctionComponent<TextBoxProps> = (props) => {

    // useEffect(() => {
    //     if (props.onEnter) {
    //         const textbox = document.getElementById('currentInput');
    //         console.log(textbox);
    //         textbox!.addEventListener('keyup', (event) => {
    //             console.log(event.key);
    //             if (event.key === "Enter") {
    //                 props.onEnter!();
    //             }
    //         });
    //     }
    // });
    
    return (
        <div className={styles.textBoxContainer}
            style={{
                fontFamily: props.fontFamily!, fontSize: props.fontSize!,
                width: props.width!,
                height: props.height!,
                backgroundColor: !props.disabled ? props.color! : props.disabledColor!,
                borderColor: !props.disabled ? props.borderColor! : props.disabledColor!,
            }}
            onClick={(e) => {
                if (props.onClick)
                    props.onClick(e);
            }}>
            {props.isSearchbox! &&
                <div className={styles.clearSearch} onClick={() => { props.onClearSearch!() }}></div>
            }
            {props.type !== 'multiline' ?

                <input id='currentInput' type={props.type} autoComplete="one-time-code" value={props.text!} readOnly={props.readOnly!}
                    className={props.validationIcon! ? (!props.validationIcon ? styles.valid : styles.invalid) : 'unset'}
                    style={{
                        padding: '0px 5px',
                        width: '100%', height: '100%',
                        backgroundColor: "transparent",
                        borderColor: "transparent",
                        fontFamily: props.fontFamily! ?? 'interMedium',
                        fontSize: props.fontSize! ?? '14px',
                        color: props.color?? '#15253F',
                    }}
                    placeholder={props.placeHolder!}
                    onChange={(e) => {
                        if (props.onChange)
                            props.onChange(e.target.value);
                    }}
                    onKeyUp={(e) => {
                        if (e.key === "Enter") {
                            if(props.onEnter!)
                                props.onEnter!();
                        }
                    }}
                />
                :
                <textarea autoComplete="one-time-code" value={props.text!} readOnly={props.readOnly!}
                    rows={5}
                    style={{
                        padding: '0px 5px',
                        width: '100%', height: '100%',
                        backgroundColor: "transparent",
                        borderColor: "transparent",
                        fontFamily: props.fontFamily! ?? 'interMedium',
                        fontSize: props.fontSize! ?? '14px',
                        color: props.color?? '#15253F',
                    }}
                    placeholder={props.placeHolder!}
                    onChange={(e) => {
                        if (props.onChange)
                            props.onChange(e.target.value);
                    }}
                />
            }
            {props.icon! &&
                <div className={styles.iconContainer} >
                    {props.icon}
                </div>
            }
        </div>
    );
}

export default Textbox;
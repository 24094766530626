import { FunctionComponent } from "react";
import { rejects } from "assert";
import { Settings } from "../helpers/Settings";
import { TagData } from "./tagGate";

export interface ItemData {
    pkItem?:string;
    fkCategory?:string;
    fkOwner?:string;
    fkClub?:string;    
    title?:string;
    description?:string;
    details?:string;
    expert?:string;
    price?:number;
    createdAt?:string;
    //linkedTags?:TagData[];
    isConfirmed?: boolean;
}

export const createItem = async (itemData:ItemData, linkedTags:TagData[]): Promise<any | undefined> => {
    return new Promise((resolve, reject) => {
        fetch(Settings.api.path + "/item/new", {
            method: 'POST',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React',
            }), 
            body: JSON.stringify({
                itemData,
                linkedTags
            })
        }).then((response) => {            
            response.json().then((result) => {                
                resolve(result);
            }).catch((error) => {resolve(undefined)});
        }).catch((error) => {            
            resolve(undefined);
        });
    });
}

export const updateItem = async (itemData:ItemData, editorId: string, linkedTags:TagData[]): Promise<any | undefined> => {

    console.log(`updating: ${itemData.fkOwner} ::: ${editorId}`);
    return new Promise((resolve, reject) => {
        fetch(Settings.api.path + "/item/updates", {
            method: 'PUT',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React',
            }), 
            body: JSON.stringify({
                itemData, 
                editorId,
                linkedTags
            })
        }).then((response) => {            
            response.json().then((result) => {                
                resolve(result);
            }).catch((error) => {resolve(undefined)});
        }).catch((error) => {            
            resolve(undefined);
        });
    });
}

export const listItems = async (user:any, lookupFor: string|'*', fkCategory:string|undefined, isAscending:boolean, sortField:string|undefined): Promise<any | undefined> => {
    return new Promise((resolve, reject) => {
        fetch(Settings.api.path + `/item/list/${isAscending ? (sortField ?? 'createdAt') + ':ASC' : (sortField ?? 'createdAt') + ':DESC'}`, {
            method: 'POST',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React',
            }),
            body: (JSON.stringify({
                userId: user ? user!.pkUser : undefined,
                userRole: user ? user!.type : undefined,
                userClubRate: user ? user!.clubRate : undefined,
                fkCategory,
                lookupFor
            }))
        }).then((response) => {      
            response.json().then((result) => {
                resolve(JSON.stringify(result));
            }).catch((error) => {resolve(error)});
        }).catch((error) => {
            resolve(undefined);
        });
    });
}

export const getItem = async (itemId:string): Promise<any | undefined> => {
    return new Promise((resolve, reject) => {
        fetch(Settings.api.path + "/item/details", {
            method: 'POST',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React',
            }), 
            body: JSON.stringify({
                pkItem: itemId
            })
        }).then((response) => {
            response.json().then((result) => {
                resolve(JSON.stringify(result));
            }).catch((error) => {resolve(error)});            
        }).catch((error) => {
            resolve(undefined);
        });
    });
}

export const getItemPhotos = async (ownerId:string, itemId:string): Promise<any | undefined> => {
    return new Promise((resolve, reject) => {
        fetch(Settings.api.path + "/item/photos", {
            method: 'POST',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React',
            }), 
            body: JSON.stringify({
                ownerId, 
                itemId
            })
        }).then((response) => {
            response.json().then((result) => {
                resolve(JSON.stringify(result));
            }).catch((error) => {resolve(error)});            
        }).catch((error) => {
            resolve(undefined);
        });
    });
}

export const approveItem = async (itemId:string): Promise<any | undefined> => {
    return new Promise((resolve, reject) => {
        fetch(Settings.api.path + "/item/approved", {
            method: 'PATCH',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React',
            }), 
            body: JSON.stringify({
                pkItem: itemId
            })
        }).then((response) => {            
            response.json().then((result) => {                
                resolve(result);
            }).catch((error) => {resolve(undefined)});            
        }).catch((error) => {            
            reject(undefined);
        });
    });
}

export const deleteItem = async (itemId:string): Promise<any | undefined> => {
    return new Promise((resolve, reject) => {
        fetch(Settings.api.path + "/item", {
            method: 'DELETE',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React',
            }), 
            body: JSON.stringify({
                pkItem: itemId
            })
        }).then((response) => {            
            response.json().then((result) => {                
                resolve(result);
            }).catch((error) => {resolve(undefined)});            
        }).catch((error) => {            
            reject(undefined);
        });
    });
}


//#region Item Photo Handling Methods
export const recordItemPhoto = async (
    fkUserId: string,
    photo: string,
    fileIndex:number,
    fileObject: any): Promise<any | undefined> => {

    return new Promise((resolve, reject) => {
        fetch(Settings.api.path + "/item/photo", {
            method: 'PUT',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React'
            }),
            body: JSON.stringify({
                filename: fileObject.name,
                MIMEType: fileObject.type,
                size: fileObject.size,
                base64: photo,
                fkUserId,
                fileIndex,
            })
        }).then((response) => {
            response.json().then((result) => {
                resolve(result);
            }).catch((error) => {resolve(undefined)});            
        }).catch((error) => {            
            reject(undefined);
        });
    });
}

export const removeExistingPhoto = async (ownerId:string, photoName:string): Promise<any | undefined> => {
    return new Promise((resolve, reject) => {
        fetch(Settings.api.path + "/item/existingPhoto", {
            method: 'DELETE',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React',
            }), 
            body: JSON.stringify({
                ownerId, 
                photoName
            })
        }).then((response) => {            
            response.json().then((result) => {                
                resolve(result);
            }).catch((error) => {resolve(undefined)});            
        }).catch((error) => {            
            reject(undefined);
        });
    });
}

export const removeTempItemPhoto = async (
    fkUserId: string, 
    fileIndex:number): Promise<any | undefined> => {
    
    return new Promise((resolve, reject) => { 

        fetch(Settings.api.path + "/item/photo", {
            method: 'DELETE',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React'
            }),
            body: JSON.stringify({
                fkUserId,
                fileIndex
            })
        }).then((response) => {
            response.json().then((result) => {    
                console.log(result);
                resolve(result);
            }).catch((error) => {
                resolve(undefined);
            });
        }).catch((error) => {
            reject(error);
        });
    });
}

export const removeTempItemPhotos = async (
    fkUserId: string): Promise<any | undefined> => {

    return new Promise((resolve, reject) => {
        
        fetch(Settings.api.path + "/item/photos", {
            method: 'DELETE',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React'
            }),
            body: JSON.stringify({
                fkUserId
            })
        }).then((response) => {            
            response.json().then((result) => {                
                resolve(result.result);
            }).catch((error) => {                 
                reject(undefined);
            });
        }).catch((error) => {
            reject(undefined);
        });
    });
}
//#endregion Item Photo Handling Methods
import React, { FunctionComponent, useContext, useEffect, useRef, useState } from 'react';

import styles from "./appHome.module.scss";
import { Auction, Exchange, Gallery, News, SloganBig, SloganTiny } from '../../../components/icons/icons';
import headerRugs from '../../../assets/photos/categoryLargeRugs.jpg';
import headerBooks from '../../../assets/photos/categoryLargeBooks.jpg';
import headerPaintings from '../../../assets/photos/categoryLargePaintings.jpg';
import headerItems from '../../../assets/photos/categoryLargeItems.jpg';
import headerGold from '../../../assets/photos/categoryLargeGold.jpg';
import { PopupsContext } from '../../../handlers/PopupsContext';
import { listItems } from '../../../restAPI/itemGate';
import AppItem from '../../../components/inappComponents/AppItem/appItem';
import { Settings } from '../../../helpers/Settings';
import { GlobalContext } from '../../../handlers/GlobalContext';
import { listNotices, NoticeData } from '../../../restAPI/noticeGate';
import AppItemDetails from '../appItemDetails/appItemDetails';
import NoticeInfo from '../../admin/notices/noticeInfo/noticeInfo';


interface AppHomeProperties {
    onClose?: () => void;
}

const AppHome: FunctionComponent<AppHomeProperties> = (props) => {

    const itemLength = useRef<number>(0);
    const [itemIndexes, setItemIndexes] = useState<number[]>([]);
    const [noticeIndex, setNoticeIndex] = useState<number|undefined>(undefined);
    const timerId = useRef<NodeJS.Timer>();

    const { user } = useContext(GlobalContext);
    const { updateCategory } = useContext(GlobalContext);
    const { showBusy, hideBusy, showModal, showInfoPopup } = useContext(PopupsContext);
    const [itemsList, setItemsList] = useState<any[]>();
    const [noticesList, setNoticesList] = useState<any[] | undefined>(undefined);

    const [selectedItem, setSelectedItem] = useState<string | undefined>(undefined);
    const [showDetails, setShowDetails] = useState<boolean>(false);
    const headerPhotos = [headerRugs, headerBooks, headerPaintings, headerItems, headerGold];
    const [showInfo, setShowInfo] = useState<boolean>(false);
    const [selectedNotice, setSelectedNotice] = useState<any>();
    const headerDescriptions = [
        'Persian whole natural silk carpet handmade',
        'Adam Smith’s Wealth of Nations first edition 1776',
        'The death of Caesar - Jean Leon Gerome 1867',
        'French antique Gold Plated Clock',
        'versache necklace & bracelet set, whole grade A diamonds set'
    ]
    const categoryTitles = [
        'Rug & Carpet',
        'Book & Publication',
        'Painting & Sculpture',
        'Rarities',
        'Gold & Jewelry'
    ]
    const categories = [
        '7b405bc8-682d-4ce4-a980-c331f24650ff',
        'bea1a8e0-9b33-455a-96c6-b6c81266a0b8',
        'e6c16284-021d-437e-a55b-88dc9db86725',
        'ed97d946-098b-430a-9c60-d89f5a6f83bf',
        'b1b60c6b-a7a2-4e9e-afdd-9baff5b15f81'
    ]
    const [displayingPos, setDisplayingPos] = useState<number>(0);

    useEffect(() => {

        (async () => {
            showBusy('busy', '');
            const result = await listItems(undefined, '*', undefined, false, 'createdAt');

            if (result!) {
                const items = await JSON.parse(result).itemsList;
                setItemsList(items);
                itemLength.current = items?.length!;
            }

            const resultNotices = await listNotices(undefined, '*', 'NEWSLETTER', false);
            if (resultNotices!) {
                const notices = await JSON.parse(resultNotices).noticesList;
                setNoticesList(notices);
            }
            hideBusy();
        })();

    }, [])

    useEffect(() => {
        timerId.current = setInterval(intervalActivity, 7000);
        return () => clearInterval(timerId.current);
    }, [itemsList])

    useEffect(() => {
        if (noticesList!)
            setNoticeIndex(Math.floor(Math.random() * (noticesList!.length! - 1)));
    }, [noticesList])

    const intervalActivity = () => {
        setDisplayingPos(prevPos => prevPos >= 4 ? 0 : prevPos + 1);
        const indexToShow: number[] = [];
        for (let cnt = 0; cnt < itemLength.current && cnt < (itemLength.current < 10 ? 3 : 5); cnt++) {
            const newIndex = Math.floor(Math.random() * (itemLength.current - 1));
            if (indexToShow.includes(newIndex))
                break;
            else
                indexToShow.push(newIndex);
        }
        setItemIndexes(indexToShow);
        if (noticesList!)
            setNoticeIndex(Math.floor(Math.random() * (noticesList!.length! - 1)));
    }

    return (
        <div className={styles.container}>
            {showDetails &&
                <AppItemDetails itemId={selectedItem!} onClose={() => { setShowDetails(false) }} />
            }
            {showInfo &&
                <NoticeInfo noticeType={selectedNotice!.type} noticeId={selectedNotice!.pkNotice}
                    onClose={() => {
                        setShowInfo(false);
                    }} />
            }
            <div className={styles.headerContainer}
                onClick={() => {
                    // console.log('redirecting to ' + categories[displayingPos]);
                    // updateCategory(categories[displayingPos]);
                    // document.location = '/items';
                }}>
                <div className={styles.categoryHeader}
                    style={{
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundImage: `url(${headerPhotos[displayingPos]})`
                    }}>
                    <div className={styles.categoryTitle}>
                        <div className={styles.counterInside}>
                            <div className={styles.overall}>{categoryTitles[displayingPos]}</div>
                            {/* <div className={styles.current}>0{`${displayingPos + 1}`}</div> */}
                        </div>
                    </div>
                    {/* <div className={styles.categoryCounter}>
                        <div className={styles.counterInside}>
                            <div className={styles.overall}>CAT/05</div>
                            <div className={styles.current}>0{`${displayingPos + 1}`}</div>
                        </div>
                    </div> */}
                </div>
                <div className={styles.categoryText}>
                    <div className={styles.textContainer}>
                        {`${headerDescriptions[displayingPos]}`}
                    </div>
                </div>
            </div>

            <div className={styles.contentStandard}>
                <div className={styles.contentSlogan}>
                    <div className={styles.slogan}>
                        <div className={styles.sloganBig}>
                            <SloganBig />
                        </div>
                        <div className={styles.sloganTiny}>
                            <SloganTiny />
                        </div>
                    </div>
                </div>

                <div className={styles.contentSearch}>
                    {/* <div className={styles.searchBox}>
                        <Textbox borderColor='#CA7C4E'
                            fontFamily='interRegular'
                            fontSize='14px'
                            placeHolder='Search...'
                            width='180px' height='34px' />
                        <div className={styles.splitter}></div>
                        <Button color='#D9B59D'
                            borderColor='#CA7C4E'
                            border='1px solid #CA7C4E'
                            borderRadius='3px'
                            width='34px' height='34px' image={require('../../../assets/icons/search.png')} imageHeight='24px' imageWidth='24px' />
                    </div> */}
                    <div className={styles.separator}></div>
                    {noticesList! &&
                        <div className={styles.adBox}
                            onClick={() => {
                                //document.location = '/updates'
                                setSelectedNotice(noticesList[noticeIndex?? 0]);
                                setShowInfo(true);
                            }}>
                            <div className={styles.adBoxHeader}>Crown Gold Gallery news</div>
                            <div className={styles.separatorHalf}></div>
                            <div className={styles.adBoxBody}>
                                {(noticesList.length > 0) &&
                                    <div className={styles.addBoxInside}>
                                        {noticesList[noticeIndex?? 0].title! + ' ' + noticesList[noticeIndex?? 0].description!}
                                    </div>
                                }
                                {(noticesList.length === 0) &&
                                    'no updates yet'
                                }
                            </div>
                        </div>
                    }
                    <div className={styles.links}>
                        <div className={styles.linkItem} onClick={() => {
                            document.location = '/galleries';
                        }}>
                            <Gallery height='24px' fill='#AA623C' />Galleries
                        </div>
                        <div className={styles.linkItem} onClick={() => {
                            document.location = '/exchanges';
                        }}><Exchange height='20px' fill='#AA623C' />&nbsp;Exchange</div>
                    </div>
                    <div className={styles.separatorDouble}></div>
                    {itemsList! &&
                        <div className={styles.items}>
                            {itemIndexes.map((item, index) => {
                                return (
                                    <div className={styles.homeItem} key={index}>
                                        <AppItem listType='FULL' index={0}
                                            onClick={() => {
                                                setSelectedItem(itemsList[item]!.pkItem);
                                                setShowDetails(true);
                                            }}
                                            itemWidth={64}
                                            //imageSize={96}
                                            titleFont={'interRegular'}
                                            titleColor={'#2C3D5B'}
                                            image={`${Settings.api.path}/photos/items/${itemsList[item]!.fkOwner}/${itemsList[item]!.pkItem}_0.jpg`}
                                            data={
                                                JSON.stringify({
                                                    title: itemsList[item]!.title,
                                                    description: itemsList[item]!.description,
                                                    price: itemsList[item]!.price
                                                })
                                            } />
                                    </div>);
                            })}
                        </div>
                    }
                    <div className={styles.separatorDouble}></div>
                    <div className={styles.links}>
                        <div className={styles.linkItem} onClick={() => {
                        (user === undefined) ?
                            showModal('', 'You need to login to your account, to see auctions: login!',
                                <div onClick={() => {
                                    document.location = '/login';
                                }}>go to LOGIN!</div>,
                                '', () => { }, () => { })
                        : 
                                document.location = '/updates/AUCTION';
                        }}><Auction height='28px' fill='#AA623C' />Auctions</div>
                        <div className={styles.linkItem} onClick={() => {
                            (user === undefined) ?
                            showModal('', 'You need to login to your account, to see auctions: login!',
                                <div onClick={() => {
                                    document.location = '/login';
                                }}>go to LOGIN!</div>,
                                '', () => { }, () => { })
                                :
                                document.location = '/updates/NEWSLETTER';
                        }}><News height='28px' fill='#AA623C' />&nbsp;News</div>
                    </div>
                    <div className={styles.separatorDouble}></div>
                </div>
            </div>
        </div>
    );
}

export default AppHome;
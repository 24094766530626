import React, { FunctionComponent, ReactElement, useContext, useEffect, useRef, useState } from 'react';

import styles from "./appVerification.module.scss";
import Modal from '../../../components/Modal/Modal';
import { Close, Contacts, Login, Logo, LogoDark, Search, SloganBig, SloganTiny, SocialsLinkedin, SocialsWhatsapp, SocialsYouTube } from '../../../components/icons/icons';
import Button from '../../../components/Button/Button';
// import galleryRugs from '../../assets/photos/categorySmallRugs.jpg';
// import galleryBooks from '../../assets/photos/categorySmallBooks.jpg';
// import galleryPaintings from '../../assets/photos/categorySmallPaintings.jpg';
// import galleryItems from '../../assets/photos/categorySmallItems.jpg';
// import galleryGold from '../../assets/photos/categorySmallGold.jpg';
import Textbox from '../../../components/Textbox/Textbox';
import { CategoryData, listCategories } from '../../../restAPI/categoryGate';
import { Settings } from '../../../helpers/Settings';
import { GlobalContext } from '../../../handlers/GlobalContext';
import { PopupsContext } from '../../../handlers/PopupsContext';
import { verifyUser } from '../../../restAPI/userGate';
import { useLocation, useParams } from 'react-router-dom';


interface AppVerificationProperties {

}

const AppVerification: FunctionComponent<AppVerificationProperties> = (props) => {

    const { verificationKey } = useParams();
    const {user, displayingCategory, updateCategory} = useContext(GlobalContext);
    const { showBusy, hideBusy } = useContext(PopupsContext);    
    const [result, setResult] = useState<string>('');    


    useEffect(() => {
        (async () => {
            showBusy('busy', 'please wait, while we are updating your account verification...');
            document.location = `/login/reset/${verificationKey}`;
            // const verificationResult = await verifyUser(verificationKey!);
            // if (verificationResult!) {
            //     const result = await JSON.parse(verificationResult).verifiedUser;
            //     const userId = result.pkUser;

            //     sessionStorage.setItem('resetting', userId);
            // }
            // else{
            //     const result = await JSON.parse(verificationResult).error;
            //     setResult(result);
            // }
            hideBusy();
        })()
    }, [])

    return (
        <div className={styles.container}  style={{height: '100%'}}>
            <div className={styles.userPageTitle}>
                Verification
            </div>
            <div className={styles.separator}></div>

            <div className={styles.separatorHalf}></div>
            <div className={styles.contentGalleries}>
                {result}
            </div>
            <div className={styles.separatorDouble}></div>
        </div>
    );
}

export default AppVerification;
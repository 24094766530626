import { FunctionComponent } from "react";
import { rejects } from "assert";
//import { getUserPickupCount } from "./pickup";

export interface notifications {

}

export const getNotifications = async (
    userId: string,
    userType: string): Promise<any | undefined> => {

    return new Promise(async (resolve, reject) => {
        try {
            if (userType === 'STAFF' || userType === 'SUPERVISOR' || userType === 'MEMBER') {
  //              const pickups = await getUserPickupCount(userId, userType)
                //console.log(JSON.parse(pickups).result);
                //ToDO: compose response as notifications in response 
                //resolve(JSON.stringify({'pickups': JSON.parse(pickups).result}));
                // getUserPickupCount(userId, userType).then((response) =>{
                //     response.json().then((result:any) => {
                //         console.log(' :::::: ' + result)
                //         resolve(JSON.stringify({ 'notification': result }));
                //     }).catch((error:any) => {
                //         resolve(undefined);
                //     });
                // });
            }
            else
                reject('unknown User Type!' + userType);
            // fetch(Settings.api.path + "/pickup/programDetails/" + pageSize + '/' + pageNumber, {
            //     method: 'GET',
            //     mode: 'cors',
            //     headers: new Headers({
            //         'Accept': 'application/json',
            //         'Content-Type': 'application/json',
            //         'AuthenticationToken': 'cyrus#1@Cyrus@React',
            //         'queryData': JSON.stringify({ 'programId': programId })
            //     })
            // }).then((response) => {
            //     response.json().then((result) => {
            //         resolve(JSON.stringify({ 'result': result.result }));
            //     }).catch((error) => {
            //         resolve(undefined);
            //     });
            // }).catch((error) => {                    
            //     reject(error);
            // });
        }
        catch (error) {               
            reject('Global Wrapper: ' + error);
        }
    });
}

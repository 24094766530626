class Settings {
    static api = {
      "path": process.env.NODE_ENV === 'production' ? 'https://mine.crowngoldgallery.co.uk' : 'http://emerald:8080'
    }

    static tempCategories = [   
            { 'label': 'plastics (mix.)', 'value': '0' },
        ]
}

exports.Settings = Settings;
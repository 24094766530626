import React, { FunctionComponent, ReactElement, createElement, useContext, useEffect, useRef, useState } from 'react';
import styles from "./splashScreen.module.scss";
import cgGalleryLogo from '../../assets/icons/logo.svg';

const SplashScreen = () => {

    useEffect(() => {
        (async () => {
            sessionStorage.setItem('splashed', 'true');
        })()
    }, [])

    return (
        <div className={styles.splashContainer}>
            <div></div>
            <div className={styles.mainSection}>
                <img src={cgGalleryLogo} className={styles.logo} alt='cgGallery Logo' />
                <div className={styles.title}>Crown Gold Gallery<span className={styles.crispySymbol}>©</span></div>
            </div>
            <div className={styles.company}>worth of rarities<span className={styles.crispySymbol}>®</span></div>
        </div>
    );
};

export default SplashScreen;
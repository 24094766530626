import React, { FunctionComponent, useEffect, useState } from "react";
import styles from "./button.module.scss";

interface ButtonProps {
    fontFamily?: string;
    fontSize?: string;
    text?: string;    
    textColor?: string;
    textPosition?:'imageLeft'|'imageRight';
    color?: string;
    borderColor?: string;
    disabledColor?: string;
    disabled?: boolean;
    width?: string;
    height?: string;
    hasShadow?: boolean;
    onClick?: (value: any) => void
    image?: string;
    imageWidth?: string;
    imageHeight?: string;
    imageSVG?: React.ReactNode;
    border?: string;
    borderRadius?: string;
    margin?: string;
    padding?: string;
    overlaySpec?: string;    
}

const Button: FunctionComponent<ButtonProps> = (props) => {

    const [top, setTop] = useState<string>('unset');
    const [left, setLeft] = useState<string>('unset');
    const [bottom, setBottom] = useState<string>('unset');
    const [right, setRight] = useState<string>('unset');
    const [position, setPosition] = useState<string>('unset');

    useEffect(() => {
        setPlace();
    })

    const setPlace = () => {
        if (props.overlaySpec !== undefined) {
            setPosition('absolute');
            if (props.overlaySpec.includes('top'))
                setTop('3px');
            if (props.overlaySpec.includes('-right'))
                setRight('3px');
            if (props.overlaySpec.includes('bottom'))
                setBottom('3px');
            if (props.overlaySpec.includes('-left'))
                setLeft('3px');
        }
    }

    return (
        <div className={styles.buttonContainer}
            style={{
                margin: props.margin!,
                boxShadow: props.hasShadow ? '#00000088 1px 2px 2px' : '',
                fontFamily: props.fontFamily!,
                fontSize: props.fontSize!,
                color: props.textColor ?? '',
                borderRadius: props.borderRadius!,
                width: props.width!,
                height: props.height!,
                backgroundColor: !props.disabled ? props.color! : props.disabledColor!,
                backgroundImage: !props.disabled ? props.color! : props.disabledColor!,
                borderColor: !props.disabled ? props.borderColor! : props.disabledColor!,
                border: !props.disabled ? props.border! : props.disabledColor!,
                position: props.overlaySpec !== undefined ? 'absolute' : 'unset',
                top,
                left,
                right,
                bottom,
                padding: props.padding?? 'unset',
            }}
            onClick={(e) => {
                if (!props.disabled && props.onClick)
                    props.onClick(e);
            }}>
            {
                props.image! &&
                <img src={props.image} width={props.imageWidth} height={props.imageHeight} />
            }
            <div className={styles.buttonContent}
                style={{
                    flexDirection: (props.textPosition! === 'imageLeft') ? 'row-reverse' : 'row'
                }}
                >
                {props.imageSVG!}
                {(props.imageSVG! && props.text!) &&
                    <div className={styles.splitter}></div>
                }
                {props.text!}
            </div>
            {/* {(!props.image && !props.imageSVG) &&
                props.text! &&
                (props.text?? 'button')
            } */}
        </div>
    );
}

export default Button;
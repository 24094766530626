import React, { FunctionComponent, ReactElement, useContext, useEffect, useRef, useState } from 'react';

import styles from "./appEnquiryDetails.module.scss";
import Modal from '../../../components/Modal/Modal';
import { Close, CloseModal, CloseModalDark, CloseModalGold, Collapse, Enquiry, EnquiryItem, Expand, LeftGold, Logo, RightGold, SigninHeader } from '../../../components/icons/icons';
import { PopupsContext } from '../../../handlers/PopupsContext';

interface AppEnquiryDetailsProps {
    pageFunction?: 'LOGIN' | 'SIGNUP' | 'ّFORGOTPASSWORD' | 'ّRESETPASSWORD';
    onClose?: () => void;
}

const AppEnquiryDetails: FunctionComponent<AppEnquiryDetailsProps> = (props) => {

    const { showModal, showInfoPopup } = useContext(PopupsContext);


    const [zooming, setZooming] = useState<string>('');
    const [showZoomed, setShowZoomed] = useState<boolean>(false);
    const [enquiryMode, setEnquiryMode] = useState<boolean>(false);
    const [activeFunc, setActiveFunc] = useState<string>(props.pageFunction ?? 'LOGIN');

    const enquiry = {
        'date': '2024 03 12 - 13:10',
        'description': 'whole set: necklace, bracelet, ear rings crafted on high 975 white plated gold, whole diamonds are grade A international and this item is is what you gain as a 5 part items!',
        'item': {
            'title': 'Versache diamond Set', 
            'photo': 'photos/items/itemSample.jpg'
        }
    };

    useEffect(() => {
        (async () => {

        })()
    }, [enquiryMode])

    return (
        <Modal zIndex='1000' glassEffect='CLEARGLASS'>
            <div className={styles.modalPageDarkFrame} style={{justifyContent: 'flex-start'}}>

                <div className={styles.headerContainer}>
                    <div className={styles.close} onClick={() => { props.onClose!() }}>
                        <CloseModalDark width={'32px'} />
                        <div className={styles.splitter}></div>
                        <div className={styles.splitter}></div>
                    </div>
                    <div className={styles.header}>
                        <div className={styles.splitter}></div>
                        <div className={styles.splitter}></div>
                        <Enquiry width={'64px'} fill={styles.headerBlue} />
                        Item Enquiry
                    </div>
                </div>

                <div className={styles.body}>
                    <div className={styles.date}>
                        {enquiry.date}
                    </div>
                    <div className={styles.item}>{enquiry.item.title}</div>
                    <div className={styles.message}>{enquiry.description}</div>
                    <div className={styles.preview}>
                        <div className={styles.photo}>
                            <img src={`${'./' + enquiry.item.photo}`} /></div>
                        <div className={styles.name}>{enquiry.item.title}</div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default AppEnquiryDetails;
import React, { FunctionComponent, ReactElement, useContext, useEffect, useRef, useState } from 'react';

import styles from "../../../pages/admin/exchanges/exchanges.module.scss";
//import styles from " ./appExchange.module.scss";
import { Buy, CurrencyDollar, CurrencyEuro, CurrencyPound, EnquiryItem, Exchange, Online, Sell } from '../../../components/icons/icons';
import Textbox from '../../../components/Textbox/Textbox';
import FormItem from '../../../components/FormItem/FormItem';
import { Currency, ExchangeData, listExchanges } from '../../../restAPI/exchangeGate';


interface AppExchangeProperties {
    onClose?: () => void;
}

const AppExchange: FunctionComponent<AppExchangeProperties> = (props) => {

    const [currentExchanges, setCurrentExchanges] = useState<ExchangeData[]>([
        { currency: Currency.GBP, sellIRR: 0, buyIRR: 0 },
        { currency: Currency.EUR, sellIRR: 0, buyIRR: 0 },
        { currency: Currency.USD, sellIRR: 0, buyIRR: 0 },
        { currency: Currency.IRCoinFull, sellIRR: 0, buyIRR: 0 },
        { currency: Currency.IRCoinHalf, sellIRR: 0, buyIRR: 0 },
        { currency: Currency.IRCoinQuarter, sellIRR: 0, buyIRR: 0 },
        { currency: Currency.IRCoinGram, sellIRR: 0, buyIRR: 0 }
    ]);
    const [update, setUpdate] = useState<String>('');
    

    useEffect(() => {
        (async () => {
            const exchanges = await listExchanges();
            if (exchanges) {
                const list = await JSON.parse(exchanges).exchangesList;
                if (list) {
                    setCurrentExchanges(list);
                    const updated = new Date(list[0].updatedAt);
                    setUpdate(
                        updated.toLocaleDateString('en-GB', {
                            weekday: 'long',  // Full day name (e.g., Monday)                          
                        })
                         + '     ' + 
                        updated.toLocaleTimeString('en-GB', {                          
                            hour: '2-digit',
                            minute: '2-digit'                          
                        })
                        + '     ' + 
                        updated.toLocaleDateString('en-UK', {
                            year: 'numeric',  // Full year (e.g., 2024)
                            month: 'long',    // Full month name (e.g., September)
                            day: 'numeric'    // Day of the month (e.g., 13)
                        })
                    );
                }
            }
        })()
    }, [])

    const getRate = (currency: Currency, direction: string): string => {
        if (currentExchanges!.length !== 0) {
            const gettingExchange = currentExchanges.filter((element) => element.currency === currency)[0];
            if (!gettingExchange)
                return '0';
            if (direction === 'SELL')
                return (gettingExchange.sellIRR?? 0).toLocaleString();
            if (direction === 'BUY')
                return (gettingExchange.buyIRR?? 0).toLocaleString();
        }
        return '0';
    }

    return (
        <div className={''}  style={{display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center'}}>
            <div className={styles.userPageTitle}>
                <Exchange fill={'#AA623C'} height={'32px'} />&nbsp;Exchange Rates
            </div>
            <div className={styles.container}>
                <div className={styles.contentItems}>
                    <div className={styles.dateContainer}>
                        {update}
                    </div>
                    <div className={styles.separator}></div>
                    <div className={styles.separatorHalf}></div>
                    <div className={styles.currency + ' ' + styles.bgPound}>
                        <div className={styles.sellContainer}>
                            <div className={styles.icon}>
                                <CurrencyPound />
                            </div>
                            <div className={styles.data}>
                                <FormItem title={'Tomans'} titleColor='#253D5B' titleFontFamily='interRegular' titleFontSize='10px' errorMessage={''} width='90%'>
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Textbox width={'100%'} height={'34px'} borderColor='#EDEAE3'
                                            placeHolder={'IRR'} fontFamily='interRegular' fontSize='14px'
                                            readOnly
                                            text={getRate(Currency.GBP, 'SELL')}
                                            onChange={() => { }} icon={<Sell />} />
                                    </div>
                                </FormItem>
                                <div className={styles.label}>sell</div>
                            </div>
                        </div>
                        <div className={styles.buyContainer}>
                            <div className={styles.icon}>
                                <CurrencyPound />
                            </div>
                            <div className={styles.data}>
                                <FormItem title={'Tomans'} titleColor='#253D5B' titleFontFamily='interRegular' titleFontSize='10px' errorMessage={''} width='90%'>
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Textbox width={'100%'} height={'34px'} borderColor='#EDEAE3'
                                            placeHolder={'IRR'} fontFamily='interRegular' fontSize='14px'
                                            readOnly
                                            text={getRate(Currency.GBP, 'BUY')}
                                            onChange={() => { }} icon={<Buy />} />
                                    </div>
                                </FormItem>
                                <div className={styles.label}>buy</div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.separator}></div>
                    <div className={styles.separator}></div>

                    <div className={styles.currency + ' ' + styles.bgPoundCash}>
                        <div className={styles.sellContainer}>
                            <div className={styles.icon}>
                                <CurrencyPound />
                                {/* <Online height='32px' className={styles.animateIcon} /> */}
                            </div>
                            <div className={styles.data}>
                                <FormItem title={'Tomans'} titleColor='#253D5B' titleFontFamily='interRegular' titleFontSize='10px' errorMessage={''} width='90%'>
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Textbox width={'100%'} height={'34px'} borderColor='#EDEAE3'
                                            placeHolder={'IRR'} fontFamily='interRegular' fontSize='14px'
                                            readOnly
                                            text={getRate(Currency.GBPo, 'SELL')}
                                            onChange={() => { }} icon={<Sell />} />
                                    </div>
                                </FormItem>
                                <div className={styles.label}>sell</div>
                            </div>
                        </div>
                        <div className={styles.buyContainer}>
                            <div className={styles.icon}>
                                <CurrencyPound />
                                {/* <Online height='32px' className={styles.animateIcon} /> */}
                            </div>
                            <div className={styles.data}>
                                <FormItem title={'Tomans'} titleColor='#253D5B' titleFontFamily='interRegular' titleFontSize='10px' errorMessage={''} width='90%'>
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Textbox width={'100%'} height={'34px'} borderColor='#EDEAE3'
                                            placeHolder={'IRR'} fontFamily='interRegular' fontSize='14px'
                                            readOnly
                                            text={getRate(Currency.GBPo, 'BUY')}
                                            onChange={() => { }} icon={<Buy />} />
                                    </div>
                                </FormItem>
                                <div className={styles.label}>buy</div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.separator}></div>
                    <div className={styles.dateContainer}>
                        {update}
                    </div>
                    <div className={styles.separator}></div>
                    <div className={styles.currency + ' ' + styles.bgEuro}>
                        <div className={styles.sellContainer}>
                            <div className={styles.icon}>
                                <CurrencyEuro />
                            </div>
                            <div className={styles.data}>
                                <FormItem title={'Tomans'} titleColor='#253D5B' titleFontFamily='interRegular' titleFontSize='10px' errorMessage={''} width='90%'>
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Textbox width={'100%'} height={'34px'} borderColor='#EDEAE3'
                                            placeHolder={'IRR'} fontFamily='interRegular' fontSize='14px'
                                            readOnly
                                            text={getRate(Currency.EUR, 'SELL')}
                                            onChange={() => { }} icon={<Sell />} />
                                    </div>
                                </FormItem>
                                <div className={styles.label}>sell</div>
                            </div>
                        </div>
                        <div className={styles.buyContainer}>
                            <div className={styles.icon}>
                                <CurrencyEuro />
                            </div>
                            <div className={styles.data}>
                                <FormItem title={'Tomans'} titleColor='#253D5B' titleFontFamily='interRegular' titleFontSize='10px' errorMessage={''} width='90%'>
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Textbox width={'100%'} height={'34px'} borderColor='#EDEAE3'
                                            placeHolder={'IRR'} fontFamily='interRegular' fontSize='14px'
                                            readOnly
                                            text={getRate(Currency.EUR, 'BUY')}
                                            onChange={() => { }} icon={<Buy />} />
                                    </div>
                                </FormItem>
                                <div className={styles.label}>buy</div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.separator}></div>
                    <div className={styles.separator}></div>

                    <div className={styles.currency + ' ' + styles.bgDollar}>
                        <div className={styles.sellContainer}>
                            <div className={styles.icon}>
                                <CurrencyDollar />
                            </div>
                            <div className={styles.data}>
                                <FormItem title={'Tomans'} titleColor='#253D5B' titleFontFamily='interRegular' titleFontSize='10px' errorMessage={''} width='90%'>
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Textbox width={'100%'} height={'34px'} borderColor='#EDEAE3'
                                            placeHolder={'IRR'} fontFamily='interRegular' fontSize='14px'
                                            readOnly
                                            text={getRate(Currency.USD, 'SELL')}
                                            onChange={() => { }} icon={<Sell />} />
                                    </div>
                                </FormItem>
                                <div className={styles.label}>sell</div>
                            </div>
                        </div>
                        <div className={styles.buyContainer}>
                            <div className={styles.icon}>
                                <CurrencyDollar />
                            </div>
                            <div className={styles.data}>
                                <FormItem title={'Tomans'} titleColor='#253D5B' titleFontFamily='interRegular' titleFontSize='10px' errorMessage={''} width='90%'>
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Textbox width={'100%'} height={'34px'} borderColor='#EDEAE3'
                                            placeHolder={'IRR'} fontFamily='interRegular' fontSize='14px'
                                            readOnly
                                            text={getRate(Currency.USD, 'BUY')}
                                            onChange={() => { }} icon={<Buy />} />
                                    </div>
                                </FormItem>
                                <div className={styles.label}>buy</div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.separator}></div>
                    <div className={styles.dateContainer}>
                        {update}
                    </div>
                    <div className={styles.separator}></div>

                    <div className={styles.currency + ' ' + styles.bgCoinFull}>
                        <div className={styles.sellContainer}>
                            <div className={styles.data}>
                                <FormItem title={'Tomans'} titleColor='#FFFFFF' titleFontFamily='interRegular' titleFontSize='10px' errorMessage={''} width='90%'>
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Textbox width={'100%'} height={'34px'} borderColor='#EDEAE3'
                                            placeHolder={'IRR'} fontFamily='interRegular' fontSize='14px'
                                            readOnly
                                            text={getRate(Currency.IRCoinFull, 'SELL')}
                                            onChange={() => { }} icon={<Sell />} />
                                    </div>
                                </FormItem>
                                <div className={styles.label}>sell</div>
                            </div>
                        </div>
                        <div className={styles.buyContainer}>
                            <div className={styles.data}>
                                <FormItem title={'Tomans'} titleColor='#FFFFFF' titleFontFamily='interRegular' titleFontSize='10px' errorMessage={''} width='90%'>
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Textbox width={'100%'} height={'34px'} borderColor='#EDEAE3'
                                            placeHolder={'IRR'} fontFamily='interRegular' fontSize='14px'
                                            readOnly
                                            text={getRate(Currency.IRCoinFull, 'BUY')}
                                            onChange={() => { }} icon={<Buy />} />
                                    </div>
                                </FormItem>
                                <div className={styles.label}>buy</div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.separator}></div>
                    <div className={styles.separator}></div>

                    <div className={styles.currency + ' ' + styles.bgCoinHalf}>
                        <div className={styles.sellContainer}>
                            <div className={styles.data}>
                                <FormItem title={'Tomans'} titleColor='#FFFFFF' titleFontFamily='interRegular' titleFontSize='10px' errorMessage={''} width='90%'>
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Textbox width={'100%'} height={'34px'} borderColor='#EDEAE3'
                                            placeHolder={'IRR'} fontFamily='interRegular' fontSize='14px'
                                            readOnly
                                            text={getRate(Currency.IRCoinHalf, 'SELL')}
                                            onChange={() => { }} icon={<Sell />} />
                                    </div>
                                </FormItem>
                                <div className={styles.label}>sell</div>
                            </div>
                        </div>
                        <div className={styles.buyContainer}>
                            <div className={styles.data}>
                                <FormItem title={'Tomans'} titleColor='#FFFFFF' titleFontFamily='interRegular' titleFontSize='10px' errorMessage={''} width='90%'>
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Textbox width={'100%'} height={'34px'} borderColor='#EDEAE3'
                                            placeHolder={'IRR'} fontFamily='interRegular' fontSize='14px'
                                            readOnly
                                            text={getRate(Currency.IRCoinHalf, 'BUY')}
                                            onChange={() => { }} icon={<Buy />} />
                                    </div>
                                </FormItem>
                                <div className={styles.label}>buy</div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.separator}></div>
                                        <div className={styles.dateContainer}>
                        {update}
                    </div>
                    <div className={styles.separator}></div>

                    <div className={styles.currency + ' ' + styles.bgCoinQuarter}>
                        <div className={styles.sellContainer}>
                            <div className={styles.data}>
                                <FormItem title={'Tomans'} titleColor='#FFFFFF' titleFontFamily='interRegular' titleFontSize='10px' errorMessage={''} width='90%'>
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Textbox width={'100%'} height={'34px'} borderColor='#EDEAE3'
                                            placeHolder={'IRR'} fontFamily='interRegular' fontSize='14px'
                                            readOnly
                                            text={getRate(Currency.IRCoinQuarter, 'SELL')}
                                            onChange={() => { }} icon={<Sell />} />
                                    </div>
                                </FormItem>
                                <div className={styles.label}>sell</div>
                            </div>
                        </div>
                        <div className={styles.buyContainer}>
                            <div className={styles.data}>
                                <FormItem title={'Tomans'} titleColor='#FFFFFF' titleFontFamily='interRegular' titleFontSize='10px' errorMessage={''} width='90%'>
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Textbox width={'100%'} height={'34px'} borderColor='#EDEAE3'
                                            placeHolder={'IRR'} fontFamily='interRegular' fontSize='14px'
                                            readOnly
                                            text={getRate(Currency.IRCoinQuarter, 'BUY')}
                                            onChange={() => { }} icon={<Buy />} />
                                    </div>
                                </FormItem>
                                <div className={styles.label}>buy</div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.separator}></div>
                    <div className={styles.separator}></div>

                    <div className={styles.currency + ' ' + styles.bgCoinGrams}>
                        <div className={styles.sellContainer}>
                            <div className={styles.data}>
                                <FormItem title={'Pound'} titleColor='#FFFFFF' titleFontFamily='interRegular' titleFontSize='10px' errorMessage={''} width='90%'>
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Textbox width={'100%'} height={'34px'} borderColor='#EDEAE3'
                                            placeHolder={'IRR'} fontFamily='interRegular' fontSize='14px'
                                            readOnly
                                            text={getRate(Currency.IRCoinGram, 'SELL')}
                                            onChange={() => { }} icon={<Sell />} />
                                    </div>
                                </FormItem>
                                <div className={styles.label}>sell</div>
                            </div>
                        </div>
                        <div className={styles.buyContainer}>
                            <div className={styles.data}>
                                <FormItem title={'Pound'} titleColor='#FFFFFF' titleFontFamily='interRegular' titleFontSize='10px' errorMessage={''} width='90%'>
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Textbox width={'100%'} height={'34px'} borderColor='#EDEAE3'
                                            placeHolder={'IRR'} fontFamily='interRegular' fontSize='14px'
                                            readOnly
                                            text={getRate(Currency.IRCoinGram, 'BUY')}
                                            onChange={() => { }} icon={<Buy />} />
                                    </div>
                                </FormItem>
                                <div className={styles.label}>buy</div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.separator}></div>
                    <div className={styles.separator}></div>
                </div>
            </div>
        </div>
    );
}

export default AppExchange;
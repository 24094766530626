import { FunctionComponent } from "react";
import { rejects } from "assert";
import { Settings } from "../helpers/Settings";
//import { getUserPickupCount } from "./pickup";

export interface ClubData {
    pkClub?:string;
    name?:string;
    rate?:number;
}

export const listClubs = async (): Promise<any | undefined> => {
    return new Promise((resolve, reject) => {
        fetch(Settings.api.path + "/club/list", {
            method: 'GET',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': 'cyrus#1@Cyrus@React',
            }), 
        }).then((response) => {            
            response.json().then((result) => {
                resolve(JSON.stringify(result));
            }).catch((error) => {resolve(error)});
        }).catch((error) => {
            resolve(undefined);
        });
    });
}
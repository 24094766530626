import React, { forwardRef, FunctionComponent, ReactElement, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';

import styles from "./tags.module.scss";
import { Approve, Delete, Down, EnquiryItem, SortASC, SortDESC, Up } from '../../../components/icons/icons';
import Button from '../../../components/Button/Button';
import Textbox from '../../../components/Textbox/Textbox';
import ListItem from '../../../components/ListItem/ListItem';
import List from '../../../components/List/List';
import { listTags, TagData, usageCount } from '../../../restAPI/tagGate';
import TagInfo from './tagInfo/tagInfo';
import { PopupsContext } from '../../../handlers/PopupsContext';


interface TagsProperties {
    onClose?: () => void;    
}

export interface TagCommunicators{
    onRefresh: () => void;
}

//const Tags: FunctionComponent<TagsProperties> = forwardRef<TagCommunicators, TagsProperties>((props, ref) => {
const Tags = forwardRef<TagCommunicators, TagsProperties>((props, ref) => {

    const { showBusy, hideBusy } = useContext(PopupsContext);

    const usages:{tagId:string, count:number}[] = [];
    const [tagsList, setTagsList] = useState<TagData[]>([]);
    const [selectedTag, setSelectedTag] = useState<TagData>();
    const [showInfo, setShowInfo] = useState<boolean>(false);

    const [isAscending, setIsAscending] = useState<boolean>(true);
    const [lookupTag, setLookupTag] = useState<string>('*');

    // Expose methods using useImperativeHandle
    useImperativeHandle(ref, () => ({
        onRefresh() {
            loadTags();
        },
    }));

    useEffect(() => {
        (() => {
            if (lookupTag === '*')
                loadTags();
        })()
    }, [lookupTag])

    useEffect(() => {
    }, [])

    useEffect(() => {        
        (() => {
            const temp = tagsList.sort((firstItem: TagData, nextItem: TagData) =>
                firstItem.title! < nextItem.title! ?
                    (isAscending ? -1 : 1)
                    :
                    (isAscending ? 1 : -1)
            ) 
            setTagsList([...temp]);
        })()
    }, [isAscending])

    const loadTags = async () => {
        showBusy('busy', '');
        setTagsList([]);
        const result = await listTags(lookupTag, isAscending);
        if (result!) {
            const tags = await JSON.parse(result).tagsList;
            setTagsList(tags);
            console.log(tags);
        }
        hideBusy();
    }

    // const usage = async (tagId: string, index:number) => {
    //     const usage = await usageCount(tagId);
    //     if(usage!)
    //         usages.push({tagId, count: usage.used?? 0});        
    // }

    return (
        <div className={styles.container}>
            {showInfo &&
                <TagInfo tagId={selectedTag?.pkTag} tagTitle={selectedTag?.title} 
                    onClose={() => { 
                        setShowInfo(false);
                        loadTags(); 
                    }} />
            }
            <div className={styles.separator}></div>
            <div className={styles.userTopControls}>
                <div className={styles.searchBox}>
                    <Textbox borderColor='#CA7C4E'
                        fontFamily='interRegular'
                        fontSize='14px'
                        placeHolder='Search...'
                        width='80%' height='34px'
                        isSearchbox
                        text={lookupTag === '*' ? '' : lookupTag}
                        onClearSearch={ () => {
                            setLookupTag('*');
                        }}
                        onChange={(value) => {
                            if (value.trim() !== '')
                                setLookupTag(value);
                            else {
                                setLookupTag('*');
                            }
                        }} />
                    <div className={styles.splitter}></div>
                    <Button color='#D9B59D'
                        borderColor='#CA7C4E'
                        border='1px solid #CA7C4E'
                        borderRadius='3px'
                        width='34px' height='34px' image={require('../../../assets/icons/search.png')} imageHeight='24px' imageWidth='24px'
                        onClick={() => {
                            loadTags();
                        }}
                    />
                </div>
                <div
                    onClick={() => {
                        setIsAscending(!isAscending);
                    }}>
                    {!isAscending ? <SortASC width='32px' /> : <SortDESC width='32px' />}
                </div>
            </div>
            <div className={styles.separatorHalf}></div>
            <div className={styles.contentItems}>
                <List>
                    {
                        tagsList.map((item, index) => {
                            return (
                                <ListItem index={index} key={item.pkTag}
                                    onClick={() => {
                                        setSelectedTag(item);
                                        setShowInfo(true);
                                    }}
                                    data={JSON.stringify({
                                        title: item.title,
                                    })}>
                                    <div className={styles.listExpandControl} >
                                        <div>{
                                            item.used?? '0'
                                        }</div>
                                    </div>
                                </ListItem>
                            );
                        })
                    }
                </List>
            </div>

        </div>
    );
});

export default Tags;
import React, { forwardRef, FunctionComponent, ReactElement, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';

import styles from "./notices.module.scss";
import { Approve, Auction, Delete, Down, EnquiryItem, News, SortASC, SortDESC, Up } from '../../../components/icons/icons';
import Button from '../../../components/Button/Button';
import Textbox from '../../../components/Textbox/Textbox';
import ListItem from '../../../components/ListItem/ListItem';
import List from '../../../components/List/List';
import ToggleButton from '../../../components/ToggleButton/ToggleButton';
import { approveNotice, deleteNotice, listNotices } from '../../../restAPI/noticeGate';
import { PopupsContext } from '../../../handlers/PopupsContext';
import ListItemEmpty from '../../../components/ListItemEmpty/ListItemEmpty';
import NoticeInfo from './noticeInfo/noticeInfo';
import { GlobalContext } from '../../../handlers/GlobalContext';
import { useParams } from 'react-router-dom';


interface NoticesProperties {
    noticeType: 'NEWSLETTER' | 'AUCTION' | 'ALL';
    onClose?: () => void;
}

export interface NoticeCommunicators{
    onRefresh: () => void;
}

const Notices = forwardRef<NoticeCommunicators, NoticesProperties>((props, ref) => {

    const { user } = useContext(GlobalContext);
    const [showInfo, setShowInfo] = useState<boolean>(false);
    const [selectedNotice, setSelectedNotice] = useState<string>('');
    const [lookupNotice, setLookupNotice] = useState<string>('*');
    const [isAscending, setIsAscending] = useState<boolean>(false);
    const [noticesList, setNoticesList] = useState<any[]>([]);

    const { showBusy, hideBusy } = useContext(PopupsContext);

    // Expose methods using useImperativeHandle
    useImperativeHandle(ref, () => ({
        onRefresh() {
            loadNotices();
        },
    }));

    useEffect(() => {
        (() => {            
            if(lookupNotice === '*')
                loadNotices();
        })()
    }, [user, isAscending, lookupNotice, selectedNotice])
    
    useEffect(() => {
    }, [selectedNotice, noticesList])
    
    const loadNotices = async () => {
        showBusy('busy', '');           
        setNoticesList([]);
        console.log(user, lookupNotice, props.noticeType);
        const result = await listNotices(user, lookupNotice, props.noticeType, isAscending,);
        if (result !== undefined) {
            const notices = await JSON.parse(result).noticesList;
            setNoticesList(notices);
        }
        hideBusy();
    }

    return (
        <div className={styles.container}>
            {showInfo &&
                <NoticeInfo noticeType={props.noticeType} noticeId={selectedNotice} 
                    onClose={() => { 
                        loadNotices();
                        setShowInfo(false); 
                    }} />
            }
            <div className={styles.separator}></div>
            <div className={styles.userTopControls}>
                <div className={styles.searchBox}>
                    <Textbox borderColor='#CA7C4E'
                        fontFamily='interRegular'
                        fontSize='14px'
                        placeHolder='Search...'
                        width='80%' height='34px'
                        isSearchbox
                        text={lookupNotice === '*' ? '' : lookupNotice}
                        onClearSearch={ () => {
                            setLookupNotice('*');
                        }}
                        onChange={(value) => {
                            if(value.trim() !== '')
                                setLookupNotice(value);
                            else{
                                setLookupNotice('*');
                            }
                        }} />
                    <div className={styles.splitter}></div>
                    <Button color='#D9B59D'
                        borderColor='#CA7C4E'
                        border='1px solid #CA7C4E'
                        borderRadius='3px'
                        width='34px' height='34px' 
                        image={require('../../../assets/icons/search.png')} imageHeight='24px' imageWidth='24px' 
                        onClick={() => {
                            loadNotices();
                        }}
                        />
                </div>
                <div
                    onClick={() => {
                        setIsAscending(!isAscending);
                    }}>
                    {isAscending ? <SortASC width='32px' /> : <SortDESC width='32px' />}
                </div>
            </div>
            <div className={styles.separatorHalf}></div>
            <div className={styles.contentItems}>
                <List>
                    {noticesList.length === 0 &&
                        <ListItemEmpty text={`no${props.noticeType === 'NEWSLETTER' ? ' news letters' : props.noticeType === 'AUCTION' ? ' auctions' : 'thing'} to show!`} />
                    }
                    {noticesList.length > 0 &&

                        noticesList.map((item, index) => {
                            let date = ((new Date(item.createdAt)).toLocaleDateString('en-US', {
                                weekday: 'short',  // Full day name (e.g., Monday)
                                year: 'numeric',  // Full year (e.g., 2024)
                                month: 'short',    // Full month name (e.g., September)
                                day: 'numeric'    // Day of the month (e.g., 13)
                            }) + ' ' + item.createdAt.split('T')[1].substring(0,5));
                            if (!item.isConfirmed)
                                return (
                                    <ListItem index={index} key={index}
                                        onClick={() => {
                                            setSelectedNotice(item.pkNotice);
                                            setShowInfo(true);
                                        }}
                                        expandable={JSON.parse(item.isNew)}
                                        expandIcon={<Down width={'32px'} fill={'#ADB8BA'} />}
                                        shrinkIcon={<Up width={'32px'} fill='#ADB8BA' />}
                                        data={JSON.stringify({
                                            title: item.title,
                                            details: item.description,
                                            extra: date//item.createdAt.split('T')[0].substring(2).replaceAll('-', '/') + ' ' + item.createdAt.split('T')[1].substring(0, 5)
                                        })}>
                                        <div className={styles.listExpandControl} onClick={async () => {
                                            showBusy('busy', '');
                                            const result = await deleteNotice(item.pkNotice);
                                            if (result.pkNotice === item.pkNotice) {
                                                const temp = { ...noticesList.filter((element) => element.pkNotice !== item.pkNotice) };
                                                setNoticesList(temp);
                                                setSelectedNotice('1');
                                            }
                                            hideBusy();
                                        }}>
                                            <Delete height={'24px'} fill={'#A8623E'} />
                                            <div className={styles.text}>
                                                delete
                                            </div>
                                        </div>
                                        <div className={styles.splitter}></div>
                                        <div className={styles.splitter}></div>
                                        {(user.type === 'SUPERVISOR') &&
                                            <div className={styles.listExpandControl} onClick={async () => {
                                                showBusy('busy', '');
                                                const result = await approveNotice(item.pkNotice);
                                                if(result!){

                                                }
                                                setSelectedNotice(item.pkNotice);
                                                hideBusy();
                                            }}>
                                                <Approve height={'24px'} fill={'#A8623E'} />
                                                <div className={styles.text}>
                                                    approve
                                                </div>
                                            </div>
                                        }
                                    </ListItem>
                                );
                            else
                                return (
                                    <ListItem index={index} key={index}
                                        onClick={() => {
                                            setSelectedNotice(item.pkNotice);
                                            setShowInfo(true);
                                        }}
                                        imageComponent={props.noticeType === 'ALL' ? (item.type === 'AUCTION' ? <Auction fill='#C9A959'/> : <News fill='#D9B59D'/>) : ''}
                                        //imageFrame='CIRCLE'
                                        imageSize={64}
                                        // expandable
                                        // expandIcon={<Down width={'32px'} fill={'#ADB8BA'} />}
                                        // shrinkIcon={<Up width={'32px'} fill='#ADB8BA' />}
                                        data={JSON.stringify({
                                            title: item.title,
                                            details: item.description,
                                            extra: date//item.createdAt.split('T')[0].substring(2).replaceAll('-', '/') + ' ' + item.createdAt.split('T')[1].substring(0, 5)
                                        })}>
                                    </ListItem>
                                );
                        })
                    }
                </List>
            </div>

        </div>
    );
});

export default Notices;
import React, { FunctionComponent, useEffect, useState } from "react";
import styles from "./toggleButton.module.scss";

interface ToggleButtonProps {
    isOn?: boolean;
    offText?: string;
    onText?: string;
    displayTexts?: 'wings' | 'left' | 'right' | 'bottom' | 'top';
    selectedTextColor?: string,
    deselectedTextColor?: string,
    fontFamily?: string
    fontSize?: string;
    frameColor?: string;
    buttonColor?: string;
    switchedOffColor?: string;
    switchedOnColor?: string;
    height?: number;
    image?: string;
    imageHeight?: string;
    imageWidth?: string;
    borderThickness?: string;
    onClick?: (value: boolean) => void
}

const ToggleButton: FunctionComponent<ToggleButtonProps> = (props) => {

    const [toggleOn, setToggleOn] = useState<boolean>(false);
    const [offText, setOffText] = useState(props.offText ?? undefined);
    const [onText, setOnText] = useState(props.onText ?? undefined);
    const [toggleDirection, setToggleDirection] = useState<'row' | 'column' | 'column-reverse'>('row');

    useEffect(() => {
        setToggleOn(props.isOn?? false);
    }, [])

    useEffect(() => {

        if (props.displayTexts === 'left') {
            setOffText(toggleOn ? props.offText : props.onText);
            setOnText(undefined);
        }
        if (props.displayTexts === 'right') {
            setOnText(toggleOn ? props.offText : props.onText);
            setOffText(undefined);
        }
        if (props.displayTexts === 'wings') {
            setOnText(props.onText);
            setOffText(props.offText);
        }
        if (props.displayTexts === 'bottom') {
            setToggleDirection('column');
            setOnText(props.onText);
            setOffText(props.offText);
        }
        if (props.displayTexts === 'top') {
            setToggleDirection('column-reverse');
            setOnText(props.onText);
            setOffText(props.offText);
        }
    }, [toggleOn])

    return (
        <div className={styles.toggleContainer} style={{ fontFamily: props.fontFamily!, flexDirection: `${toggleDirection}` }}>
            {(props.displayTexts !== 'top' && props.displayTexts !== 'bottom') &&
                <div className={styles.toggleText}
                    style={{
                        fontSize: props.fontSize, fontFamily: props.fontFamily!,
                        color: toggleOn ? props.selectedTextColor ?? "darkgrey" : props.deselectedTextColor ?? "darkgrey",
                        padding: offText! ? '7px' : '0px'
                    }}>
                    {offText}
                </div>
            }
            <div className={styles.toggleFrame}
                style={{
                    borderColor: props.frameColor ?? "darkgray",
                    backgroundColor: toggleOn ? props.switchedOffColor ?? "lightgrey" : props.switchedOnColor ?? "whitesmoke",
                    height: props.height ?? "50px",
                    width: props.height ? (props.height * 2) : "100px",
                    borderRadius: props.height ?? "50px",
                    borderWidth: props.borderThickness ?? "3px",
                }}
                onClick={(e) => {
                    setToggleOn(!toggleOn);
                    if (props.onClick)
                        props.onClick(toggleOn);
                }}>                    
                <div className={styles.toggleCircle} 
                    style={{ 
                        height: props.height ?? "50px", 
                        width: props.height ?? "50px", 
                        left: toggleOn ? "0px" : "", 
                        right: !toggleOn ? "0px" : "", 
                        borderColor: props.buttonColor ? props.buttonColor : 'grey', 
                        backgroundColor: props.buttonColor ? props.buttonColor : 'grey' }}>
                    {props.image && <img src={props.image} width={props.imageWidth} height={props.imageHeight} />}
                </div>
            </div>
            {(props.displayTexts !== 'top' && props.displayTexts !== 'bottom') &&
                <div className={styles.toggleText}
                    style={{
                        fontSize: props.fontSize,
                        color: !toggleOn ? props.selectedTextColor ?? "darkgrey" : props.deselectedTextColor ?? "darkgrey",
                        padding: toggleDirection === 'row' ? (onText! ? '7px' : '0px') : '0px'
                    }}>
                    {onText}
                </div>
            }
            {(props.displayTexts === 'top' || props.displayTexts === 'bottom') &&
                <div className={styles.toggleText}
                    style={{
                        fontSize: props.fontSize,
                        color: !toggleOn ? props.selectedTextColor ?? "darkgrey" : props.deselectedTextColor ?? "darkgrey",
                        padding: toggleDirection === 'row' ? (onText! ? '0px' : '0px') : '0px'
                    }}>
                    {toggleOn ? onText : offText}
                </div>
            }
        </div>
    );
}

export default ToggleButton;
import React, { FunctionComponent, ReactElement, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';

import styles from "./userInfo.module.scss";
import Modal from '../../../../components/Modal/Modal';
import { CloseModal, Delete, Email, SigninHeader, Staff, Whatsapp } from '../../../../components/icons/icons';
import FormItem from '../../../../components/FormItem/FormItem';
import Textbox from '../../../../components/Textbox/Textbox';
import Button from '../../../../components/Button/Button';
import { PopupsContext } from '../../../../handlers/PopupsContext';
import ToggleButton from '../../../../components/ToggleButton/ToggleButton';
import Dropdown from '../../../../components/DropDown/Dropdown';
import { getUser, saveUser, createUser, UserData, enableUser, approveUser, checkDuplicates, deleteUser } from '../../../../restAPI/userGate';
import { listClubs } from '../../../../restAPI/clubGate';
import { Settings } from '../../../../helpers/Settings';
import { GlobalContext } from '../../../../handlers/GlobalContext';

interface UserInfoProperties {
    userId?: string | undefined;
    userType: 'MEMBER' | 'STAFF' | 'SUPERVISOR';

    onClose?: () => void;
}

const UserInfo: FunctionComponent<UserInfoProperties> = (props) => {

    const regexMail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    const regexCell = /^(\+|00)[1-9][0-9]{7,32}$/;
    const { user } = useContext(GlobalContext);
    const { showModal, showInfoPopup, showBusy, hideBusy } = useContext(PopupsContext);
    const [currentUser, setCurrentUser] = useState<UserData>({ pkUser: props.userId!, type: props.userType });
    const [creation, setCreation] = useState<string>('');
    const [isValidMail, setIsValidMail] = useState<boolean>(true);
    const [isValidPhone, setIsValidPhone] = useState<boolean>(true);
    const [existingEmail, setExistingEmail] = useState<boolean>(false);
    const [existingCell, setExistingCell] = useState<boolean>(false);
    const [passwordRetype, setPasswordRetype] = useState<string>();
    const [clubsList, setClubsList] = useState<[]>([]);

    useEffect(() => { 
        (async () => {
            showBusy('busy', '');
            if (props.userType === 'MEMBER') {
                const clubs = await listClubs();
                if (clubs!)
                    setClubsList(JSON.parse(JSON.stringify(JSON.parse(clubs).clubsList.sort((a: any, b: any) => { return (a.rate > b.rate ? 1 : -1) })).replaceAll('pkClub', 'value').replaceAll('name', 'label')));
            }
            if (props.userId!) {
                const result = await getUser(props.userId);
                if (result !== undefined) {
                    const userData = await JSON.parse(result);
                    if (userData !== undefined) {
                        setCurrentUser({
                            pkUser: userData.pkUser,
                            fkClub: userData.fkClub,
                            name: userData.name,
                            surname: userData.surname,
                            username: userData.username,
                            birthday: userData.birthday,
                            email: userData.email,
                            phone: userData.phone,
                            address: userData.address,
                            postalCode: userData.postalCode,
                            isActive: userData.isActive,
                            isNew: userData.isNew,
                            type: props.userType,
                            createdAt: userData.createdAt
                        });
                    }
                }
            }
            hideBusy();
        })()
    }, [])

    useEffect(() => {
        (async () => {

        })()
    }, [])

    return (
        <Modal zIndex='1000' glassEffect='CLEARGLASS'>
            <div className={styles.modalPage}>
                <div className={styles.headerContainer}>
                    <div className={styles.close} onClick={() => { props.onClose!() }}>
                        <CloseModal width={'32px'} />
                        <div className={styles.splitter}></div>
                        <div className={styles.splitter}></div>
                    </div>
                    <div className={props.userId! ? styles.headerEx : styles.header}>
                        <div className={styles.header}>
                            <div className={styles.splitter}></div>
                            <div className={styles.splitter}></div>
                            {props.userType === 'STAFF' ? <Staff width={'64px'} fill={'#2C3D5B'} /> : <SigninHeader width={'64px'} fill={'#2C3D5B'} />}
                            <div className={styles.splitter}></div>
                            <div className={styles.text}>
                                <div className={styles.title}>{props.userId! ? 'edit' : 'new'} {props.userType === 'STAFF' ? 'staff ' : 'member '}</div>
                                <div className={styles.data}>{currentUser.createdAt ? (currentUser.createdAt.split('T')[0].substring(2).replaceAll('-', '/') + ' ' + currentUser.createdAt.split('T')[1].substring(0, 5)) : ''}</div>
                            </div>
                        </div>
                        <div className={styles.expansion}>
                            <div className={styles.splitter}></div>
                            <div className={styles.splitter}></div>
                        </div>
                    </div>
                </div>
                <div className={styles.bodyLeft}>
                    {props.userId! &&
                        <FormItem title={`${props.userType === 'MEMBER' ? 'member' : 'staff'} status`} errorMessage={''} width='100%' controlPos='line'>
                            <ToggleButton height={22} onText='enable' offText='disable' displayTexts='top'
                                isOn={currentUser.isActive!}
                                frameColor={styles.adminControlDarkColor} buttonColor={styles.adminControlDarkColor}
                                switchedOnColor={styles.lightBackgrounds}
                                switchedOffColor={styles.disabledControlContext}
                                selectedTextColor={styles.adminControlDarkColor} deselectedTextColor={styles.adminControlDarkColor}
                                fontFamily='interMedium' fontSize='10px'
                                onClick={async () => {
                                    if (props.userId) {
                                        const result = await enableUser(props.userId!, !currentUser.isActive);
                                    }
                                    else {
                                        setCurrentUser({ ...currentUser, isActive: !currentUser.isActive });
                                    }
                                }}
                            />
                        </FormItem>
                    }
                    <div className={styles.separator}></div>
                    {props.userType !== 'STAFF' &&
                        <FormItem title={'loayalty club'} errorMessage={''}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Dropdown items={clubsList} width={'180px'} height={'32px'} placeHolder={'select club'}
                                    initialItem={currentUser.fkClub!}
                                    onChange={(selectedData) => {
                                        setCurrentUser({ ...currentUser, fkClub: selectedData.value });
                                    }}
                                />
                            </div>
                        </FormItem>
                    }
                    <div className={styles.separatorHalf}></div>
                    <FormItem title={'name'} errorMessage={''}>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Textbox width={'100%'} height={'32px'} placeHolder={'name'}
                                text={currentUser.name!}
                                onChange={(value) => {
                                    setCurrentUser({ ...currentUser, name: value });
                                }} />
                        </div>
                    </FormItem>
                    <div className={styles.separatorHalf}></div>
                    <FormItem title={'surname'} errorMessage={''}>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Textbox width={'230px'} height={'32px'} placeHolder={'surname'}
                                text={currentUser.surname!}
                                onChange={(value) => {
                                    setCurrentUser({ ...currentUser, surname: value });
                                }} />
                        </div>
                    </FormItem>
                    <div className={styles.separatorDouble}></div>
                    <FormItem title={'email/username'} errorMessage={existingEmail! ? 'another account is using this email' : !isValidMail ? 'invalid email address' : ''}>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Textbox width={'241px'} height={'32px'} placeHolder={'email/username'} type='email' validationIcon={existingEmail?? undefined}
                                disabled={props.userId !== undefined}
                                disabledColor={styles.disabledControlContext}
                                text={currentUser.email!}
                                onChange={async (value) => {
                                    setCurrentUser({ ...currentUser, email: value });
                                    if (value !== '') {
                                        const emailExists = (await JSON.parse(await checkDuplicates(value, 'EMAIL', props.userId)).result);
                                        
                                        if (emailExists) {
                                            setExistingEmail(true);
                                        }
                                        else {
                                            setExistingEmail(false);
                                            if (regexMail.test(value)) {
                                                setIsValidMail(true);
                                            }
                                            else
                                                setIsValidMail(false);
                                        }
                                    }
                                }} />
                        </div>
                    </FormItem>
                    <div className={styles.separatorHalf}></div>
                    <FormItem title={'phone'} errorMessage={existingCell! ? 'another account is using this phone' : !isValidPhone ? 'invalid phone number' : ''}>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Textbox width={'200px'} height={'32px'} placeHolder={'+112312345 / 004412312345'} type='phone' validationIcon={existingCell?? undefined}
                                text={currentUser.phone!}
                                onChange={async (value) => {
                                    setCurrentUser({ ...currentUser, phone: value });
                                    if (value !== '') {
                                        const cellExists = (await JSON.parse(await checkDuplicates(value.replace('00', '+'), 'CELL', props.userId)).result);
                                        console.log(value.replace('00', '+') , cellExists);
                                        
                                        if (cellExists) {
                                            setExistingCell(true);
                                        }
                                        else {
                                            setExistingCell(false);
                                            if (regexCell.test(value)) {
                                                setIsValidPhone(true);
                                            }
                                            else
                                                setIsValidPhone(false);
                                        }
                                    }
                                }} />
                        </div>
                    </FormItem>
                    <div className={styles.separatorHalf}></div>
                    {/* <FormItem title={'birthday'} errorMessage={''}>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Textbox width={'140px'} height={'32px'} placeHolder={'birthday'} 
                                text={birthday!}
                                onChange={(value) => { 'setEmail(value)' }} icon={<Calendar />} />
                        </div>
                    </FormItem> */}
                    <div className={styles.separatorHalf}></div>
                    <FormItem title={'postal code'} errorMessage={''} width={'100%'}>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Textbox width={'220px'} height={'32px'} placeHolder={'postal code'}
                                text={currentUser.postalCode!}
                                onChange={(value) => {
                                    setCurrentUser({ ...currentUser, postalCode: value });
                                }} />
                        </div>
                    </FormItem>

                    <div className={styles.separatorHalf}></div>
                    {props.userType === 'MEMBER' &&
                        <FormItem title={'address'} errorMessage={''} width={'100%'}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Textbox width={'100%'} height={'90px'} placeHolder={'address'}
                                    text={currentUser.address!}
                                    onChange={(value) => { 
                                        setCurrentUser({ ...currentUser, address: value });
                                    }} type='multiline' />
                            </div>
                        </FormItem>
                    }
                    {props.userType === 'STAFF' &&
                        <div>
                            <div className={styles.separator}></div>
                            <FormItem title={'password'} errorMessage={currentUser?.password! !== passwordRetype ? 'password & retype do not match' : ''} >
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Textbox width={'240px'} height={'32px'} placeHolder={'password'} type='password'
                                        text={currentUser.password!}
                                        onChange={(value) => {
                                            setCurrentUser({ ...currentUser, password: value });
                                        }} />
                                </div>
                            </FormItem>
                            <div className={styles.separatorHalf}></div>
                            <FormItem title={'password retype'} errorMessage={''}>
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Textbox width={'240px'} height={'32px'} placeHolder={'password retype'} type='password'
                                        text={passwordRetype}
                                        onChange={(value) => {
                                            setPasswordRetype(value);
                                        }} />
                                </div>
                            </FormItem>
                        </div>
                    }
                    <div className={styles.separator}></div>
                    <div className={styles.separatorDouble}></div>
                    <div className={styles.button}>
                        {props.userId! &&
                            <Button text='save'
                                textColor='#EDEAE3' fontFamily='interLight' fontSize='16px'
                                border='1px solid #ADB8BA' borderRadius='0px' color='#153147'
                                width='128px' height='38px'
                                onClick={async () => {
                                    if (isValidMail && !existingEmail) {
                                        showBusy('busy', '');
                                        const updatedUser = (await saveUser(currentUser!)).updatedUser;
                                        console.log(updatedUser);
                                        //const updatedUser = (await JSON.parse(result)); 
                                        hideBusy();
                                        if(updatedUser.pkUser === props.userId)
                                            props.onClose!();
                                        else if(!updatedUser.pkUser){
                                            showModal('update failure', updatedUser.error, null, '', () => {}, () => {});    
                                        }
                                    }
                                }} />
                        }
                        {!props.userId && currentUser.phone! && !currentUser.email &&
                            <FormItem title={'invite via'} errorMessage={''}>
                                <Button text='whatsapp' textPosition='imageRight'
                                    imageSVG={<Whatsapp height={32} width={32} fill='white' />}
                                    textColor='#EDEAE3' fontFamily='interLight' fontSize='14px'
                                    border='1px solid #ADB8BA' borderRadius='0px' color='#153147'
                                    width='128px' height='48px'
                                    onClick={async () => {
                                        showBusy('busy', '');
                                        if (isValidPhone && !existingEmail && !existingCell) {
                                            const response = await createUser(currentUser!);
                                            if (response!) {
                                                if (response.insertedUser!) {
                                                    const verificationURL = `${Settings.api.path}/user/verification/${response.insertedUser.verificationCode}`;
                                                    const message = 'Welcome to Crown Gold Gallery your online rarities store, we are proud to see such member in our club! Please consider to click the verification link, to start using your account: ';
                                                    window.open(`https://wa.me/${response.userPrimaryCell!}?text=` + encodeURIComponent(message) + verificationURL, '_blank');
                                                    props.onClose!();
                                                }
                                                else
                                                    showModal('add member failure', response.error, null, '', () => { }, () => { });
                                            }
                                        }
                                        else
                                            showModal('add member failure', 'response.error', null, '', () => { }, () => { });
                                        hideBusy();
                                    }} />
                            </FormItem>
                        }
                        {!props.userId && currentUser.email! &&
                            <FormItem title={'invite via'} errorMessage={''}>
                                <div className={styles.splitter}></div>
                                <Button
                                    text='email' textPosition='imageRight'
                                    imageSVG={<Email height={32} width={32} fill='white' />}
                                    textColor='#EDEAE3' fontFamily='interLight' fontSize='14px'
                                    border='1px solid #ADB8BA' borderRadius='0px' color='#153147'
                                    width='128px' height='48px'
                                    onClick={async () => {
                                        showBusy('busy', '');
                                        if (isValidMail && !existingEmail && !existingCell) {
                                            const response = await createUser(currentUser!);
                                            if (response!) {
                                                if (response.insertedUser!) {
                                                    const resultApprovalMail = await approveUser(response.insertedUser.pkUser);
                                                    //ToDO: optimize approval accuracy according to responses
                                                    props.onClose!();
                                                }
                                                else
                                                    showModal('add member failure', response.error, null, '', () => { }, () => { });
                                            }
                                        }
                                        else
                                            showModal('add member failure', 'response.error', null, '', () => { }, () => { });
                                        hideBusy();
                                    }} />
                            </FormItem>
                        }
                    </div>
                    <div className={styles.separatorDouble}></div>
                    {(user.type === 'SUPERVISOR') && (!currentUser.isNew) && props.userId! &&
                        <div className={styles.listExpandControl}
                            onClick={async () => {
                                if (currentUser.type === 'MEMBER') {
                                    showModal('delete member', 'deleting a member will remove all their enquiries and other related data, are you sure you want to continue?', 
                                        <div>
                                            <div>to cancel: close, to delete: ok</div>
                                        </div>
                                        , 'ok',
                                        async () => {
                                            showBusy('busy', '');
                                            const result = await deleteUser(props.userId!);
                                            if (result.pkUser === props.userId) {
                                                showModal('delete member', 'member deleting, completed successfully!', null, '', () => { }, () => { });            
                                            }
                                            props.onClose!();
                                            hideBusy();
                                        },
                                        () => {

                                        });
                                }
                                else
                                    showModal('delete staff', 'can not delete staff due to they have related items, enquiries & ... which are critical to the business! you can try changinf their user password & disable their account!', null, '', () => { }, () => { });
                            }}>
                            <Delete height={'24px'} fill={'#2C3D5B'} />
                        </div>
                    }
                </div>
                <div className={styles.separator}></div>
                <div className={styles.separator}></div>
            </div>
        </Modal>
    );
}

export default UserInfo;
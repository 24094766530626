import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import styles from './alertPopup.module.scss';
import Modal from "../Modal/Modal";

interface AlertPopupProps {
    fontFamily?: string;
    fontSize?: string;
    message: string;
    textColor?: string;
    color?: string;
    borderColor?: string;    
    isSelfish?: boolean|false;
    width?: string;
    height?: string;
    timerLength: number;
    onTimeout: () => void;
    type: 'error'|'info'|'message'|'question';
    children?: React.ReactNode; 
    opacity?: number;
}

const AlertPopup: FunctionComponent<AlertPopupProps> = (props) => {

    const intervalTimer = useRef<NodeJS.Timer>();
    const alertColor = (() => {
        switch(props.type){
            case 'error':
                return 'red';
            case 'info':
                return '#ffff00';
            case 'message':
                return 'green';
            default:
                return 'red';
        }
    });
    const alertIcon = (() => {
        switch(props.type){
            case 'error':                
                return require('../../assets/icons/components/alertPopup/alertError.png');
            case 'info':
                return require('../../assets/icons/components/alertPopup/alertInfo.png');
            case 'question':
                return require('../../assets/icons/components/alertPopup/alertQuestion.png');
            case 'message':
                return require('../../assets/icons/components/alertPopup/alertMessage.png');
            default:
                return require('../../assets/icons/components/alertPopup/alertMessage.png');
        }
    });
    
    
    const progressWidth = useRef<number>(100);
    const [progressBarWidth, setProgressBarWidth] = useState<number>(100);

    useEffect(() => {
        const seconds = props.timerLength / 1000;
        intervalTimer.current = setInterval(async () => {      
            progressWidth.current = (progressWidth.current - (1 / seconds));
            setProgressBarWidth(progressWidth.current);
            if(progressWidth.current <= 0){
                clearInterval(intervalTimer.current);
                props.onTimeout();
            }
        }, 10 );
        return () => clearInterval(intervalTimer.current);
    }, [])

    return (
        <div className={styles.modalContainer} 
            style={{ fontFamily: props.fontFamily!, fontSize: props.fontSize!, 
                width: props.width!,
                height: props.height!,                
            }}
            onClick={() => {
                if (!props.isSelfish){
                    clearInterval(intervalTimer.current);                    
                    props.onTimeout();                    
                }
            }}>
            <div className={styles.alertContainer} >
                <div className={styles.dataContainer} >
                    <div className={styles.imageContainer}>
                        <img src={alertIcon()} width='64px' />
                    </div>
                    {props.children}
                    <div className={styles.message}>
                        {props.message}
                    </div>
                </div>
                <div className={styles.separator}></div>
                <div className={styles.progressContainer}>
                    <div className={styles.progressBar} 
                            style={{ 
                                width: `${progressBarWidth}%`, 
                                backgroundColor: alertColor() 
                            }}>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default AlertPopup;